import React, { FC, ReactNode } from 'react';
import ModalReact from 'react-modal';
import './style.scss';

interface Props {
    children: ReactNode;
    title: string;
    isOpen: boolean;
    closeModal: any;
    hiddeHeader?: boolean;
    fixedSize?: boolean;
}

const Modal: FC<Props> = (props) => {
    const fixedSize = {
        content: {
            width: '80%',
            height: '80vh',
        },
    };

    const variableSize = {
        content: {
            maxWidth: '80%',
            maxHeight: '80vh',
        },
    };

    return (
        <ModalReact
            isOpen={props.isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
            onRequestClose={props.closeModal}
            style={props.fixedSize ? fixedSize : variableSize}
        >
            {!props.hiddeHeader && (
                <div className='header-modal'>
                    <h3>{props.title}</h3>
                    <button onClick={props.closeModal}>
                        <div className='close'></div>
                    </button>
                </div>
            )}
            <div className='content-modal'>{props.children}</div>
        </ModalReact>
    );
};
export default Modal;
