import { StickerCategory } from 'photoeditorsdk';

export const mockupsAIStickers: StickerCategory[] = [
    {
        identifier: 'AI TEMPLATES',
        name: 'AI TEMPLATES',
        thumbnailURI: 'custom/PhotoLandscapeAI.png',
        items: [
            {
                identifier: 'PhotoLandscapeAI mock',
                name: 'PhotoLandscapeAI',
                thumbnailURI: 'custom/PhotoLandscapeAI.png',
                stickerURI: 'custom/PhotoLandscapeAI.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortraitAI mock',
                name: 'PhotoPortraitAI',
                thumbnailURI: 'custom/PhotoPortraitAI.png',
                stickerURI: 'custom/PhotoPortraitAI.png',
                resizeMode: 'unrestricted',
            },
        ],
    },
];
