import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'router/router';
import * as Sentry from '@sentry/react';
require('./services/auth/interceptorToken');
require('./services/auth/interceptorRefresh');
require('./utils/i18n');

Sentry.configureScope(function (scope) {
    scope.setLevel('error');
});

Sentry.init({
    dsn: 'https://6203d9992fa64b38b4064dcea6b8484b@o1280586.ingest.sentry.io/4505147331510272',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

console.log(`${process.env.REACT_APP_NAME} version: ${process.env.REACT_APP_VERSION}`);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement! as HTMLElement);

root.render(
    <StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Router />
        </BrowserRouter>
    </StrictMode>
);
