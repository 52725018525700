export const portugueseLanguageObject = {
    common: {
        error: 'Erro',
        warning: 'Alerta',
        color: {
            colorPicker: {
                hex: 'Hex',
                r: 'R',
                g: 'G',
                b: 'B',
                sliderHue: 'Cor',
                sliderOpacity: 'Selector de Opacidade',
            },
        },
    },
    mainCanvasActions: {
        buttonUndo: 'Desfazer',
        buttonRedo: 'Refazer',
    },
    infoModals: {
        exporting: {
            heading: 'Exportando...',
            body: 'Apenas alguns segundos...',
        },
        saving: {
            heading: 'Salvando...',
            body: 'Apenas alguns segundos...',
        },
        loading: {
            heading: 'Carregando...',
            body: 'Apenas alguns segundos...',
        },
        resizing: {
            heading: 'Redimensionando...',
            body: 'Apenas alguns segundos...',
        },
        loadingFonts: {
            heading: 'Carregando fontes...',
            body: 'Apenas alguns segundos...',
        },
        stickerLoading: {
            heading: 'Carregando adesivo...',
            body: 'Apenas alguns segundos...',
        },
    },
    errorModals: {
        rendering: {
            headingDelimiter: ':',
            body: 'Ocorreu um erro ao carregar a imagem',
            buttonYes: 'Tentar novamente',
            buttonNo: 'Fechar',
        },
        fontLoading: {
            headingDelimiter: ':',
            heading: 'Falha ao carregar a fonte',
            body: 'Algumas fontes podem não ser carregados porque o servidor está demorando para responder. Por favor, tente novamente',
            buttonNo: 'Fechar',
        },
        stickerLoading: {
            headingDelimiter: ':',
            body: 'Não foi possível carregar o adesivo',
            buttonNo: 'Fechar',
        },
        unexpectedError: {
            headingDelimiter: ':',
            body: 'Ocorreu um erro inesperado ${error}',
            buttonYes: 'Tentar novamente',
            buttonNo: 'Fechar',
        },
    },
    warningModals: {
        unsupportedSerializationVersion: {
            headingDelimiter: ':',
            body: 'Seus dados foram restaurados  a partir de uma versão anterior e podem ter alterações',
            buttonYes: 'Aplicar alterações',
            buttonNo: 'Cancelar',
        },
        discardChanges: {
            headingDelimiter: ':',
            body: 'Você tem alterações não salvas. Tem certeza de que deseja sair?',
            buttonYes: 'Descartar alterações',
            buttonNo: 'Manter alterações',
        },
        unsavedChanges: {
            headingDelimiter: ':',
            body: 'Você tem alterações não salvas. Tem certeza de que deseja sair?',
            buttonYes: 'Sair sem salvar',
            buttonNo: 'Cancelar',
        },
        unsupportedWebGLRenderer: {
            body: 'Foi detectado um problema no navegador que pode causar tempos de carregamento longos. Por favor, tente em outro navegador.',
            buttonYes: 'Continuar',
        },
    },
    library: {
        title: 'Modelos',
    },
    sticker: {
        title: 'Adesivos',
        canvasActions: {
            buttonDelete: 'Apagar',
            buttonBringToFront: 'Mover para frente',
            buttonDuplicate: 'Duplicar',
            buttonFlipHorizontal: 'Inverter horizontal',
            buttonFlipVertical: 'Inverter vertical',
        },
    },
    text: {
        title: 'Textos',
        controls: {
            buttonNew: 'Novo texto',
            dropdownFontFamily: 'Família',
            textFontSize: 'Tamanho',
            selectAlignment: 'Alinhamento',
            selectFontColor: 'Cor da fonte',
            selectBackgroundColor: 'Cor de fundo',
            sliderLineSpacing: 'Espaçamento entre linhas',
            tabColor: 'Cor',
            tabBgColor: 'Cor de fundo',
            tabAlignment: 'Alinhamento',
            tabLineHeight: 'Altura da linha',
            tabFontSize: 'Tamanho',
        },
        canvasControls: {
            placeholderText: 'Escreva algo',
            buttonSave: 'Salvar',
            buttonClose: 'Cancelar',
            inputText: 'Entrada de texto',
        },
        canvasActions: {
            buttonEdit: 'Editar',
            buttonDelete: 'Excluir',
            buttonBringToFront: 'Trazer para frente',
            buttonDuplicate: 'Duplicar',
        },
    },
};
