import {
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setSerializationToLoad,
    setStickerCategories,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { defaultStickers } from 'pages/photoEditor/defaults/stickers';
import { StickerAsset } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';

export const useRemovePhotoStickersHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const removePhotoStickers = async () => {
        if (editorContext.editor === undefined) return;
        if (editorContext.serializationToLoad) return;
        dispatch(setLoader(true));

        const newStickers = editorContext.stickerCategories.filter((st) => {
            return !['DEFAULT', 'CUSTOM TEMPLATES', 'BACKGROUNDS'].includes(st.identifier);
        });

        const serialization = await editorContext.editor.serialize({ image: false }).then((data) => data);

        const serializationFormatted: any = {
            ...serialization,
            operations: [
                {
                    type: 'sprite',
                    options: { sprites: [] },
                },
            ],
        };
        dispatch(setSerializationToLoad(serializationFormatted));

        const defaultStickersNoQR = [
            {
                ...defaultStickers[0],
                items: defaultStickers[0].items.filter((st: StickerAsset) => !st.identifier.includes('touchpix-qr')),
            },
        ];
        dispatch(setStickerCategories([...defaultStickersNoQR, ...newStickers]));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    return { removePhotoStickers };
};
