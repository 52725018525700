export const englishLanguageObject = {
    errorModals: {
        fontLoading: {
            headingDelimiter: ':',
            heading: 'Failed to load font',
            body: 'Some fonts cannot be loaded because the service took too long to reply. Please try again',
            buttonNo: 'Close',
        },
    },
    library: {
        title: 'Templates',
    },
};
