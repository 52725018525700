import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const mockupPath = `${process.env.PUBLIC_URL}/assets/photodesigner/library/images/mockup`;

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const InfoQR: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal title={t('INFO')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-error'>
                <p className='text-error'>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/img/report_problem.svg`}
                        alt='error'
                        className='icon-error'
                    />
                    {t('TEXT_QR_SIZE')}
                </p>
                <div className='container-button'>
                    <Button onClick={props.closeModal} title={t('CLOSE')} />
                </div>
            </div>
        </Modal>
    );
};

export default InfoQR;
