import { FC } from 'react';
import './style.scss';
import ElementCarouselSticker, { SizePosition } from '../elementCarouselSticker/elementCarouselSticker';
import ElementCarouselText from '../elementCarouselText/elementCarouselText';

interface Props {
    stickers: any[];
    allStickers: any[];
    size: { height: number; width: number };
    moveUp: (id: number) => void;
    moveDown: (id: number) => void;
    deleteElement: (id: number) => void;
    changeSizePosition: (id: number, sizePosition: SizePosition) => void;
    changeTypo: (id: number, identifier: string) => void;
    updateSticker: () => void;
}

const CarouselStickers: FC<Props> = (props) => {
    return (
        <div className='carousel-stickers'>
            {props.stickers.map((st, i) => {
                if (st.type === 'sticker') {
                    return (
                        <ElementCarouselSticker
                            key={st.id}
                            sticker={st}
                            allStickers={props.allStickers}
                            size={props.size}
                            moveUp={props.moveUp}
                            moveDown={props.moveDown}
                            deleteElement={props.deleteElement}
                            changeSizePosition={props.changeSizePosition}
                            updateSticker={props.updateSticker}
                            isFirst={i === 0}
                            isLast={props.stickers.length - 1 === i}
                        />
                    );
                } else if (st.type === 'text') {
                    return (
                        <ElementCarouselText
                            key={st.id}
                            sticker={st}
                            moveUp={props.moveUp}
                            moveDown={props.moveDown}
                            deleteElement={props.deleteElement}
                            changeTypo={props.changeTypo}
                            updateSticker={props.updateSticker}
                            isFirst={i === 0}
                            isLast={props.stickers.length - 1 === i}
                        />
                    );
                }
            })}
        </div>
    );
};

export default CarouselStickers;
