const heightStyle = {
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
    }),
    input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        marginBottom: '2px',
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        marginBottom: '2px',
    }),
    indicatorSeparator: (state: any) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
    }),
};

export const selectStyleType = {
    ...heightStyle,
    control: (control: any) => ({
        ...control,
        // minWidth: '100%',
        // maxWidth: '220px',
        minHeight: '30px',
        height: '30px',
        width: '220px',
    }),
};

export const selectStyleTypeFullWidth = {
    ...heightStyle,
    control: (control: any) => ({
        ...control,
        // minWidth: '100%',
        // maxWidth: '220px',
        minHeight: '30px',
        height: '30px',
        width: '100%',
    }),
};

export const selectStyleEvent = {
    ...heightStyle,
    control: (control: any) => ({
        ...control,
        minWidth: '200px',
        maxWidth: '250px',
        minHeight: '30px',
        height: '30px',
    }),
};

export const selectStyleFont = {
    ...heightStyle,
    control: (control: any) => ({
        ...control,
        maxWidth: '100%',
        minHeight: '30px',
        height: '30px',
    }),
    menu: (control: any) => ({
        ...control,
        position: 'relative',
        zIndex: 99999,
        height: '300px',
    }),
};
