import { TPX_CODE_VERIFIER } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

export const loginTpx = async (eventId: string) => {
    const baseUrl = `${process.env.REACT_APP_TP_HOST}/oauth/authorize/`;
    const client = process.env.REACT_APP_TP_CLIENT ?? '';
    const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/?event_id=${eventId}`;
    const scope = 'basic';

    // Get Code Verifier.
    const verifier = generateVerifier();
    const challenge = await generateChallenge(verifier);

    const url = new URL(baseUrl);

    url.searchParams.set('client_id', client);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', redirectUri);
    url.searchParams.set('scope', scope);
    url.searchParams.set('code_challenge', challenge);
    url.searchParams.set('code_challenge_method', 'S256');

    cookie.setCookie(TPX_CODE_VERIFIER, verifier, 30);

    window.location.href = url.href;
};

const generateVerifier = () => {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);

    return Array.from(array, (item) => `0${item.toString(16)}`.substring(-2)).join('');
};

const generateChallenge = async (verifier: string) => {
    const sha256 = (plain: string) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);

        return window.crypto.subtle.digest('SHA-256', data);
    };

    const base64URLEncode = (string: ArrayBufferLike) => {
        return (
            window
                // @ts-ignore
                .btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '')
        );
    };

    const hashed = await sha256(verifier);

    return base64URLEncode(hashed);
};
