import { FC } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    showSidebar: boolean;
    setIsOpenStickersSidebar: () => void;
}

const ShowHideButton: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    return (
        <button className='button-show-hide' onClick={props.setIsOpenStickersSidebar}>
            <div className='inner-button-show-hide'>
                {t('SIDEBAR_STICKERS_LAYERS_DESING')}
                <div className='container-chevron'>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/img/chevron_left.svg?version=${process.env.REACT_APP_VERSION}`}
                        alt='Show or hide stickers sidebar'
                        className={`chevron ${props.showSidebar && 'open'}`}
                    />
                </div>
            </div>
        </button>
    );
};

export default ShowHideButton;
