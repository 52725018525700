import axios from 'axios';
import { ACCESS_TOKEN } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

axios.interceptors.request.use(
    async (config) => {
        if (config.url && !config.url.includes('https://slomospin.tecdam.io')) {
            const token = cookie.getCookie(ACCESS_TOKEN);

            if (token !== null) {
                const newConfig = {
                    ...config,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                return newConfig;
            }
        }

        return config;
    },
    (error) => {
        return error;
    }
);
