import Button from 'components/button/button';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authInterface } from 'router/router';
import { loginTpx } from 'services/auth/loginTpx';
import Cookie from 'utils/cookie';
import './style.scss';
import { LANGUAGE_SELECTED } from 'constants/constants';

const cookie = new Cookie();

interface Props {
    auth: authInterface;
}

const Login: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.auth.auth) {
            navigate(`/editor?event_id=${props.auth.eventId}`);
        }
    }, [props.auth]);

    const handleLogin = () => {
        loginTpx(props.auth.eventId);
    };

    const changeLanguage = (lang: string) => {
        cookie.setCookie(LANGUAGE_SELECTED, lang, 2592000);
        i18n.changeLanguage(lang);
    };

    return (
        <div className='login-page'>
            <div className='card-login'>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/touchpix-logo.png?version=${process.env.REACT_APP_VERSION}`}
                    alt='Touchpix'
                />
                <p>{t('TEXT_LOGIN')}</p>
                <Button onClick={handleLogin} title={t('BUTTON_LOGIN')} />
                <div className='lang-selector'>
                    <img
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAmVBMVEViZsViZMJiYrf9gnL8eWrlYkjgYkjZYkj8/PujwPybvPz4+PetraBEgfo+fvo3efkydfkqcvj8Y2T8UlL8Q0P8MzP9k4Hz8/Lu7u4DdPj9/VrKysI9fPoDc/EAZ7z7IiLHYkjp6ekCcOTk5OIASbfY/v21takAJrT5Dg6sYkjc3Nn94t2RkYD+y8KeYkjs/v7l5fz0dF22YkjWvcOLAAAAgElEQVR4AR2KNULFQBgGZ5J13KGGKvc/Cw1uPe62eb9+Jr1EUBFHSgxxjP2Eca6AfUSfVlUfBvm1Ui1bqafctqMndNkXpb01h5TLx4b6TIXgwOCHfjv+/Pz+5vPRw7txGWT2h6yO0/GaYltIp5PT1dEpLNPL/SdWjYjAAZtvRPgHJX4Xio+DSrkAAAAASUVORK5CYII='
                        alt='English'
                        width='21'
                        height='14'
                        onClick={() => changeLanguage('en')}
                    />
                    <img
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAflBMVEX/AAD9AAD3AADxAADrAAD/eXn9bGz8YWH8WVn6UVH5SEj5Pz/3NDT0Kir9/QD+/nL+/lT18lDt4Uf6+j/39zD39yf19R3n5wDxflXsZ1Pt4Y3x8zr0wbLs1NXz8xPj4wD37t3jmkvsUU/Bz6nrykm3vJ72IiL0FBTyDAvhAABEt4UZAAAAX0lEQVR4AQXBQUrFQBBAwXqTDkYE94Jb73+qfwVRcYxVQRBRToiUfoaVpGTrtdS9SO0Z9FR9lVy/g5c99+dKl30N5uxPuviexXEc9/msC7TOkd4kHu/Dlh4itCJ8AP4B0w4Qwmm7CFQAAAAASUVORK5CYII='
                        alt='Español'
                        width='21'
                        height='14'
                        onClick={() => changeLanguage('es')}
                    />
                    <img
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAn1BMVEUAYQAAWwAAUgAARwAAOgAALgAAIwB/voB4uni242pttG1kr2Rdq11RpFEAGAD9/Uv8/VT690U/mz83lTguki4ADQCp2lJ3d+Q+dfpkaun47zii1B9IoEhgslHOy6fZ5virw/Iubfhsc6v29iMfih+Wy3Nbg+lzm/m61vd+oNr08hZ2uDcAAQCPlLSuraIzmA8yjzKw3z8nXvcTgxM1pTDYD/UeAAAAfUlEQVR4AUWIA3oFMBCE/4mT2nbvf6biQ91n27tjAaDNi7gaFuLAIexiUDc6QKUfhKaVva5Dh/Xu3rwrqYPTzfeYVNvrRvUiUUbvO5hIVR0f8f7TYCo797+6POgOqtm9n8nGQ1qP/395v7cHw4FsQohnXhE3DOSQSETOmN8EHiUfBxs2q7sAAAAASUVORK5CYII='
                        alt='Português'
                        width='21'
                        height='14'
                        onClick={() => changeLanguage('pt')}
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;
