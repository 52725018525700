import { FC, useEffect, useState } from 'react';
import './style.scss';
import ShowHideButton from './components/showHideButton/showHideButton';
import HeaderSidebar from './components/headerSidebar/headerSidebar';
import CarouselStickers from './components/carouselStickers/carouselStickers';
import ModalAlert from './components/modalAlert/modalAlert';
import { SizePosition } from './components/elementCarouselSticker/elementCarouselSticker';
import ModalErrorSticker from './components/modalErrorSticker/modalErrorSticker';

interface Props {
    showSidebar: boolean;
    stickers: any[] | null;
    size: { height: number; width: number };
    allStickers: any[];
    setIsOpenStickersSidebar: () => void;
    saveStickersFromSidebar: (stickers: any[]) => void;
}

const StickersSidebar: FC<Props> = (props) => {
    const [stickers, setStickers] = useState<any[]>();
    const [updatedStickers, setUpdatedStickers] = useState(false);
    const [modalAlert, setModalAlert] = useState(false);
    const [modalErrorSticker, setModalErrorSticker] = useState(false);

    useEffect(() => {
        if (props.stickers) {
            // Add an id to every sticker
            const newStickers = props.stickers.map((st) => {
                return {
                    id: Math.random(),
                    ...st,
                };
            });
            setStickers(newStickers.reverse());
        }
    }, [props.stickers]);

    const moveUp = (id: number) => {
        if (stickers === undefined) return;
        const index = stickers.findIndex((item: any) => item.id === id);
        const element = stickers[index];
        const stickersUp = stickers.filter((item, i) => i < index - 1);
        const stickersDown = stickers.filter((item, i) => i >= index - 1 && item.id !== id);
        const newStickers = [...stickersUp, element, ...stickersDown];

        const domElement = document.getElementById(`${id}`);
        const container = document.getElementById('container-sidebar');
        if (domElement && container) {
            const top = domElement.offsetTop;
            const height = domElement.clientHeight;
            container.scroll(0, top - height);
        }

        setStickers(newStickers);
    };

    const moveDown = (id: number) => {
        if (stickers === undefined) return;
        const index = stickers.findIndex((item: any) => item.id === id);
        const element = stickers[index];
        const stickersUp = stickers.filter((item, i) => i <= index + 1 && item.id !== id);
        const stickersDown = stickers.filter((item, i) => i > index + 1);
        const newStickers = [...stickersUp, element, ...stickersDown];

        const domElement = document.getElementById(`${id}`);
        const container = document.getElementById('container-sidebar');
        if (domElement && container) {
            const top = domElement.offsetTop;
            const height = domElement.clientHeight;
            container.scroll(0, top + height);
        }

        setStickers(newStickers);
    };

    const changeSizePosition = (id: number, sizePosition: SizePosition) => {
        if (stickers === undefined) return;
        const newStickers = stickers.map((st) => {
            if (st.id === id) {
                return {
                    ...st,
                    options: {
                        ...st.options,
                        dimensions: {
                            x: +sizePosition.width,
                            y: +sizePosition.height,
                        },
                        position: {
                            x: +sizePosition.xPosition,
                            y: +sizePosition.yPosition,
                        },
                    },
                };
            } else {
                return st;
            }
        });

        setStickers(newStickers);
    };

    const deleteElement = (id: number) => {
        if (!stickers) return;
        const newStickers = stickers.filter((st) => st.id !== id);
        setStickers(newStickers);
    };

    const changeTypo = (id: number, identifier: string) => {
        if (stickers === undefined) return;
        const newStickers = stickers.map((st) => {
            if (st.id === id) {
                return {
                    ...st,
                    options: {
                        ...st.options,
                        fontIdentifier: identifier,
                    },
                };
            } else {
                return st;
            }
        });

        setStickers(newStickers);
    };

    const handleSaveStickers = () => {
        if (stickers === undefined) return;
        const errorStickers = stickers.find((st) => {
            if (st.type === 'sticker' && (st.options.dimensions.x === 0 || st.options.dimensions.y === 0)) {
                return st;
            }
        });

        if (errorStickers) {
            setModalErrorSticker(true);
            return;
        }
        // Remove sticker id
        const newStickers = stickers.map((st) => {
            const { id, ...sticker } = st;
            return sticker;
        });
        setUpdatedStickers(false);
        props.saveStickersFromSidebar(newStickers.reverse());
    };

    const handleOpenCloseSidebar = () => {
        if (!props.showSidebar) {
            props.setIsOpenStickersSidebar();
            return;
        }

        if (updatedStickers) {
            setModalAlert(true);
        } else {
            props.setIsOpenStickersSidebar();
        }
    };

    return (
        <aside className={`stickers-sidebar`}>
            <ShowHideButton showSidebar={props.showSidebar} setIsOpenStickersSidebar={handleOpenCloseSidebar} />
            <div id='container-sidebar' className={`container-sidebar ${props.showSidebar && 'show-sidebar'}`}>
                <HeaderSidebar handleSaveStickers={handleSaveStickers} size={props.size} />
                {stickers && (
                    <CarouselStickers
                        stickers={stickers}
                        size={props.size}
                        allStickers={props.allStickers}
                        moveUp={moveUp}
                        moveDown={moveDown}
                        deleteElement={deleteElement}
                        changeTypo={changeTypo}
                        changeSizePosition={changeSizePosition}
                        updateSticker={() => setUpdatedStickers(true)}
                    />
                )}
            </div>
            <ModalAlert
                showModal={modalAlert}
                closeModal={() => setModalAlert(false)}
                saveChanges={() => {
                    setModalAlert(false);
                    handleSaveStickers();
                }}
                notSaveChanges={() => {
                    setModalAlert(false);
                    setUpdatedStickers(false);
                    props.setIsOpenStickersSidebar();
                }}
            />
            <ModalErrorSticker showModal={modalErrorSticker} closeModal={() => setModalErrorSticker(false)} />
        </aside>
    );
};

export default StickersSidebar;
