export default class Cookie {
    static instance: Cookie = new Cookie();

    setCookie = (name: string, value: any, seconds: number) => {
        const date = new Date();
        date.setTime(date.getTime() + seconds * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie = `${name}=${value}; ${expires}`;
    };

    getCookie = (name: string) => {
        let nameEQ = `${name}=`;
        let ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    deleteCookie = (name: string) => {
        document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    };

    deleteAllCookies = () => {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    };
}
