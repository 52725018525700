import { FC } from 'react';
import './style.scss';

interface Props {
    checked: boolean;
    label: string;
    handleChange: () => void;
}

const Checkbox: FC<Props> = (props) => {
    return (
        <div className='checkbox'>
            <input
                type='checkbox'
                checked={props.checked}
                name={props.label}
                id={props.label}
                onChange={props.handleChange}
            />
            <label htmlFor={props.label}>{props.label}</label>
        </div>
    );
};

export default Checkbox;
