import editorReducer from 'context/reducers/editorReducer';
import { combineReducers, createStore } from 'redux';

const rootReducer = combineReducers({
    editorReducer: editorReducer,
});

export const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
