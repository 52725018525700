import Checkbox from 'components/checkbox/checkbox';
import { selectStyleType } from 'components/select/selectStyle';
import { selectTheme } from 'components/select/selectTheme';
import { setLoader, setTemplate, setTypeEvent } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import GalleryTemplates from './components/galleryTemplates/galleryTemplates';
import './style.scss';
import ModalChangeTemplate from './components/modalChangeTemplate/modalChangeTemplate';
import AlertFourK from 'components/alertFourK/alertFourK';
import { NOT_SHOW_ALERT_4K } from 'constants/constants';
import { TypeEvent } from 'models/typeEvent';

interface Props {}

const TemplatesSidebar: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const { editor, typeEvent, isOpenTemplatesSidebar, template } = useSelector(
        (state: RootState) => state.editorReducer
    );
    const dispatch = useDispatch();
    const [temporalTypeEvent, setTemporalTypeEvent] = useState(typeEvent);
    const [emptyTemplate, setEmptyTemplate] = useState(false);
    const [isStrip, setIsStrip] = useState(false);
    const [cutHorizontal, setCutHorizontal] = useState(false);
    const [fourK, setFourK] = useState(false);
    const [isOpenModalChangeTemplate, setIsOpenModalChangeTemplate] = useState<undefined | string>(undefined);
    const [isOpenAlertFourK, setIsOpenAlertFourK] = useState(false);

    const handleSetTemplate = async (template: string) => {
        if (!editor) return;
        dispatch(setLoader(true));
        const serialization = await editor.serialize({ image: false }).then((data) => data);
        const sprites = serialization.operations.find((sp: any) => sp.type === 'sprite');

        if (!sprites) {
            confirmChangeTemplate(template);
            return;
        }

        // @ts-ignore
        const spritesWithoutMocks = sprites.options.sprites.find((sp: any) => {
            return (sp.type === 'sticker' && !sp.options.identifier.includes('mock')) || sp.type === 'text';
        });
        if (!spritesWithoutMocks) {
            confirmChangeTemplate(template);
        } else {
            setIsOpenModalChangeTemplate(template);
        }
    };

    const confirmChangeTemplate = (template: string) => {
        setIsOpenModalChangeTemplate(undefined);
        dispatch(setTypeEvent(temporalTypeEvent as never));
        dispatch(setTemplate(template));
    };

    const handleEmptyTemplate = () => {
        setEmptyTemplate(!emptyTemplate);
        setIsStrip(false);
    };

    const handleFourK = () => {
        if (fourK) {
            setFourK(false);
        } else {
            const cookie4K = localStorage.getItem(NOT_SHOW_ALERT_4K);
            if (!cookie4K) {
                setIsOpenAlertFourK(true);
            } else {
                setFourK(true);
            }
        }
    };

    const handleStrip = () => {
        if (isStrip) {
            setIsStrip(false);
        } else {
            setIsStrip(true);
            setCutHorizontal(false);
        }
    };

    return (
        <aside className={`templates-sidebar ${isOpenTemplatesSidebar && 'show-sidebar'}`}>
            {isOpenModalChangeTemplate !== undefined && (
                <ModalChangeTemplate
                    isOpen={isOpenModalChangeTemplate !== undefined}
                    closeModal={() => {
                        dispatch(setLoader(false));
                        setIsOpenModalChangeTemplate(undefined);
                    }}
                    confirmContinue={() => confirmChangeTemplate(isOpenModalChangeTemplate)}
                />
            )}
            {isOpenAlertFourK && (
                <AlertFourK
                    isOpen={isOpenAlertFourK}
                    closeModal={() => setIsOpenAlertFourK(false)}
                    confirmFourK={() => {
                        setIsOpenAlertFourK(false);
                        setFourK(true);
                    }}
                />
            )}
            <div className='container-selector'>
                <Select
                    placeholder={t('TYPE')}
                    theme={selectTheme}
                    styles={selectStyleType}
                    onChange={(selected) => setTemporalTypeEvent(selected as never)}
                    value={temporalTypeEvent}
                    options={[
                        {
                            value: TypeEvent.Photo,
                            label: `${t('PHOTO')} - ${t('PHOTO_GIF')}`,
                        },
                        {
                            value: TypeEvent.AI,
                            label: `${t('PHOTO_AI')}`,
                        },
                        {
                            value: TypeEvent.Video,
                            label: `${t('SLOMO')} - ${t('BOOMERANG')} - ${t('VIDEO')} - ${t('ANIMATED_GIF')}`,
                        },
                    ]}
                />
            </div>
            <div className='container-select'>
                {temporalTypeEvent?.value === TypeEvent.Photo && (
                    <Checkbox checked={emptyTemplate} label={t('CUSTOM_TEMPLATE')} handleChange={handleEmptyTemplate} />
                )}
                {temporalTypeEvent?.value === TypeEvent.Photo && emptyTemplate && (
                    <Checkbox checked={isStrip} label={t('IS_STRIP')} handleChange={handleStrip} />
                )}
                {/* {temporalTypeEvent?.value !== 'Video' && emptyTemplate && (
                    <Checkbox checked={cutHorizontal} label={t('CUT_HORIZONTAL')} handleChange={handleCutHorizontal} />
                )} */}
                {temporalTypeEvent?.value === TypeEvent.Video && (
                    <Checkbox checked={fourK} label={t('4K')} handleChange={handleFourK} />
                )}
            </div>
            <GalleryTemplates
                type={temporalTypeEvent === undefined ? undefined : (temporalTypeEvent.value as TypeEvent)}
                selectTemplate={handleSetTemplate}
                template={template}
                emptyTemplate={emptyTemplate}
                fourK={fourK}
                isStrip={isStrip}
                cutHorizontal={cutHorizontal}
            />
        </aside>
    );
};

export default TemplatesSidebar;
