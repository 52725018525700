import React, { FC } from 'react';
import './style.scss'

interface Props {
    label: string
    value: string
    onChange: (e: Event) => void
}

const InputField : FC<Props> = (props) => {
    return (
        <div className='input-field'>
            <span className='label'>{props.label}</span>
            <input 
                type="text"
                value={props.value}
                onChange={(e: any) => props.onChange(e)}
            />
        </div>
    );
}
export default InputField;
