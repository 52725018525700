import { setLoader, setOverlayToLoad, setStickerCategories } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getOverlaysUser } from 'services/overlays/getOverlaysUser';

export const useLoadUserOverlaysHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const loadUserOverlays = async (overlay: string | undefined = undefined) => {
        dispatch(setLoader(true));
        dispatch(setOverlayToLoad(overlay));

        return new Promise<any>((resolve, reject) => {
            getOverlaysUser()
                .then((res) => {
                    if (res.status === 200) {
                        const overlaysData = res.data.overlays.user.overlays;
                        const overlays = overlaysData.map((ov: { id: string; URI: string }) => {
                            return {
                                identifier: ov.id,
                                name: ov.id,
                                thumbnailURI: ov.URI,
                                stickerURI: ov.URI,
                            };
                        });

                        resolve({
                            result: true,
                            identifier: 'OVERLAYS',
                            items: overlays.reverse(),
                        });
                    } else {
                        resolve({
                            result: false,
                            identifier: 'OVERLAYS',
                            items: [],
                        });
                    }
                })
                .catch((err) => {
                    resolve({
                        result: false,
                        identifier: 'OVERLAYS',
                        items: [],
                    });
                });
        });
    };

    return { loadUserOverlays };
};
