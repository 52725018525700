import { TypeEvent } from 'models/typeEvent';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    type: TypeEvent;
    mockupPath: string;
    template: string | undefined;
    selectTemplate: (template: string) => void;
}

const DisplayAI: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <div className='container-wrapper-templates'>
            <div className='container-templates'>
                <h3 className='title'>{`${t('PORTRAIT')} 1240 x 1844px`}</h3>
                <div className='group-templates'>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 10 AI`)}
                        className={`template library ${props.template === `${props.type} 10` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 10.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 10'
                        />
                    </div>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 4 AI`)}
                        className={`template library ${props.template === `${props.type} 4` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 4 AI.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 4'
                        />
                    </div>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 8 AI`)}
                        className={`template library ${props.template === `${props.type} 8` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 8.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 8'
                        />
                    </div>
                </div>
            </div>
            <div className='container-templates'>
                <h3 className='title'>{`${t('LANDSCAPE')} 1844 x 1240px`}</h3>
                <div className='group-templates'>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 17 AI`)}
                        className={`template library ${props.template === `${props.type} 17` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 17.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 17'
                        />
                    </div>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 15 AI`)}
                        className={`template library ${props.template === `${props.type} 15` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 15.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 15'
                        />
                    </div>
                </div>
            </div>
            <div className='container-templates'>
                <h3 className='title'>{`${t('SQUARE')} 1844 x 1844px`}</h3>
                <div className='group-templates'>
                    <div
                        onClick={() => props.selectTemplate(`${props.type} 16 AI`)}
                        className={`template library ${props.template === `${props.type} 16` && 'selected'}`}
                    >
                        <img
                            src={`${props.mockupPath}/Photo 16.png?version=${process.env.REACT_APP_VERSION}`}
                            alt='Photo 16'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DisplayAI;
