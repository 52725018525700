import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    confirmDelete: () => void;
}

const DeleteStickers: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal title={t('TITLE_DELETE_ASSETS')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div>
                <p>{t('TEXT_DELETE_ASSETS')}</p>
            </div>
            <div className='container-buttons'>
                <Button onClick={props.closeModal} title={t('GO_BACK')} />
                <Button onClick={props.confirmDelete} title={t('CONFIRM_DELETE')} style='red' />
            </div>
        </Modal>
    );
};

export default DeleteStickers;
