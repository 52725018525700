import { configurationDesktop } from 'pages/photoEditor/defaults/configurationDesktop';
import { configurationMobile } from 'pages/photoEditor/defaults/configurationMobile';
import { defaultStickers } from 'pages/photoEditor/defaults/stickers';
import i18n from 'utils/i18n';
import { editorConstants } from '../constants/editorConstants';

import {
    PropsEditor,
    SetBackgroundToLoadAction,
    SetConfigurationAction,
    SetEditorAction,
    SetEditorLoadingAction,
    SetEventAction,
    SetFinishedLoadSerializationAction,
    SetFinishedLoadStickersAction,
    SetIsOpenModalQRAction,
    SetIsOpenModalUploadAction,
    SetIsOpenTemplatesSidebarAction,
    SetLoaderAction,
    SetOverlayToLoadAction,
    SetSerializationToLoadAction,
    SetStickerCategoriesAction,
    SetTemplateAction,
    SetTemplatesLoadedAction,
    SetTypeEventAction,
} from 'context/types/types';
import { TypeEvent } from 'models/typeEvent';
import { StickerAsset } from 'photoeditorsdk';

const initialState: PropsEditor = {
    event: undefined,
    editor: undefined,
    editorLoading: false,
    isOpenTemplatesSidebar: true,
    template: `Video Boomerang Gif Slomo 2`,
    templatesLoaded: [],
    configuration: document.body.clientWidth < 900 ? configurationMobile : configurationDesktop,
    stickerCategories: [
        {
            ...defaultStickers[0],
            items: defaultStickers[0].items.filter((st: StickerAsset) => !st.identifier.includes('touchpix-qr')),
        },
    ],
    typeEvent: {
        value: TypeEvent.Video,
        label: `${i18n.t('SLOMO')} - ${i18n.t('BOOMERANG')} - ${i18n.t('VIDEO')} - ${i18n.t('ANIMATED_GIF')}`,
    },
    serializationToLoad: undefined,
    overlayToLoad: undefined,
    backgroundToLoad: undefined,
    finishedLoadStickers: true,
    finishedLoadSerialization: true,
    loader: true,
    isOpenModalErrorUpload: false,
    isOpenModalQR: false,
};

function editorReducer(
    state = initialState,
    action:
        | SetEventAction
        | SetEditorAction
        | SetEditorLoadingAction
        | SetIsOpenTemplatesSidebarAction
        | SetTemplateAction
        | SetTemplatesLoadedAction
        | SetConfigurationAction
        | SetStickerCategoriesAction
        | SetTypeEventAction
        | SetSerializationToLoadAction
        | SetOverlayToLoadAction
        | SetBackgroundToLoadAction
        | SetFinishedLoadStickersAction
        | SetFinishedLoadSerializationAction
        | SetLoaderAction
        | SetIsOpenModalUploadAction
        | SetIsOpenModalQRAction
): PropsEditor {
    switch (action.type) {
        case editorConstants.SET_EVENT:
            return {
                ...state,
                event: action.value,
            };
        case editorConstants.SET_EDITOR:
            return {
                ...state,
                editor: action.value,
            };
        case editorConstants.SET_EDITOR_LOADING:
            return {
                ...state,
                editorLoading: action.value,
            };
        case editorConstants.SET_IS_OPEN_TEMPLATES_SIDEBAR:
            return {
                ...state,
                isOpenTemplatesSidebar: action.value,
            };
        case editorConstants.SET_TEMPLATE:
            return {
                ...state,
                template: action.value,
            };
        case editorConstants.SET_TEMPLATES_LOADED:
            return {
                ...state,
                templatesLoaded: action.value,
            };
        case editorConstants.SET_CONFIGURATION:
            return {
                ...state,
                configuration: action.value,
            };
        case editorConstants.SET_STICKER_CATEGORIES:
            return {
                ...state,
                stickerCategories: action.value,
            };
        case editorConstants.SET_TYPE_EVENT:
            return {
                ...state,
                typeEvent: action.value,
            };
        case editorConstants.SET_SERIALIZATION_TO_LOAD:
            return {
                ...state,
                serializationToLoad: action.value,
            };
        case editorConstants.SET_OVERLAY_TO_LOAD:
            return {
                ...state,
                overlayToLoad: action.value,
            };
        case editorConstants.SET_BACKGROUND_TO_LOAD:
            return {
                ...state,
                backgroundToLoad: action.value,
            };
        case editorConstants.SET_FINISHED_LOAD_STICKERS:
            return {
                ...state,
                finishedLoadStickers: action.value,
            };
        case editorConstants.SET_FINISHED_LOAD_SERIALIZATION:
            return {
                ...state,
                finishedLoadSerialization: action.value,
            };
        case editorConstants.SET_LOADER:
            return {
                ...state,
                loader: action.value,
            };
        case editorConstants.SET_IS_OPEN_MODAL_ERROR_UPLOAD:
            return {
                ...state,
                isOpenModalErrorUpload: action.value,
            };
        case editorConstants.SET_IS_OPEN_MODAL_QR:
            return {
                ...state,
                isOpenModalQR: action.value,
            };
        default:
            return state;
    }
}

export default editorReducer;
