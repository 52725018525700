import { URL_ASSET } from 'constants/constants';
import { setFinishedLoadSerialization, setSerializationToLoad } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { TypeEvent } from 'models/typeEvent';
import { TPFonts } from 'pages/photoEditor/defaults/TPfonts';
import { replaceDuplicatedFonts } from 'utils/replaceDuplicatedFonts';
import { useDispatch, useSelector } from 'react-redux';
import { getPreset } from 'services/templates/templatesServer';
import { getTemplateName, isStickerInCategory } from 'utils/utils';

export const useSearchDefaultPresetHook = () => {
    const dispatch = useDispatch();
    const { typeEvent, template, editor, stickerCategories } = useSelector((state: RootState) => state.editorReducer);

    // Get the json file of presets from the server -> Search if someone have the current dimensions ->
    // Serializate the current state and replace the operations by the presets of the server (If don't do this,
    // returns an error because the platform is not the same (ios - html))
    const searchDefaultPreset = async (promisesPresets: any, stickersDesign: any) => {
        const allPresets = await Promise.all(promisesPresets);
        const promisesPresetsJSON = await allPresets.map(async (preset: any) => {
            const name = preset.data.data.filename_disk.replace('.bin', '.json');
            const url = `${URL_ASSET}${name}`;

            const response = getPreset(url);
            return response;
        });

        const allPresetsJSON = await Promise.all(promisesPresetsJSON);
        let presetSelected = undefined;

        if (typeEvent?.value === TypeEvent.Photo || typeEvent?.value === TypeEvent.AI) {
            presetSelected = allPresetsJSON.find((pr) => {
                if (!typeEvent) return false;
                return pr.data.template === getTemplateName(template, typeEvent.value);
            });
        } else if (typeEvent?.value === TypeEvent.Video) {
            const templateId = template?.includes('4K')
                ? template.split(' ')[template.split(' ').length - 2]
                : template?.substring(template.lastIndexOf(' ') + 1);
            if (templateId !== undefined) {
                presetSelected = allPresetsJSON[+templateId - 1];
            }
        }

        if (presetSelected !== undefined) {
            const presetSelectedParsed = replaceDuplicatedFonts(presetSelected);
            const currentSerialization = await editor?.serialize({ image: false }).then((data) => data);
            // @ts-ignore
            const spritesInCurrentSerialization = currentSerialization?.operations.find((sp: any) => {
                return sp.type === 'sprite';
            });
            // @ts-ignore
            const currentStickers = spritesInCurrentSerialization?.options.sprites ?? [];
            const customTemplates = currentStickers.filter((st: any) => {
                return (
                    st.type === 'sticker' &&
                    (isStickerInCategory(stickerCategories, 'CUSTOM TEMPLATES', st.options.identifier) ||
                        isStickerInCategory(stickerCategories, 'AI TEMPLATES', st.options.identifier))
                );
            });

            const spritesInTemplate = presetSelectedParsed.data.operations.find((op: any) => op.type === 'sprite');
            const spritesInTemplateFiltered = spritesInTemplate.options.sprites.map((sp: any) => {
                if (sp.type === 'sticker') {
                    const foundSticker = stickersDesign.find((sticker: any) => {
                        return sticker.identifier === sp.options.identifier;
                    });
                    if (foundSticker !== undefined) {
                        return sp;
                    }
                } else if (sp.type === 'text') {
                    let identifier = undefined;

                    // Depends where is created
                    if (typeEvent?.value === TypeEvent.Photo || typeEvent?.value === TypeEvent.AI) {
                        identifier = sp.options.fontIdentifier;
                    } else if (typeEvent?.value === TypeEvent.Video) {
                        if (sp.options.fontIdentifier.includes('_')) {
                            identifier = sp.options.fontIdentifier.substring(0, sp.options.fontIdentifier.indexOf('_'));
                        } else {
                            identifier = sp.options.fontIdentifier;
                        }
                    }

                    const identifiersLoaded = TPFonts.map((ft) => ft.identifier);
                    if (identifiersLoaded.includes(identifier)) {
                        return {
                            type: 'text',
                            options: {
                                ...sp.options,
                                fontIdentifier: identifier,
                            },
                        };
                    } else if (identifiersLoaded.includes(sp.options.fontIdentifier)) {
                        return {
                            type: 'text',
                            options: {
                                ...sp.options,
                                fontIdentifier: sp.options.fontIdentifier,
                            },
                        };
                    }
                }
            });

            const emptySerialization = await editor?.serialize({ image: false }).then((data) => data);
            const presetSelectedFormatted: any = {
                ...emptySerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: [...customTemplates, ...spritesInTemplateFiltered] },
                    },
                ],
            };
            dispatch(setSerializationToLoad(presetSelectedFormatted));
        }
        setTimeout(() => {
            dispatch(setFinishedLoadSerialization(true));
        }, 500);
    };

    return { searchDefaultPreset };
};
