import { setBackgroundToLoad, setLoader, setStickerCategories } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { defaultBackgrounds } from 'pages/photoEditor/defaults/defaultBackgrounds';
import { useDispatch, useSelector } from 'react-redux';
import { getBackgroundsUser } from 'services/backgrounds/getBackgroundUser';

export const useLoadUserBackgroundsHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const loadUserBackgrounds = async (background: string | undefined = undefined) => {
        dispatch(setLoader(true));
        dispatch(setBackgroundToLoad(background));

        return new Promise<any>((resolve, reject) => {
            getBackgroundsUser()
                .then((res) => {
                    if (res.status === 200) {
                        const backgroundsData = res.data.backgrounds.user.backgrounds;

                        const backgrounds = backgroundsData.map((st: { id: string; URI: string }) => {
                            return {
                                identifier: st.id,
                                name: st.id,
                                thumbnailURI: st.URI,
                                stickerURI: st.URI,
                            };
                        });

                        resolve({
                            result: true,
                            identifier: 'BACKGROUNDS',
                            items: backgrounds.reverse(),
                        });
                    } else {
                        resolve({
                            result: false,
                            identifier: 'BACKGROUNDS',
                            items: [],
                        });
                    }
                })
                .catch((err) => {
                    resolve({
                        result: false,
                        identifier: 'BACKGROUNDS',
                        items: [],
                    });
                });
        });
    };

    return { loadUserBackgrounds };
};
