export const TOKEN_EXPIRY_TIME = 3600;
export const REFRESH_TOKEN_EXPIRY_TIME = 86400;

export const ACCESS_TOKEN = 'access_token_v1';
export const REFRESH_TOKEN = 'refresh_token_v1';
export const TPX_CODE_VERIFIER = 'tpxCodeVerifier';
export const NOT_SHOW_ALERT_4K = 'not_show_alert_4k';
export const LANGUAGE_SELECTED = 'language_selected';

export const URL_ASSET = 'https://slomospin.tecdam.io/assets/';
