import { setIsOpenModalErrorUpload, setLoader } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { ExportFormat, ImageFormat } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';
import { postTemplatePreset } from 'services/presets/presetsServer';
import { useSavePresetLocallyHook } from './useSavePresetLocallyHook';

export const useSavePresetServerHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);
    const { savePresetLocally } = useSavePresetLocallyHook();

    const savePresetServer = async (name: string) => {
        if (editorContext.editor === undefined) return;

        dispatch(setLoader(true));
        const image = await editorContext.editor
            .export({
                format: ImageFormat.PNG,
                exportType: ExportFormat.BLOB,
                quality: 1.0,
                enableDownload: false,
                preventExportEvent: true,
            })
            .then((image) => {
                const file = new File([image as Blob], 'template.png', { type: 'image/png' });
                return file;
            })
            .catch((err) => {
                console.log('An error has occured while exporting ', err);
            });
        await editorContext.editor.serialize({ image: false }).then((data) => {
            // @ts-ignore
            data.stickerCategories = editorContext.stickerCategories;
            // @ts-ignore
            data.template = editorContext.template;
            // @ts-ignore
            data.typeEvent = editorContext.typeEvent;

            postTemplatePreset(name, image as File, JSON.stringify(data))
                .then((res) => {
                    if (res.status !== 200) {
                        savePresetLocally(name);
                        dispatch(setIsOpenModalErrorUpload(true));
                    }
                })
                .catch((err) => {
                    savePresetLocally(name);
                    dispatch(setIsOpenModalErrorUpload(true));
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        });
    };

    return { savePresetServer };
};
