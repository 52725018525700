import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    confirmContinue: () => void;
}

const ModalChangeTemplate: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal title={t('CONFIRM_DELETE')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div>
                <p>{t('TEXT_CHANGE_TEMPLATE')}</p>
            </div>
            <div className='container-buttons'>
                <Button onClick={props.closeModal} title={t('GO_BACK')} />
                <Button onClick={props.confirmContinue} title={t('CONFIRM')} style='red' />
            </div>
        </Modal>
    );
};

export default ModalChangeTemplate;
