import {
    setBackgroundToLoad,
    setEditor,
    setEditorLoading,
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setOverlayToLoad,
    setSerializationToLoad,
    setStickerCategories,
    setTemplate,
    setTypeEvent,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { AdvancedStickerControlBarItem, UIEvent, PhotoEditorSDKUI, Tool, Color } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateNameBackground, isStickerInCategory } from 'utils/utils';
import { useChangedHistoryHook } from './useChangedHistoryHook';
import { useChangedToolHook } from './useChangedToolHook';
import { useLoadUserStickersHook } from './useLoadUserStickersHook';
import { useLoadUserOverlaysHook } from './useLoadUserOverlaysHook';
import { useLoadUserBackgroundsHook } from './useLoadUserBackgroundsHook';
import { TintMode } from 'photoeditorsdk';
import { useTranslation } from 'react-i18next';

export const useInitEditorHook = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);
    const { changedHistory, clearOverlaysAndBackgroundsRefs } = useChangedHistoryHook();
    const { changedTool } = useChangedToolHook();
    const { loadUserStickers } = useLoadUserStickersHook();
    const { loadUserOverlays } = useLoadUserOverlaysHook();
    const { loadUserBackgrounds } = useLoadUserBackgroundsHook();

    const initEditor = async () => {
        dispatch(setLoader(true));
        dispatch(setEditorLoading(true));
        dispatch(setFinishedLoadStickers(false));
        dispatch(setFinishedLoadSerialization(false));
        const tools = [Tool.LIBRARY, Tool.STICKER, Tool.TEXT];
        const defaultTool = editorContext.editor ? Tool.STICKER : Tool.LIBRARY;
        const newConfig = {
            ...editorContext.configuration,
            tools: tools,
            language: i18n.language,
            defaultTool: defaultTool,
            sticker: {
                advancedUIToolControlBarOrder: [
                    AdvancedStickerControlBarItem.StickerColorList,
                    AdvancedStickerControlBarItem.Items,
                ],
                enableCustomUpload: false,
                categories: editorContext.stickerCategories,
                customStickerTintMode: 'solid' as TintMode,
                defaultTintColor: [0.3058823529411765, 0.6745098039215687, 0.7490196078431373, 1.0] as Color,
            },
        };

        const userStickersLoaded = editorContext.stickerCategories.find((st) => st.identifier === 'USER STICKERS');
        const userOverlaysLoaded = editorContext.stickerCategories.find((st) => st.identifier === 'OVERLAYS');
        if (!userStickersLoaded || !userOverlaysLoaded) {
            const resolves = await Promise.all([
                loadUserStickers(),
                loadUserOverlays(),
                ...(editorContext.typeEvent?.value !== 'Video' ? [loadUserBackgrounds()] : []),
            ])
                .then((res) => {
                    return res;
                })
                .catch(() => {
                    return [];
                });

            const newIdentifiersCats = resolves.map((cat) => cat.identifier);
            const oldCategories = editorContext.stickerCategories.filter((cat) => {
                return !newIdentifiersCats.includes(cat.identifier);
            });
            const newCategories = resolves.map((cat) => {
                return {
                    identifier: cat.identifier,
                    name: cat.identifier,
                    thumbnailURI: cat.items.length > 0 ? cat.items[0].stickerURI : '',
                    items: cat.items,
                };
            });

            dispatch(setStickerCategories([...oldCategories, ...newCategories]));

            dispatch(setFinishedLoadSerialization(true));
            dispatch(setEditorLoading(false));
            setTimeout(() => {
                dispatch(setFinishedLoadStickers(true));
            }, 500);
            return;
        }

        // Save the current serialization
        // We need close the editorContext.editor to open with the new configuration
        let previousSerialization: any;
        if (editorContext.editor) {
            previousSerialization = await editorContext.editor.serialize({ image: false }).then((data) => data);
            await editorContext.editor.close();
        }

        const editor = await PhotoEditorSDKUI.init(newConfig)
            .then((ed) => ed)
            .catch((err) => console.log(err));

        if (!editor) return;

        dispatch(setEditor(editor));
        clearOverlaysAndBackgroundsRefs();
        editor.on(UIEvent.HISTORY_CHANGE, () => changedHistory(editor));
        editor.on(UIEvent.TOOL_ENTER, (tool) => changedTool(tool));

        if (editorContext.serializationToLoad !== undefined) {
            const idStickersLoaded = editorContext.stickerCategories
                .map((st) => {
                    return st.items.map((item) => item.identifier);
                })
                .flat();

            const template = editorContext.serializationToLoad.template;
            const typeEvent = editorContext.serializationToLoad.typeEvent;

            if (template !== undefined) {
                dispatch(setTemplate(template));
                const newImage = `library/images/mockup/${getTemplateNameBackground(template, typeEvent?.value)}.png`;
                await editor.setImage(newImage);
            }

            if (typeEvent !== undefined) {
                dispatch(setTypeEvent(typeEvent));
            }

            if (editorContext.serializationToLoad.operations.length > 0) {
                const spritesInTemplate = editorContext.serializationToLoad.operations.find(
                    (op: any) => op.type === 'sprite'
                );

                // Filter the sprites that we have loaded in the editorContext.editor
                const spritesInTemplateFiltered = spritesInTemplate.options.sprites.filter((sp: any) => {
                    if (sp === undefined) return;
                    if (sp.type === 'sticker' && idStickersLoaded.includes(sp.options.identifier)) {
                        return sp;
                    } else if (sp.type === 'text') {
                        return sp;
                    }
                });

                const spritesConvertedText = spritesInTemplateFiltered.map((sp: any) => {
                    if (sp === undefined) return;
                    if (sp.type === 'sticker') {
                        return sp;
                    } else if (sp.type === 'text') {
                        return {
                            type: 'text',
                            options: {
                                ...sp.options,
                                ...(sp.options.lineHeight
                                    ? { lineHeight: sp.options.lineHeight }
                                    : { lineHeight: 0.5 }),
                            },
                        };
                    }
                });

                const newSerialization = {
                    ...editorContext.serializationToLoad,
                    operations: [
                        {
                            type: 'sprite',
                            options: { sprites: spritesConvertedText },
                        },
                    ],
                };

                // @ts-ignore
                await editor.deserialize(newSerialization);
            }

            dispatch(setSerializationToLoad(undefined));
        } else if (editorContext.overlayToLoad) {
            const previousSpritesObject = previousSerialization.operations.find((sp: any) => sp.type === 'sprite');
            const previousSprites = previousSpritesObject ? previousSpritesObject.options.sprites : [];
            const imageWidth = previousSerialization.image.width;
            const imageHeight = previousSerialization.image.height;
            const newWidth = imageWidth < imageHeight ? 1 : imageWidth / imageHeight;
            const newHeight = imageWidth < imageHeight ? imageHeight / imageWidth : 1;

            const overlayName = editorContext.overlayToLoad;
            const newSprites = [
                ...previousSprites,
                {
                    type: 'sticker',
                    options: {
                        position: {
                            x: 0.5,
                            y: 0.5,
                        },
                        dimensions: {
                            x: newWidth,
                            y: newHeight,
                        },
                        rotation: 0,
                        flipVertically: false,
                        flipHorizontally: false,
                        identifier: overlayName,
                        alpha: 1,
                        tintColor: {
                            rgba: [1, 1, 1, 1],
                        },
                    },
                },
            ];
            const newSerialization = {
                ...previousSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: newSprites },
                    },
                ],
            };

            dispatch(setOverlayToLoad(undefined));
            await editor.deserialize(newSerialization);
        } else if (editorContext.backgroundToLoad) {
            const previousSpritesObject = previousSerialization.operations.find((sp: any) => sp.type === 'sprite');
            const previousSprites = previousSpritesObject ? previousSpritesObject.options.sprites : [];
            const previousSpritesFiltered = previousSprites.filter(
                (sp: any) => !isStickerInCategory(editorContext.stickerCategories, 'BACKGROUNDS', sp.options.identifier)
            );
            const imageWidth = previousSerialization.image.width;
            const imageHeight = previousSerialization.image.height;
            const newWidth = imageWidth < imageHeight ? 1 : imageWidth / imageHeight;
            const newHeight = imageWidth < imageHeight ? imageHeight / imageWidth : 1;

            const backgroundName = editorContext.backgroundToLoad;
            const newSprites = [
                {
                    type: 'sticker',
                    options: {
                        position: {
                            x: 0.5,
                            y: 0.5,
                        },
                        dimensions: {
                            x: newWidth,
                            y: newHeight,
                        },
                        rotation: 0,
                        flipVertically: false,
                        flipHorizontally: false,
                        identifier: backgroundName,
                        alpha: 1,
                        tintColor: {
                            rgba: [1, 1, 1, 1],
                        },
                    },
                },
                ...previousSpritesFiltered,
            ];
            const newSerialization = {
                ...previousSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: newSprites },
                    },
                ],
            };

            dispatch(setBackgroundToLoad(undefined));
            await editor.deserialize(newSerialization);
        } else if (previousSerialization !== undefined) {
            await editor.deserialize(previousSerialization);
        }

        dispatch(setEditorLoading(false));
        dispatch(setLoader(false));
    };

    return { initEditor };
};
