import './style.scss';
import { useTranslation } from 'react-i18next';

const UploadFinished = () => {
    const { i18n, t } = useTranslation();
    return (
        <div id={`upload-finished-modal`}>
            <span>{t('TEMPLATES_ADDED')}</span>
        </div>
    );
};

export default UploadFinished;
