import Button from 'components/button/button';
import InputField from 'components/inputField/inputField';
import Modal from 'components/modal/modal';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    savePresetServer: (name: string) => void;
}

const SavePresetServer: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [name, setName] = useState('');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (name === '') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [name]);

    return (
        <Modal title={t('TITLE_SAVE_PRESET')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <InputField label={t('TEXT_INPUT_PRESET')} value={name} onChange={(e: any) => setName(e.target.value)} />
            <Button onClick={() => props.savePresetServer(name)} title={t('BUTTON_SAVE_PRESET')} disabled={disabled} />
        </Modal>
    );
};

export default SavePresetServer;
