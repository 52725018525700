import { StickerAsset } from 'photoeditorsdk';

export const defaultBackgrounds: StickerAsset[] = [
    {
        identifier: 'background-00000',
        name: '00000',
        resizeMode: 'unrestricted',
        tintMode: 'solid',
        stickerURI: 'background/00000.jpg',
        thumbnailURI: 'background/00000.jpg',
    },
    {
        identifier: 'background-00001',
        name: '00001',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00001.jpg',
        thumbnailURI: 'background/00001.jpg',
    },
    {
        identifier: 'background-00002',
        name: '00002',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00002.jpg',
        thumbnailURI: 'background/00002.jpg',
    },
    {
        identifier: 'background-00003',
        name: '00003',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00003.jpg',
        thumbnailURI: 'background/00003.jpg',
    },
    {
        identifier: 'background-00004',
        name: '00004',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00004.jpg',
        thumbnailURI: 'background/00004.jpg',
    },
    {
        identifier: 'background-00005',
        name: '00005',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00005.jpg',
        thumbnailURI: 'background/00005.jpg',
    },
    {
        identifier: 'background-00006',
        name: '00006',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00006.jpg',
        thumbnailURI: 'background/00006.jpg',
    },
    {
        identifier: 'background-00007',
        name: '00007',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00007.jpg',
        thumbnailURI: 'background/00007.jpg',
    },
    {
        identifier: 'background-00008',
        name: '00008',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00008.jpg',
        thumbnailURI: 'background/00008.jpg',
    },
    {
        identifier: 'background-00009',
        name: '00009',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00009.jpg',
        thumbnailURI: 'background/00009.jpg',
    },
    {
        identifier: 'background-00010',
        name: '00010',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00010.jpg',
        thumbnailURI: 'background/00010.jpg',
    },
    {
        identifier: 'background-00011',
        name: '00011',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00011.jpg',
        thumbnailURI: 'background/00011.jpg',
    },
    {
        identifier: 'background-00012',
        name: '00012',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00012.jpg',
        thumbnailURI: 'background/00012.jpg',
    },
    {
        identifier: 'background-00013',
        name: '00013',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00013.jpg',
        thumbnailURI: 'background/00013.jpg',
    },
    {
        identifier: 'background-00014',
        name: '00014',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00014.jpg',
        thumbnailURI: 'background/00014.jpg',
    },
    {
        identifier: 'background-00015',
        name: '00015',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00015.jpg',
        thumbnailURI: 'background/00015.jpg',
    },
    {
        identifier: 'background-00016',
        name: '00016',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00016.jpg',
        thumbnailURI: 'background/00016.jpg',
    },
    {
        identifier: 'background-00017',
        name: '00017',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00017.jpg',
        thumbnailURI: 'background/00017.jpg',
    },
    {
        identifier: 'background-00018',
        name: '00018',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00018.jpg',
        thumbnailURI: 'background/00018.jpg',
    },
    {
        identifier: 'background-00019',
        name: '00019',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00019.jpg',
        thumbnailURI: 'background/00019.jpg',
    },
    {
        identifier: 'background-00020',
        name: '00020',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00020.jpg',
        thumbnailURI: 'background/00020.jpg',
    },
    {
        identifier: 'background-00021',
        name: '00021',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00021.jpg',
        thumbnailURI: 'background/00021.jpg',
    },
    {
        identifier: 'background-00022',
        name: '00022',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00022.jpg',
        thumbnailURI: 'background/00022.jpg',
    },
    {
        identifier: 'background-00023',
        name: '00023',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00023.jpg',
        thumbnailURI: 'background/00023.jpg',
    },
    {
        identifier: 'background-00024',
        name: '00024',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00024.jpg',
        thumbnailURI: 'background/00024.jpg',
    },
    {
        identifier: 'background-00025',
        name: '00025',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00025.jpg',
        thumbnailURI: 'background/00025.jpg',
    },
    {
        identifier: 'background-00026',
        name: '00026',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00026.jpg',
        thumbnailURI: 'background/00026.jpg',
    },
    {
        identifier: 'background-00027',
        name: '00027',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00027.jpg',
        thumbnailURI: 'background/00027.jpg',
    },
    {
        identifier: 'background-00028',
        name: '00028',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00028.jpg',
        thumbnailURI: 'background/00028.jpg',
    },
    {
        identifier: 'background-00029',
        name: '00029',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00029.jpg',
        thumbnailURI: 'background/00029.jpg',
    },
    {
        identifier: 'background-00030',
        name: '00030',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00030.jpg',
        thumbnailURI: 'background/00030.jpg',
    },
    {
        identifier: 'background-00031',
        name: '00031',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00031.jpg',
        thumbnailURI: 'background/00031.jpg',
    },
    {
        identifier: 'background-00032',
        name: '00032',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00032.jpg',
        thumbnailURI: 'background/00032.jpg',
    },
    {
        identifier: 'background-00033',
        name: '00033',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00033.jpg',
        thumbnailURI: 'background/00033.jpg',
    },
    {
        identifier: 'background-00034',
        name: '00034',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00034.jpg',
        thumbnailURI: 'background/00034.jpg',
    },
    {
        identifier: 'background-00035',
        name: '00035',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00035.jpg',
        thumbnailURI: 'background/00035.jpg',
    },
    {
        identifier: 'background-00036',
        name: '00036',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00036.jpg',
        thumbnailURI: 'background/00036.jpg',
    },
    {
        identifier: 'background-00037',
        name: '00037',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00037.jpg',
        thumbnailURI: 'background/00037.jpg',
    },
    {
        identifier: 'background-00038',
        name: '00038',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00038.jpg',
        thumbnailURI: 'background/00038.jpg',
    },
    {
        identifier: 'background-00039',
        name: '00039',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00039.jpg',
        thumbnailURI: 'background/00039.jpg',
    },
    {
        identifier: 'background-00040',
        name: '00040',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00040.jpg',
        thumbnailURI: 'background/00040.jpg',
    },
    {
        identifier: 'background-00041',
        name: '00041',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00041.jpg',
        thumbnailURI: 'background/00041.jpg',
    },
    {
        identifier: 'background-00042',
        name: '00042',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00042.jpg',
        thumbnailURI: 'background/00042.jpg',
    },
    {
        identifier: 'background-00043',
        name: '00043',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00043.jpg',
        thumbnailURI: 'background/00043.jpg',
    },
    {
        identifier: 'background-00044',
        name: '00044',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00044.jpg',
        thumbnailURI: 'background/00044.jpg',
    },
    {
        identifier: 'background-00045',
        name: '00045',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00045.jpg',
        thumbnailURI: 'background/00045.jpg',
    },
    {
        identifier: 'background-00046',
        name: '00046',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00046.jpg',
        thumbnailURI: 'background/00046.jpg',
    },
    {
        identifier: 'background-00047',
        name: '00047',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00047.jpg',
        thumbnailURI: 'background/00047.jpg',
    },
    {
        identifier: 'background-00048',
        name: '00048',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00048.jpg',
        thumbnailURI: 'background/00048.jpg',
    },
    {
        identifier: 'background-00049',
        name: '00049',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00049.jpg',
        thumbnailURI: 'background/00049.jpg',
    },
    {
        identifier: 'background-00050',
        name: '00050',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00050.jpg',
        thumbnailURI: 'background/00050.jpg',
    },
    {
        identifier: 'background-00051',
        name: '00051',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00051.jpg',
        thumbnailURI: 'background/00051.jpg',
    },
    {
        identifier: 'background-00052',
        name: '00052',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00052.jpg',
        thumbnailURI: 'background/00052.jpg',
    },
    {
        identifier: 'background-00053',
        name: '00053',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00053.jpg',
        thumbnailURI: 'background/00053.jpg',
    },
    {
        identifier: 'background-00054',
        name: '00054',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00054.jpg',
        thumbnailURI: 'background/00054.jpg',
    },
    {
        identifier: 'background-00055',
        name: '00055',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00055.jpg',
        thumbnailURI: 'background/00055.jpg',
    },
    {
        identifier: 'background-00056',
        name: '00056',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00056.jpg',
        thumbnailURI: 'background/00056.jpg',
    },
    {
        identifier: 'background-00057',
        name: '00057',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00057.jpg',
        thumbnailURI: 'background/00057.jpg',
    },
    {
        identifier: 'background-00058',
        name: '00058',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00058.jpg',
        thumbnailURI: 'background/00058.jpg',
    },
    {
        identifier: 'background-00059',
        name: '00059',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00059.jpg',
        thumbnailURI: 'background/00059.jpg',
    },
    {
        identifier: 'background-00060',
        name: '00060',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00060.jpg',
        thumbnailURI: 'background/00060.jpg',
    },
    {
        identifier: 'background-00061',
        name: '00061',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00061.jpg',
        thumbnailURI: 'background/00061.jpg',
    },
    {
        identifier: 'background-00062',
        name: '00062',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00062.jpg',
        thumbnailURI: 'background/00062.jpg',
    },
    {
        identifier: 'background-00063',
        name: '00063',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00063.jpg',
        thumbnailURI: 'background/00063.jpg',
    },
    {
        identifier: 'background-00064',
        name: '00064',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00064.jpg',
        thumbnailURI: 'background/00064.jpg',
    },
    {
        identifier: 'background-00065',
        name: '00065',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00065.jpg',
        thumbnailURI: 'background/00065.jpg',
    },
    {
        identifier: 'background-00066',
        name: '00066',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00066.jpg',
        thumbnailURI: 'background/00066.jpg',
    },
    {
        identifier: 'background-00067',
        name: '00067',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00067.jpg',
        thumbnailURI: 'background/00067.jpg',
    },
    {
        identifier: 'background-00068',
        name: '00068',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00068.jpg',
        thumbnailURI: 'background/00068.jpg',
    },
    {
        identifier: 'background-00069',
        name: '00069',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00069.jpg',
        thumbnailURI: 'background/00069.jpg',
    },
    {
        identifier: 'background-00070',
        name: '00070',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00070.jpg',
        thumbnailURI: 'background/00070.jpg',
    },
    {
        identifier: 'background-00071',
        name: '00071',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00071.jpg',
        thumbnailURI: 'background/00071.jpg',
    },
    {
        identifier: 'background-00072',
        name: '00072',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00072.jpg',
        thumbnailURI: 'background/00072.jpg',
    },
    {
        identifier: 'background-00073',
        name: '00073',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00073.jpg',
        thumbnailURI: 'background/00073.jpg',
    },
    {
        identifier: 'background-00074',
        name: '00074',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00074.jpg',
        thumbnailURI: 'background/00074.jpg',
    },
    {
        identifier: 'background-00075',
        name: '00075',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00075.jpg',
        thumbnailURI: 'background/00075.jpg',
    },
    {
        identifier: 'background-00076',
        name: '00076',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00076.jpg',
        thumbnailURI: 'background/00076.jpg',
    },
    {
        identifier: 'background-00077',
        name: '00077',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00077.jpg',
        thumbnailURI: 'background/00077.jpg',
    },
    {
        identifier: 'background-00078',
        name: '00078',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00078.jpg',
        thumbnailURI: 'background/00078.jpg',
    },
    {
        identifier: 'background-00079',
        name: '00079',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00079.jpg',
        thumbnailURI: 'background/00079.jpg',
    },
    {
        identifier: 'background-00080',
        name: '00080',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00080.jpg',
        thumbnailURI: 'background/00080.jpg',
    },
    {
        identifier: 'background-00081',
        name: '00081',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00081.jpg',
        thumbnailURI: 'background/00081.jpg',
    },
    {
        identifier: 'background-00082',
        name: '00082',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00082.jpg',
        thumbnailURI: 'background/00082.jpg',
    },
    {
        identifier: 'background-00083',
        name: '00083',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00083.jpg',
        thumbnailURI: 'background/00083.jpg',
    },
    {
        identifier: 'background-00084',
        name: '00084',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00084.jpg',
        thumbnailURI: 'background/00084.jpg',
    },
    {
        identifier: 'background-00085',
        name: '00085',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00085.jpg',
        thumbnailURI: 'background/00085.jpg',
    },
    {
        identifier: 'background-00086',
        name: '00086',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00086.jpg',
        thumbnailURI: 'background/00086.jpg',
    },
    {
        identifier: 'background-00087',
        name: '00087',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00087.jpg',
        thumbnailURI: 'background/00087.jpg',
    },
    {
        identifier: 'background-00088',
        name: '00088',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00088.jpg',
        thumbnailURI: 'background/00088.jpg',
    },
    {
        identifier: 'background-00089',
        name: '00089',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00089.jpg',
        thumbnailURI: 'background/00089.jpg',
    },
    {
        identifier: 'background-00090',
        name: '00090',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00090.jpg',
        thumbnailURI: 'background/00090.jpg',
    },
    {
        identifier: 'background-00091',
        name: '00091',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00091.jpg',
        thumbnailURI: 'background/00091.jpg',
    },
    {
        identifier: 'background-00092',
        name: '00092',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00092.jpg',
        thumbnailURI: 'background/00092.jpg',
    },
    {
        identifier: 'background-00093',
        name: '00093',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00093.jpg',
        thumbnailURI: 'background/00093.jpg',
    },
    {
        identifier: 'background-00094',
        name: '00094',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00094.jpg',
        thumbnailURI: 'background/00094.jpg',
    },
    {
        identifier: 'background-00095',
        name: '00095',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00095.jpg',
        thumbnailURI: 'background/00095.jpg',
    },
    {
        identifier: 'background-00096',
        name: '00096',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00096.jpg',
        thumbnailURI: 'background/00096.jpg',
    },
    {
        identifier: 'background-00097',
        name: '00097',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00097.jpg',
        thumbnailURI: 'background/00097.jpg',
    },
    {
        identifier: 'background-00098',
        name: '00098',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00098.jpg',
        thumbnailURI: 'background/00098.jpg',
    },
    {
        identifier: 'background-00099',
        name: '00099',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00099.jpg',
        thumbnailURI: 'background/00099.jpg',
    },
    {
        identifier: 'background-00100',
        name: '00100',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00100.jpg',
        thumbnailURI: 'background/00100.jpg',
    },
    {
        identifier: 'background-00101',
        name: '00101',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00101.jpg',
        thumbnailURI: 'background/00101.jpg',
    },
    {
        identifier: 'background-00102',
        name: '00102',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00102.jpg',
        thumbnailURI: 'background/00102.jpg',
    },
    {
        identifier: 'background-00103',
        name: '00103',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00103.jpg',
        thumbnailURI: 'background/00103.jpg',
    },
    {
        identifier: 'background-00104',
        name: '00104',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00104.jpg',
        thumbnailURI: 'background/00104.jpg',
    },
    {
        identifier: 'background-00105',
        name: '00105',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00105.jpg',
        thumbnailURI: 'background/00105.jpg',
    },
    {
        identifier: 'background-00106',
        name: '00106',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00106.jpg',
        thumbnailURI: 'background/00106.jpg',
    },
    {
        identifier: 'background-00107',
        name: '00107',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00107.jpg',
        thumbnailURI: 'background/00107.jpg',
    },
    {
        identifier: 'background-00108',
        name: '00108',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00108.jpg',
        thumbnailURI: 'background/00108.jpg',
    },
    {
        identifier: 'background-00109',
        name: '00109',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00109.jpg',
        thumbnailURI: 'background/00109.jpg',
    },
    {
        identifier: 'background-00110',
        name: '00110',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00110.jpg',
        thumbnailURI: 'background/00110.jpg',
    },
    {
        identifier: 'background-00111',
        name: '00111',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00111.jpg',
        thumbnailURI: 'background/00111.jpg',
    },
    {
        identifier: 'background-00112',
        name: '00112',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00112.jpg',
        thumbnailURI: 'background/00112.jpg',
    },
    {
        identifier: 'background-00113',
        name: '00113',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00113.jpg',
        thumbnailURI: 'background/00113.jpg',
    },
    {
        identifier: 'background-00114',
        name: '00114',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00114.jpg',
        thumbnailURI: 'background/00114.jpg',
    },
    {
        identifier: 'background-00115',
        name: '00115',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00115.jpg',
        thumbnailURI: 'background/00115.jpg',
    },
    {
        identifier: 'background-00116',
        name: '00116',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00116.jpg',
        thumbnailURI: 'background/00116.jpg',
    },
    {
        identifier: 'background-00117',
        name: '00117',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00117.jpg',
        thumbnailURI: 'background/00117.jpg',
    },
    {
        identifier: 'background-00118',
        name: '00118',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00118.jpg',
        thumbnailURI: 'background/00118.jpg',
    },
    {
        identifier: 'background-00119',
        name: '00119',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00119.jpg',
        thumbnailURI: 'background/00119.jpg',
    },
    {
        identifier: 'background-00120',
        name: '00120',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00120.jpg',
        thumbnailURI: 'background/00120.jpg',
    },
    {
        identifier: 'background-00121',
        name: '00121',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00121.jpg',
        thumbnailURI: 'background/00121.jpg',
    },
    {
        identifier: 'background-00122',
        name: '00122',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00122.jpg',
        thumbnailURI: 'background/00122.jpg',
    },
    {
        identifier: 'background-00123',
        name: '00123',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00123.jpg',
        thumbnailURI: 'background/00123.jpg',
    },
    {
        identifier: 'background-00124',
        name: '00124',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00124.jpg',
        thumbnailURI: 'background/00124.jpg',
    },
    {
        identifier: 'background-00125',
        name: '00125',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00125.jpg',
        thumbnailURI: 'background/00125.jpg',
    },
    {
        identifier: 'background-00126',
        name: '00126',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00126.jpg',
        thumbnailURI: 'background/00126.jpg',
    },
    {
        identifier: 'background-00127',
        name: '00127',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00127.jpg',
        thumbnailURI: 'background/00127.jpg',
    },
    {
        identifier: 'background-00128',
        name: '00128',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00128.jpg',
        thumbnailURI: 'background/00128.jpg',
    },
    {
        identifier: 'background-00129',
        name: '00129',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00129.jpg',
        thumbnailURI: 'background/00129.jpg',
    },
    {
        identifier: 'background-00130',
        name: '00130',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00130.jpg',
        thumbnailURI: 'background/00130.jpg',
    },
    {
        identifier: 'background-00131',
        name: '00131',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00131.jpg',
        thumbnailURI: 'background/00131.jpg',
    },
    {
        identifier: 'background-00132',
        name: '00132',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00132.jpg',
        thumbnailURI: 'background/00132.jpg',
    },
    {
        identifier: 'background-00133',
        name: '00133',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00133.jpg',
        thumbnailURI: 'background/00133.jpg',
    },
    {
        identifier: 'background-00134',
        name: '00134',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00134.jpg',
        thumbnailURI: 'background/00134.jpg',
    },
    {
        identifier: 'background-00135',
        name: '00135',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00135.jpg',
        thumbnailURI: 'background/00135.jpg',
    },
    {
        identifier: 'background-00136',
        name: '00136',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00136.jpg',
        thumbnailURI: 'background/00136.jpg',
    },
    {
        identifier: 'background-00137',
        name: '00137',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00137.jpg',
        thumbnailURI: 'background/00137.jpg',
    },
    {
        identifier: 'background-00138',
        name: '00138',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00138.jpg',
        thumbnailURI: 'background/00138.jpg',
    },
    {
        identifier: 'background-00139',
        name: '00139',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00139.jpg',
        thumbnailURI: 'background/00139.jpg',
    },
    {
        identifier: 'background-00140',
        name: '00140',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00140.jpg',
        thumbnailURI: 'background/00140.jpg',
    },
    {
        identifier: 'background-00141',
        name: '00141',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00141.jpg',
        thumbnailURI: 'background/00141.jpg',
    },
    {
        identifier: 'background-00142',
        name: '00142',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00142.jpg',
        thumbnailURI: 'background/00142.jpg',
    },
    {
        identifier: 'background-00143',
        name: '00143',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00143.jpg',
        thumbnailURI: 'background/00143.jpg',
    },
    {
        identifier: 'background-00144',
        name: '00144',
        resizeMode: 'unrestricted',
        stickerURI: 'background/00144.jpg',
        thumbnailURI: 'background/00144.jpg',
    },
];
