import Button from 'components/button/button';
import FilesUpload from 'components/filesUpload/filesUpload';
import Loader from 'components/loader/loader';
import Modal from 'components/modal/modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postStickers } from 'services/stickers/postStickers';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    loadUserStickers: () => void;
}

const UploadStickers: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    const handleUpload = () => {
        if (files.length > 0) {
            setLoading(true);
            postStickers(files)
                .then((res) => {
                    if (res.status === 200) {
                        setFiles([]);
                        props.loadUserStickers();
                        props.closeModal();
                    }
                })
                .catch((err) => navigate('/'))
                .finally(() => setLoading(false));
        }
    };

    return (
        <Modal title={t('TITLE_UPLOAD_STICKER')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-upload-stickers'>
                {loading ? (
                    <div className='container-loader'>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <FilesUpload
                            fileTypes={[
                                {
                                    name: '.png',
                                    format: 'image/png',
                                },
                                {
                                    name: '.jpeg',
                                    format: 'image/jpeg',
                                },
                                {
                                    name: '.jpg',
                                    format: 'image/jpeg',
                                },
                            ]}
                            setFiles={(files) => setFiles(files)}
                            filesSelected={files}
                            multiple
                            isOverlay={false}
                        />
                        <div className='container-button'>
                            <Button onClick={handleUpload} title={t('UPLOAD')} />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UploadStickers;
