import { StickerCategory } from 'photoeditorsdk';

export const mockupsStickers: StickerCategory[] = [
    {
        identifier: 'CUSTOM TEMPLATES',
        name: 'CUSTOM TEMPLATES',
        thumbnailURI: 'custom/PhotoLandscape1.png',
        items: [
            {
                identifier: 'PhotoLandscape1 mock',
                name: 'PhotoLandscape1',
                thumbnailURI: 'custom/PhotoLandscape1.png',
                stickerURI: 'custom/PhotoLandscape1.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape2 mock',
                name: 'PhotoLandscape2',
                thumbnailURI: 'custom/PhotoLandscape2.png',
                stickerURI: 'custom/PhotoLandscape2.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape3 mock',
                name: 'PhotoLandscape3',
                thumbnailURI: 'custom/PhotoLandscape3.png',
                stickerURI: 'custom/PhotoLandscape3.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape4 mock',
                name: 'PhotoLandscape4',
                thumbnailURI: 'custom/PhotoLandscape4.png',
                stickerURI: 'custom/PhotoLandscape4.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape5 mock',
                name: 'PhotoLandscape5',
                thumbnailURI: 'custom/PhotoLandscape5.png',
                stickerURI: 'custom/PhotoLandscape5.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape6 mock',
                name: 'PhotoLandscape6',
                thumbnailURI: 'custom/PhotoLandscape6.png',
                stickerURI: 'custom/PhotoLandscape6.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape7 mock',
                name: 'PhotoLandscape7',
                thumbnailURI: 'custom/PhotoLandscape7.png',
                stickerURI: 'custom/PhotoLandscape7.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoLandscape8 mock',
                name: 'PhotoLandscape8',
                thumbnailURI: 'custom/PhotoLandscape8.png',
                stickerURI: 'custom/PhotoLandscape8.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait1 mock',
                name: 'PhotoPortrait1',
                thumbnailURI: 'custom/PhotoPortrait1.png',
                stickerURI: 'custom/PhotoPortrait1.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait2 mock',
                name: 'PhotoPortrait2',
                thumbnailURI: 'custom/PhotoPortrait2.png',
                stickerURI: 'custom/PhotoPortrait2.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait3 mock',
                name: 'PhotoPortrait3',
                thumbnailURI: 'custom/PhotoPortrait3.png',
                stickerURI: 'custom/PhotoPortrait3.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait4 mock',
                name: 'PhotoPortrait4',
                thumbnailURI: 'custom/PhotoPortrait4.png',
                stickerURI: 'custom/PhotoPortrait4.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait5 mock',
                name: 'PhotoPortrait5',
                thumbnailURI: 'custom/PhotoPortrait5.png',
                stickerURI: 'custom/PhotoPortrait5.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait6 mock',
                name: 'PhotoPortrait6',
                thumbnailURI: 'custom/PhotoPortrait6.png',
                stickerURI: 'custom/PhotoPortrait6.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait7 mock',
                name: 'PhotoPortrait7',
                thumbnailURI: 'custom/PhotoPortrait7.png',
                stickerURI: 'custom/PhotoPortrait7.png',
                resizeMode: 'unrestricted',
            },
            {
                identifier: 'PhotoPortrait8 mock',
                name: 'PhotoPortrait8',
                thumbnailURI: 'custom/PhotoPortrait8.png',
                stickerURI: 'custom/PhotoPortrait8.png',
                resizeMode: 'unrestricted',
            },
        ],
    },
];
