import { setIsOpenTemplatesSidebar } from 'context/actions/editorActions';
import { Tool } from 'photoeditorsdk';
import { useDispatch } from 'react-redux';

export const useChangedToolHook = () => {
    const dispatch = useDispatch();

    const changedTool = async (tool: Tool) => {
        if (tool === Tool.LIBRARY) {
            dispatch(setIsOpenTemplatesSidebar(true));
        } else {
            dispatch(setIsOpenTemplatesSidebar(false));
        }
    };

    return { changedTool };
};
