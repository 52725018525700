import { ACCESS_TOKEN, REFRESH_TOKEN, REFRESH_TOKEN_EXPIRY_TIME, TOKEN_EXPIRY_TIME } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

export const getToken = async (code: string, verifier: string, eventId: string) => {
    const host = `${process.env.REACT_APP_TP_HOST}/oauth/token`;
    const client = process.env.REACT_APP_TP_CLIENT ?? '';
    const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/?event_id=${eventId}`;
    const grantType = 'authorization_code';

    if (code === null) return;

    // Build params to send to token endpoint
    const params = new FormData();
    params.append('client_id', client);
    params.append('grant_type', grantType);
    params.append('code_verifier', verifier);
    params.append('redirect_uri', redirectUri);
    params.append('code', code);

    // Make a POST request
    try {
        const response = await fetch(host, {
            method: 'POST',
            body: params,
        });

        const { status } = response;
        if (status === 200) {
            const data = await response.json();
            cookie.setCookie(ACCESS_TOKEN, data.access_token, TOKEN_EXPIRY_TIME);
            cookie.setCookie(REFRESH_TOKEN, data.refresh_token, REFRESH_TOKEN_EXPIRY_TIME);
            return status;
        }
        {
            return status;
        }
    } catch (error) {
        console.log(error);
        return 400;
    }
};
