export const TPFonts = [
    {
        identifier: '2-Questa_Grande_Regular',
        fontFamily: '2 Questa Grande Regular',
        fontURI: 'tp/2-Questa_Grande_Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Aaargh',
        fontFamily: 'Aaargh',
        fontURI: 'tp/Aaargh.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbhayaLibre-Bold',
        fontFamily: 'AbhayaLibre-Bold',
        fontURI: 'tp/AbhayaLibre-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbhayaLibre-ExtraBold',
        fontFamily: 'AbhayaLibre-ExtraBold',
        fontURI: 'tp/AbhayaLibre-ExtraBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbhayaLibre-Regular',
        fontFamily: 'AbhayaLibre-Regular',
        fontURI: 'tp/AbhayaLibre-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbhayaLibre-SemiBold',
        fontFamily: 'AbhayaLibre-SemiBold',
        fontURI: 'tp/AbhayaLibre-SemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AboutLove',
        fontFamily: 'About Love',
        fontURI: 'tp/AboutLove.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbrahamLincoln',
        fontFamily: 'Abraham Lincoln',
        fontURI: 'tp/AbrahamLincoln.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AbrilFatface-Regular',
        fontFamily: 'Abril Fatface Regular',
        fontURI: 'tp/AbrilFatface-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ActionMan',
        fontFamily: 'Action Man',
        fontURI: 'tp/Action Man.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ADELIA',
        fontFamily: 'Adelia',
        fontURI: 'tp/Adelia.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AdobeDevanagari-Regular',
        fontFamily: 'Adobe Devanagari 844',
        fontURI: 'tp/adobe-devanagari_844.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'adorabouton',
        fontFamily: 'Adorabouton',
        fontURI: 'tp/adorabouton.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AdornCondensedSans',
        fontFamily: 'AdornCondensedSans',
        fontURI: 'tp/AdornCondensedSans.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'adornpomander',
        fontFamily: 'Adornpomander',
        fontURI: 'tp/adornpomander.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Afterglow-Regular',
        fontFamily: 'Afterglow Regular',
        fontURI: 'tp/Afterglow-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AIAEverestMedium',
        fontFamily: 'AIAEverestMedium',
        fontURI: 'tp/AIAEverestMedium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Alata-Regular',
        fontFamily: 'Alata-Regular',
        fontURI: 'tp/Alata-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AlfaSlabOne-Regular',
        fontFamily: 'AlfaSlabOne-Regular',
        fontURI: 'tp/AlfaSlabOne-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'a_AlgeriusNrCmUp',
        fontFamily: 'Algerius',
        fontURI: 'tp/a_AlgeriusNrCmUp.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Alice-Regular',
        fontFamily: 'Alice-Regular',
        fontURI: 'tp/Alice-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Alisandra-Demo',
        fontFamily: 'Alisandra',
        fontURI: 'tp/Alisandra Demo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Amagro',
        fontFamily: 'Amagro',
        fontURI: 'tp/Amagro.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Amarillo',
        fontFamily: 'Amarillo',
        fontURI: 'tp/Amarillo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Amatic-Bold',
        fontFamily: 'Amatic Bold',
        fontURI: 'tp/Amatic-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Amatic-Regular',
        fontFamily: 'Amatic Regular',
        fontURI: 'tp/Amatic-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'American Legend',
        fontFamily: 'American Legend',
        fontURI: 'tp/American Legend.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'american purpose stripe 1 normal',
        fontFamily: 'American Purpose Stripe 1 Normal',
        fontURI: 'tp/american purpose stripe 1 normal.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Amithen',
        fontFamily: 'Amithen',
        fontURI: 'tp/Amithen.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ANDALEMO',
        fontFamily: 'Andalemo',
        fontURI: 'tp/ANDALEMO.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Andantinoscript',
        fontFamily: 'Andantino Script',
        fontURI: 'tp/Andantino script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Andasia',
        fontFamily: 'Andasia',
        fontURI: 'tp/Andasia.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Anja Eliane',
        fontFamily: 'Anja Eliane',
        fontURI: 'tp/Anja Eliane.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Antonio-Regular',
        fontFamily: 'Antonio Regular',
        fontURI: 'tp/Antonio-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Apex Serif Bold Regular',
        fontFamily: 'Apex Serif Bold Regular',
        fontURI: 'tp/Apex Serif Bold Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Aquitaine-CapsMedium',
        fontFamily: 'Aquitaine Caps Medium',
        fontURI: 'tp/Aquitaine-CapsMedium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Arbat-Bold',
        fontFamily: 'Arbat-Bold',
        fontURI: 'tp/Arbat-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ArbatDiBold',
        fontFamily: 'ArbatDi Bold',
        fontURI: 'tp/ArbatDi-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Argor-Got-Scaqh',
        fontFamily: 'Argor-Got-Scaqh',
        fontURI: 'tp/Argor-Got-Scaqh.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Arial-Black',
        fontFamily: 'Arial-Black',
        fontURI: 'tp/ariblk.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ArialRoundedMTBold',
        fontFamily: 'ArialRoundedMTBold',
        fontURI: 'tp/ARLRDBD.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AriaScriptSsk',
        fontFamily: 'Ariascriptssk',
        fontURI: 'tp/ariascriptssk.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Arkona-Medium',
        fontFamily: 'Arkona-Medium',
        fontURI: 'tp/arkona_medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Artisocrat',
        fontFamily: 'Artisocrat',
        fontURI: 'tp/Artisocrat.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Arvo-Bold',
        fontFamily: 'Arvo-Bold',
        fontURI: 'tp/Arvo-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Arvo-Regular',
        fontFamily: 'Arvo-Regular',
        fontURI: 'tp/Arvo-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Atlane OTF',
        fontFamily: 'Atlane Otf',
        fontURI: 'tp/Atlane OTF.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Atlas',
        fontFamily: 'Atlas',
        fontURI: 'tp/Atlas.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AucoinExtBol',
        fontFamily: 'AucoinExtBol',
        fontURI: 'tp/AucoinExtBol.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Authentic Signature',
        fontFamily: 'Authentic Signature',
        fontURI: 'tp/Authentic Signature.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AveFedanPERSONALUSEONLY',
        fontFamily: 'AveFedan',
        fontURI: 'tp/AveFedan_PERSONAL_USE.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Avenir Medium',
        fontFamily: 'Avenir Medium',
        fontURI: 'tp/Avenir Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'AvenirLTStd-Roman',
        fontFamily: 'Avenir Lt Std Roman',
        fontURI: 'tp/AvenirLTStd-Roman.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bacalisties',
        fontFamily: 'Bacalisties',
        fontURI: 'tp/Bacalisties.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bachelor-Pad-Shadow-JL-Italic',
        fontFamily: 'Bachelor-Pad-Shadow-JL-Italic',
        fontURI: 'tp/Bachelor-Pad-Shadow-JL-Italic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Backlit',
        fontFamily: 'Backlit',
        fontURI: 'tp/Backlit.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baggage-Regular',
        fontFamily: 'Baggage-Regular',
        fontURI: 'tp/Baggage-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Ballet-Engraved-T',
        fontFamily: 'Ballet-Engraved-T',
        fontURI: 'tp/Ballet-Engraved-T.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'balloons',
        fontFamily: 'Balloons',
        fontURI: 'tp/balloons.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baloo-Regular',
        fontFamily: 'Baloo Regular',
        fontURI: 'tp/Baloo-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bangers-Regular',
        fontFamily: 'Bangers Regular',
        fontURI: 'tp/Bangers-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Barrio-Regular',
        fontFamily: 'Barrio Regular',
        fontURI: 'tp/Barrio-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baskerville Bold',
        fontFamily: 'Baskerville Bold',
        fontURI: 'tp/Baskerville Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baskerville SemiBold',
        fontFamily: 'Baskerville SemiBold',
        fontURI: 'tp/Baskerville SemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baskerville-LightA',
        fontFamily: 'Baskrv L',
        fontURI: 'tp/BASKRV_L.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baskervville-Italic',
        fontFamily: 'Baskervville-Italic',
        fontURI: 'tp/Baskervville-Italic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Baskervville-Regular',
        fontFamily: 'Baskervville-Regular',
        fontURI: 'tp/Baskervville-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bastian',
        fontFamily: 'Bastian',
        fontURI: 'tp/Bastian.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BEATRICE',
        fontFamily: 'BEATRICE',
        fontURI: 'tp/BEATRICE.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Beatriz Abilla',
        fontFamily: 'Beatriz Abilla',
        fontURI: 'tp/Beatriz Abilla.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Beauty',
        fontFamily: 'Beauty Demo',
        fontURI: 'tp/BeautyDemo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bebas-Regular',
        fontFamily: 'Bebas Regular',
        fontURI: 'tp/Bebas-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bellota-Bold',
        fontFamily: 'Bellota Bold',
        fontURI: 'tp/Bellota-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BenguiatBold',
        fontFamily: 'Benguib',
        fontURI: 'tp/benguib.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BerkshireSwash-Regular',
        fontFamily: 'Berkshire Swash Regular',
        fontURI: 'tp/BerkshireSwash-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'bernadette',
        fontFamily: 'Bernadette',
        fontURI: 'tp/bernadette.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BERNIERShade-Regular',
        fontFamily: 'BERNIERShade-Regular',
        fontURI: 'tp/BERNIERShade-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Best School',
        fontFamily: 'Best School',
        fontURI: 'tp/Best School.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Better Fonts',
        fontFamily: 'Better Fonts',
        fontURI: 'tp/Better Fonts.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BickhamScriptPro-Bold',
        fontFamily: 'Bickham Script Pro Bold',
        fontURI: 'tp/BickhamScriptPro-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Big Caslon Medium',
        fontFamily: 'Big Caslon Medium',
        fontURI: 'tp/Big Caslon Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BigelowRules-Regular',
        fontFamily: 'Bigelow Rules Regular',
        fontURI: 'tp/BigelowRules-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BillionDreams',
        fontFamily: 'Billion Dreams',
        fontURI: 'tp/BillionDreams.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BillyScriptian',
        fontFamily: 'Billy Scriptian',
        fontURI: 'tp/Billy Scriptian.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bison-Bold',
        fontFamily: 'Bison-Bold',
        fontURI: 'tp/Bison-Bold(PersonalUse).woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BLACKBUBBLE',
        fontFamily: 'Black Bubble',
        fontURI: 'tp/Black Bubble.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BlackChancery',
        fontFamily: 'BlackChancery',
        fontURI: 'tp/BLKCHCRY.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'blackHandTRIAL',
        fontFamily: 'Black Hand Trial',
        fontURI: 'tp/blackHand_TRIAL.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BlackJackRegular',
        fontFamily: 'Black Jack Regular',
        fontURI: 'tp/BLACKJAR.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BOD_R',
        fontFamily: 'BOD_R',
        fontURI: 'tp/BOD_R.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bodoni-Serial-Black-Regular',
        fontFamily: 'Bodoni Serial Black Regular',
        fontURI: 'tp/bodoni-serial-black-regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'bodoni-serial-black-regular 2',
        fontFamily: 'Bodoni Serial Black Regular 2',
        fontURI: 'tp/bodoni-serial-black-regular 2.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'bodoni-seventytwo-itc-book',
        fontFamily: 'Bodoni Seventytwo Itc Book',
        fontURI: 'tp/bodoni-seventytwo-itc-book.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BodoniMT',
        fontFamily: 'Bodoni Mt',
        fontURI: 'tp/BodoniMT.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BodoniSevITC-Book',
        fontFamily: 'Bodoni Svty Two Itc Tt Book',
        fontURI: 'tp/FontsFree-Net-Bodoni-SvtyTwo-ITC-TT-Book (2).woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BodoniSvtyTwoOSITCTTBold',
        fontFamily: 'Bodoni Svty Two Os Itc Tt Bold',
        fontURI: 'tp/Bodoni_SvtyTwo_OS_ITC_TT_Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BodoniXT',
        fontFamily: 'Bodoni Xt',
        fontURI: 'tp/BodoniXT.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BohoLifestyleRegular',
        fontFamily: 'Boho Lifestyle',
        fontURI: 'tp/Boho-Lifestyle.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BordelliaDemo',
        fontFamily: 'Bordellia',
        fontURI: 'tp/Bordellia Demo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Boughy-Regular',
        fontFamily: 'Boughy-Regular',
        fontURI: 'tp/Boughy-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bright Holiday',
        fontFamily: 'Bright Holiday',
        fontURI: 'tp/Bright Holiday.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Brightly Crush Shine',
        fontFamily: 'Brightly Crush Shine',
        fontURI: 'tp/Brightly Crush Shine.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Brightons-OVOro',
        fontFamily: 'Brightons Ov Oro',
        fontURI: 'tp/Brightons-OVOro.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Broadway',
        fontFamily: 'Broadway Regular',
        fontURI: 'tp/Broadway Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'budmo jigglish',
        fontFamily: 'Budmo Jigglish',
        fontURI: 'tp/BudmoJigglish.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BudmoJiggler',
        fontFamily: 'BudmoJiggler',
        fontURI: 'tp/BudmoJiggler.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Buffalo-Regular',
        fontFamily: 'Buffalo-Regular',
        fontURI: 'tp/Buffalo-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Bungee-Regular',
        fontFamily: 'Bungee Regular',
        fontURI: 'tp/Bungee-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'BurritoOne',
        fontFamily: 'Burrito',
        fontURI: 'tp/burrito.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Butler-Black',
        fontFamily: 'Butler Black',
        fontURI: 'tp/Butler_Black.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Cambria-Font-For Android',
        fontFamily: 'Cambria Font for Android',
        fontURI: 'tp/Cambria-Font-For Android.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CandlescriptDemoVersion',
        fontFamily: 'Candlescript Demo Version',
        fontURI: 'tp/Candlescript Demo Version.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CarnevaleeFreakshow',
        fontFamily: 'Carnevalee Freakshow',
        fontURI: 'tp/Carnevalee Freakshow.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CasinoShadow-Regular',
        fontFamily: 'Casino Shadow',
        fontURI: 'tp/CasinoShadow.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Caviar_Dreams_Bold',
        fontFamily: 'Caviar_Dreams_Bold',
        fontURI: 'tp/Caviar_Dreams_Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CaviarDreams',
        fontFamily: 'CaviarDreams',
        fontURI: 'tp/CaviarDreams.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CaviarDreams_BoldItalic',
        fontFamily: 'CaviarDreams_BoldItalic',
        fontURI: 'tp/CaviarDreams_BoldItalic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CaviarDreams_Italic',
        fontFamily: 'CaviarDreams_Italic',
        fontURI: 'tp/CaviarDreams_Italic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CC-Fat-Regular',
        fontFamily: 'CC-Fat-Regular',
        fontURI: 'tp/CC-Fat-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Century Normal',
        fontFamily: 'Century Normal',
        fontURI: 'tp/Century Normal.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ChalkboardSE-Regular',
        fontFamily: 'Chalkboard Se Regular',
        fontURI: 'tp/copyfonts.com_chalkboard-se-regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Champagne&Limousines-Bold',
        fontFamily: 'Champagne & Limousines Bold',
        fontURI: 'tp/Champagne & Limousines Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'charlotte',
        fontFamily: 'Charlotte',
        fontURI: 'tp/charlotte.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Charlyne',
        fontFamily: 'Charlyne',
        fontURI: 'tp/Charlyne.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CharterBT',
        fontFamily: 'Charter Bt',
        fontURI: 'tp/CharterBT.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ChristmasBless',
        fontFamily: 'Christmas Bless',
        fontURI: 'tp/Christmas Bless.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ChunkFive-Roman',
        fontFamily: 'Chunkfive',
        fontURI: 'tp/Chunkfive.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Cinzel-Regular',
        fontFamily: 'Cinzel Regular',
        fontURI: 'tp/Cinzel-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CinzelDecorative-Regular',
        fontFamily: 'Cinzel Decorative Regular',
        fontURI: 'tp/CinzelDecorative-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Circusia',
        fontFamily: 'Circusia',
        fontURI: 'tp/Circusia.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Citadel',
        fontFamily: 'Citadel',
        fontURI: 'tp/citadel.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CitadelScriptStd',
        fontFamily: 'Citadel Script Std Regular',
        fontURI: 'tp/Citadel Script Std Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Claudina Personal Use',
        fontFamily: 'Claudina',
        fontURI: 'tp/Claudina Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Comic Book',
        fontFamily: 'Comic Book',
        fontURI: 'tp/Comic Book.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'constan',
        fontFamily: 'Constan',
        fontURI: 'tp/constan.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'a_CopperGothCpsExp',
        fontFamily: 'Cooper Goth',
        fontURI: 'tp/a_CopperGothCpsExp.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CopperLightDBNormal',
        fontFamily: 'Copper Light DB Normal 11831',
        fontURI: 'tp/CopperLightDB-Normal_11831.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CopperPennyDTPNormal',
        fontFamily: 'Copper_Penny_DTP',
        fontURI: 'tp/Copper_Penny_DTP.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Copperplate-Gothic-Bold',
        fontFamily: 'Copperplate Gothic Bold',
        fontURI: 'tp/Copperplate-Gothic-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CopperplateCondensedSSiCondensed',
        fontFamily: 'Copperplate Condensed SSi Condensed',
        fontURI: 'tp/Copperplate Condensed SSi Condensed.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CopperplateLightSSiLight',
        fontFamily: 'Copperplate Light SSi Light',
        fontURI: 'tp/Copperplate Light SSi Light.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Coral Lovers',
        fontFamily: 'Coral Lovers',
        fontURI: 'tp/Coral Lovers.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CormorantGaramond-Bold',
        fontFamily: 'CormorantGaramond-Bold',
        fontURI: 'tp/CormorantGaramond-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CormorantInfant-Bold',
        fontFamily: 'CormorantInfant-Bold',
        fontURI: 'tp/CormorantInfant-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CormorantInfant-Medium',
        fontFamily: 'Cormorant Infant Medium',
        fontURI: 'tp/CormorantInfant-Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CormorantInfant-Regular',
        fontFamily: 'CormorantInfant-Regular',
        fontURI: 'tp/CormorantInfant-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CormorantInfant-SemiBold',
        fontFamily: 'Cormorant Infant Semi Bold',
        fontURI: 'tp/CormorantInfant-SemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CoronetI',
        fontFamily: 'Coronet I',
        fontURI: 'tp/CoronetI.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Cotasia-Regular',
        fontFamily: 'Cotasia',
        fontURI: 'tp/cotasia.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'CowboyaBifurcated',
        fontFamily: 'Cowboya Bifurcated',
        fontURI: 'tp/Cowboya-Bifurcated.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Coyote-SemiBoldDEMO',
        fontFamily: 'Coyote Semi Bold Demo',
        fontURI: 'tp/Coyote-SemiBoldDEMO.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Crafty Christmas',
        fontFamily: 'Crafty Christmas',
        fontURI: 'tp/Crafty Christmas.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'DalekPinpointBold',
        fontFamily: 'Dalek Pinpoint Bold',
        fontURI: 'tp/DalekPinpointBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Damion',
        fontFamily: 'Damion-Regular',
        fontURI: 'tp/Damion-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'DarkHellow',
        fontFamily: 'Dark Hellow',
        fontURI: 'tp/Dark Hellow.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Dayland',
        fontFamily: 'Dayland',
        fontURI: 'tp/Dayland.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'DemielaFree-Medium 500',
        fontFamily: 'Demiela Free Medium 500',
        fontURI: 'tp/DemielaFree-Medium 500.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'DKSmilingCat',
        fontFamily: 'Dk Smiling Cat',
        fontURI: 'tp/DK Smiling Cat.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'DoubleBubbleShadow',
        fontFamily: 'Double Bubble Shadow',
        fontURI: 'tp/Double_Bubble_shadow.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Douglas-Ancaster-Script',
        fontFamily: 'Douglas-Ancaster-Script',
        fontURI: 'tp/Douglas-Ancaster-Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Douglas-Calgury-Block',
        fontFamily: 'Douglas-Calgury-Block',
        fontURI: 'tp/Douglas-Calgury-Block.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'duality',
        fontFamily: 'Duality',
        fontURI: 'tp/duality.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'EcuyerDAX',
        fontFamily: 'Ecuyer R0902',
        fontURI: 'tp/EcuyerR0902.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Eina01-Regular',
        fontFamily: 'Eina01 Regular',
        fontURI: 'tp/Eina01-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Elsie-Regular',
        fontFamily: 'Elsie Regular',
        fontURI: 'tp/Elsie-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ElSkeleto-ZVEGx',
        fontFamily: 'El Skeleto Zve Gx',
        fontURI: 'tp/ElSkeleto-ZVEGx.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Embryo Open',
        fontFamily: 'Embryo Open',
        fontURI: 'tp/Embryo Open.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'F...VERMONT',
        fontFamily: 'F...VERMONT',
        fontURI: 'tp/F...VERMONT.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'FelixTitling',
        fontFamily: 'Felix Titling',
        fontURI: 'tp/FelixTitling.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Flanella',
        fontFamily: 'Flanella',
        fontURI: 'tp/Flanella.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'FontsFree-Net-Bison-BoldPersonalUse',
        fontFamily: 'Bison Bold',
        fontURI: 'tp/FontsFree-Net-Bison-BoldPersonalUse.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'FontsFree-Net-Segoe-UI-Bold',
        fontFamily: 'Segoe UI Bold',
        fontURI: 'tp/FontsFree-Net-Segoe-UI-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Forum',
        fontFamily: 'Forum Regular',
        fontURI: 'tp/Forum-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'FREAS___',
        fontFamily: 'Freas ',
        fontURI: 'tp/FREAS___.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'FredokaOne-Regular',
        fontFamily: 'Fredoka One Regular',
        fontURI: 'tp/FredokaOne-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'GARABD',
        fontFamily: 'GARABD',
        fontURI: 'tp/GARABD.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Garamond',
        fontFamily: 'Garamond Regular',
        fontURI: 'tp/Garamond Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'GoodFeelingScript',
        fontFamily: 'Good Feeling Script',
        fontURI: 'tp/Good Feeling Script Demo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Gotham-Bold',
        fontFamily: 'Gotham-Bold',
        fontURI: 'tp/Gotham-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'GreatVibes-Regular',
        fontFamily: 'Great Vibes Regular',
        fontURI: 'tp/GreatVibes-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'GreatVictorian-Swashed',
        fontFamily: 'Great Victorian Swashed',
        fontURI: 'tp/GreatVictorian-Swashed.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'a_GrotoGr',
        fontFamily: 'GrotoGr',
        fontURI: 'tp/a_GrotoGr.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HaiterbachDemoRegular',
        fontFamily: 'Haiterbach Demo Regular',
        fontURI: 'tp/HaiterbachDemoRegular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Halloween Script',
        fontFamily: 'Halloween Script',
        fontURI: 'tp/Halloween Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HalloweenMorning',
        fontFamily: 'Halloween Morning',
        fontURI: 'tp/Halloween Morning.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Harmony',
        fontFamily: 'Harmony',
        fontURI: 'tp/Harmony.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Haymaker',
        fontFamily: 'Haymaker',
        fontURI: 'tp/Haymaker.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Hello Sweets',
        fontFamily: 'Hello Sweets',
        fontURI: 'tp/Hello Sweets.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Hello_Pumpkin',
        fontFamily: 'Hello Pumpkin',
        fontURI: 'tp/Hello_Pumpkin.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HelloAlmeida-Regular',
        fontFamily: 'Hello Almeida',
        fontURI: 'tp/Hello Almeida.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HelloValentica-Regular',
        fontFamily: 'Hello Valentina',
        fontURI: 'tp/Hello Valentina.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Hellstand',
        fontFamily: 'Hellstand',
        fontURI: 'tp/Hellstand.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HennyPenny-Regular',
        fontFamily: 'Henny Penny Regular',
        fontURI: 'tp/HennyPenny-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'HighSummit',
        fontFamily: 'High Summit',
        fontURI: 'tp/High Summit.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Hotsnowfreevertion-Regular',
        fontFamily: 'HotsnowRegular',
        fontURI: 'tp/HotsnowFreeVertionRegular-mLO6P.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ICE_AGE',
        fontFamily: 'ICE_AGE',
        fontURI: 'tp/ICE_AGE.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Imbue-VariableFont_opsz,wght',
        fontFamily: 'Imbue-VariableFont_opsz,wght',
        fontURI: 'tp/Imbue-VariableFont_opsz,wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Itim-Regular',
        fontFamily: 'Itim-Regular',
        fontURI: 'tp/Itim-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'JosefinSans-Bold',
        fontFamily: 'Josefin Sans Bold',
        fontURI: 'tp/JosefinSans-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Jost-Bold',
        fontFamily: 'Jost Bold',
        fontURI: 'tp/Jost-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Jost-Regular',
        fontFamily: 'Jost Regular',
        fontURI: 'tp/Jost-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Jungle',
        fontFamily: 'Jungle Regular',
        fontURI: 'tp/Jungle Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'JungleJam',
        fontFamily: 'Jungle Jam',
        fontURI: 'tp/Jungle Jam.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'K22 Didoni',
        fontFamily: 'K22 Didoni',
        fontURI: 'tp/K22 Didoni.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Kallydreams',
        fontFamily: 'Kally Dreams',
        fontURI: 'tp/Kally dreams.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'KGEyesWideOpen',
        fontFamily: 'Kg Eyes Wide Open',
        fontURI: 'tp/KGEyesWideOpen.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'KGHAPPY',
        fontFamily: 'Kghappy',
        fontURI: 'tp/KGHAPPY.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'KGHAPPYShadows',
        fontFamily: 'Kghappy Shadows',
        fontURI: 'tp/KGHAPPYShadows.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'KompotDisplayDemo-4BMmx',
        fontFamily: 'Kompot Display Demo 4 B Mmx',
        fontURI: 'tp/KompotDisplayDemo-4BMmx.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Krungthep',
        fontFamily: 'Krungthep',
        fontURI: 'tp/Krungthep.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'KunstlerScript',
        fontFamily: 'Kunstler',
        fontURI: 'tp/KUNSTLER.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Lakesight_PersonalUseOnly',
        fontFamily: 'Lakesight_PersonalUseOnly',
        fontURI: 'tp/Lakesight_PersonalUseOnly.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Landliebe',
        fontFamily: 'Landliebe',
        fontURI: 'tp/Landliebe.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LasEnterPersonalUseOnly',
        fontFamily: 'LasEnter',
        fontURI: 'tp/LasEnterPersonalUseOnly-D301.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Le chant des Albatros',
        fontFamily: 'Le Chant Des Albatros',
        fontURI: 'tp/Le chant des Albatros.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LegacySerifITCTTBold',
        fontFamily: 'Legacy Serif ITC TT Bold',
        fontURI: 'tp/Legacy Serif ITC TT Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Legendaire FREE',
        fontFamily: 'Legendaire FREE',
        fontURI: 'tp/Legendaire FREE.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LEIXO-DEMO',
        fontFamily: 'LEIXO',
        fontURI: 'tp/LEIXO-DEMO.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LemonismDEMO-Regular',
        fontFamily: 'Lemonism Demo',
        fontURI: 'tp/lemonismDEMO.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LEMONMILK-Medium',
        fontFamily: 'Lemonmilk Medium',
        fontURI: 'tp/LEMONMILK-Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LibreBaskerville-Regular',
        fontFamily: 'Libre Baskerville Regular',
        fontURI: 'tp/LibreBaskerville-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LilitaOne-Regular',
        fontFamily: 'LilitaOne-Regular',
        fontURI: 'tp/LilitaOne-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Limelight-Regular',
        fontFamily: 'Limelight Regular',
        fontURI: 'tp/Limelight-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'linowrite',
        fontFamily: 'linowrite',
        fontURI: 'tp/linowrite.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Lobster_1_4',
        fontFamily: 'Lobster_1_4',
        fontURI: 'tp/Lobster_1_4.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Lora_Regular',
        fontFamily: 'Lora_Regular',
        fontURI: 'tp/Lora_Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'LuckiestGuy-Regular',
        fontFamily: 'Luckiest Guy Regular',
        fontURI: 'tp/LuckiestGuy-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Madelican',
        fontFamily: 'Madelican',
        fontURI: 'tp/Madelican.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Magic Christmas',
        fontFamily: 'Magic Christmas',
        fontURI: 'tp/Magic Christmas.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MagnetoB',
        fontFamily: 'Magneto B',
        fontURI: 'tp/MagnetoB.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Maper',
        fontFamily: 'Maper',
        fontURI: 'tp/Maper.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Maratre',
        fontFamily: 'Maratre',
        fontURI: 'tp/Maratre.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MardiGrasPersonalUse',
        fontFamily: 'Mardi Gras',
        fontURI: 'tp/Mardi Gras Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Market Fresh Inline Bold ALL CAPS',
        fontFamily: 'Market Fresh Inline Bold',
        fontURI: 'tp/MarketFreshInlineBold-AYPm.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MasterOfBreak',
        fontFamily: 'Master of Break',
        fontURI: 'tp/master_of_break.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Metalurdo',
        fontFamily: 'Metalurdo',
        fontURI: 'tp/Metalurdo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Michland Script',
        fontFamily: 'Michland Script',
        fontURI: 'tp/Michland Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Milkshake',
        fontFamily: 'Milkshake',
        fontURI: 'tp/Milkshake 400.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Milku',
        fontFamily: 'Milku',
        fontURI: 'tp/Milku.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ModernAesthetic-Regular',
        fontFamily: 'ModernAesthetic-Regular',
        fontURI: 'tp/ModernAesthetic-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Monoton-Regular',
        fontFamily: 'Monoton Regular',
        fontURI: 'tp/Monoton-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MonsterClubhouse',
        fontFamily: 'Monster Clubhouse',
        fontURI: 'tp/Monster Clubhouse.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Mont-Black',
        fontFamily: 'Mont Black',
        fontURI: 'tp/Mont-Black.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Mont-BlackItalic',
        fontFamily: 'Mont Black Italic',
        fontURI: 'tp/Mont-BlackItalic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Mont-Regular',
        fontFamily: 'Mont Regular',
        fontURI: 'tp/Mont-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Mont-SemiBold',
        fontFamily: 'Mont Semi Bold',
        fontURI: 'tp/Mont-SemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Montserrat-Bold',
        fontFamily: 'Montserrat-Bold',
        fontURI: 'tp/Montserrat-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Montserrat-Italic-VariableFont_wght',
        fontFamily: 'Montserrat-Italic-VariableFont_wght',
        fontURI: 'tp/Montserrat-Italic-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Montserrat-Light',
        fontFamily: 'Montserrat Light',
        fontURI: 'tp/Montserrat-Light.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Montserrat-Thin',
        fontFamily: 'Montserrat Thin',
        fontURI: 'tp/Montserrat-Thin.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Montserrat-VariableFont_wght',
        fontFamily: 'Montserrat-VariableFont_wght',
        fontURI: 'tp/Montserrat-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MountainsofChristmas-Bold',
        fontFamily: 'Mountainsof Christmas Bold',
        fontURI: 'tp/MountainsofChristmas-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'MyriadPro-Regular',
        fontFamily: 'Myriad Pro Regular',
        fontURI: 'tp/Myriad Pro Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NeonRetro-Regular',
        fontFamily: 'NeonRetro-Regular',
        fontURI: 'tp/NeonRetro-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Neothic',
        fontFamily: 'Neothic',
        fontURI: 'tp/Neothic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'New York',
        fontFamily: 'New York',
        fontURI: 'tp/New York.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'nexa-rust.sans-black (1)',
        fontFamily: 'Nexa Rust.sans Black',
        fontURI: 'tp/nexa-rust.sans-black (1).woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NexaRustHandmade-Extended',
        fontFamily: 'Nexa Rust Handmade Extended',
        fontURI: 'tp/NexaRustHandmade-Extended.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NexaRustSans-Black',
        fontFamily: 'Nexa Rust Sans Black',
        fontURI: 'tp/NexaRustSans-Black.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NexaRustScriptL-0',
        fontFamily: 'Nexa Rust Script L 0',
        fontURI: 'tp/NexaRustScriptL-0.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NexaRustSlab-BlackShadow01',
        fontFamily: 'Nexa Rust Slab Black Shadow',
        fontURI: 'tp/NexaRustSlab-BlackShadow01.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Nickainley-Normal',
        fontFamily: 'Nickainley Normal',
        fontURI: 'tp/Nickainley-Normal.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'NoraITC',
        fontFamily: 'Nora Itc',
        fontURI: 'tp/nora-itc.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Novecentowide-Bold',
        fontFamily: 'Novecentowide Bold',
        fontURI: 'tp/Novecentowide-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Novecentowide-Book',
        fontFamily: 'Novecentowide Book',
        fontURI: 'tp/Novecentowide-Book.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Novecentowide-DemiBold',
        fontFamily: 'Novecentowide Demi Bold',
        fontURI: 'tp/Novecentowide-DemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Novecentowide-Medium',
        fontFamily: 'Novecentowide Medium',
        fontURI: 'tp/Novecentowide-Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Novecentowide-Normal',
        fontFamily: 'Novecentowide Normal',
        fontURI: 'tp/Novecentowide-Normal.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OctinSportsRg-Regular',
        fontFamily: 'Octin Sports Rg',
        fontURI: 'tp/octin sports rg.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Only Organic',
        fontFamily: 'Only Organic',
        fontURI: 'tp/Only Organic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OpenSans-Italic-VariableFont_wdth,wght',
        fontFamily: 'OpenSans-Italic-VariableFont_wdth,wght',
        fontURI: 'tp/OpenSans-Italic-VariableFont_wdth,wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OpenSans-VariableFont_wdth,wght',
        fontFamily: 'OpenSans-VariableFont_wdth,wght',
        fontURI: 'tp/OpenSans-VariableFont_wdth,wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OpenSans_Bold',
        fontFamily: 'OpenSans_Bold',
        fontURI: 'tp/OpenSans_Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OpenSans_Condensed-Bold',
        fontFamily: 'Open Sans Condensed Bold',
        fontURI: 'tp/OpenSans_Condensed-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'OptimusPrinceps',
        fontFamily: 'Optimus Princeps',
        fontURI: 'tp/OptimusPrinceps.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Orange Personal Use',
        fontFamily: 'Orange',
        fontURI: 'tp/Orange Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Pacifico',
        fontFamily: 'Pacifico',
        fontURI: 'tp/Pacifico.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Paladise Script',
        fontFamily: 'Paladise Script',
        fontURI: 'tp/Paladise Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ParielSSKBoldItalic',
        fontFamily: 'Pariel Ssk Bold Italic',
        fontURI: 'tp/ParielSSK-Bold-Italic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ParkLaneNF',
        fontFamily: 'Park Lane Nf',
        fontURI: 'tp/Park Lane NF.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Photograph Signature',
        fontFamily: 'Photograph Signature',
        fontURI: 'tp/Photograph Signature.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PinkSunset-Italic',
        fontFamily: 'PinkSunset-Italic',
        fontURI: 'tp/PinkSunset-Italic.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PinkSunset-Regular',
        fontFamily: 'PinkSunset-Regular',
        fontURI: 'tp/PinkSunset-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PinyonScript-Regular',
        fontFamily: 'PinyonScript-Regular',
        fontURI: 'tp/PinyonScript-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PlayfairDisplay-Bold',
        fontFamily: 'PlayfairDisplay-Bold',
        fontURI: 'tp/PlayfairDisplay-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PlayfairDisplay-Italic-VariableFont_wght',
        fontFamily: 'PlayfairDisplay-Italic-VariableFont_wght',
        fontURI: 'tp/PlayfairDisplay-Italic-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PlayfairDisplay-Regular',
        fontFamily: 'Playfair Display Regular',
        fontURI: 'tp/PlayfairDisplay-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PlayfairDisplaySC-Bold',
        fontFamily: 'PlayfairDisplaySC-Bold',
        fontURI: 'tp/PlayfairDisplaySC-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Playlist-Script',
        fontFamily: 'Playlist Script',
        fontURI: 'tp/Playlist Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Poppins',
        fontFamily: 'Poppins Bold',
        fontURI: 'tp/Poppins-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Poppins-SemiBold',
        fontFamily: 'Poppins Semi Bold',
        fontURI: 'tp/Poppins-SemiBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PORCELAIN',
        fontFamily: 'Porcelain',
        fontURI: 'tp/PORCELAIN.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'PTSerif-Regular',
        fontFamily: 'Pt Serif Regular',
        fontURI: 'tp/PTSerif-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Quicksand_Book',
        fontFamily: 'Quicksand Book',
        fontURI: 'tp/Quicksand_Book.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Quicksilver',
        fontFamily: 'Quicksilver',
        fontURI: 'tp/Quicksilver.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Raleway-Black',
        fontFamily: 'Raleway Black',
        fontURI: 'tp/Raleway-Black.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rancho-Regular',
        fontFamily: 'Rancho Regular',
        fontURI: 'tp/Rancho-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Red Christmas - Personal Use',
        fontFamily: 'Red Christmas',
        fontURI: 'tp/Red Christmas - Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Reey-Regular',
        fontFamily: 'Reey Regular',
        fontURI: 'tp/Reey-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rellyan Charlotte',
        fontFamily: 'Rellyan Charlotte',
        fontURI: 'tp/Rellyan Charlotte.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'RifficFree-Bold',
        fontFamily: 'Riffic-Bold',
        fontURI: 'tp/RifficFree-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'RNSCamelia-Medium',
        fontFamily: 'RNSCamelia-Medium',
        fontURI: 'tp/RNSCamelia-Medium.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rockinsoda',
        fontFamily: 'Rockinsoda',
        fontURI: 'tp/Rockinsoda.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rockwell-ExtraBold',
        fontFamily: 'Rockwell Extra Bold',
        fontURI: 'tp/Rockwell-ExtraBold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'RockwellNova',
        fontFamily: 'RockwellNova',
        fontURI: 'tp/RockwellNova.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rockybilly',
        fontFamily: 'Rockybilly',
        fontURI: 'tp/Rockybilly.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'RollingBeat_PersonalUse',
        fontFamily: 'Rolling Beat',
        fontURI: 'tp/Rolling Beat_Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'ROMANAB',
        fontFamily: 'ROMANAB',
        fontURI: 'tp/ROMANAB.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'RougeScript-Regular',
        fontFamily: 'Rouge Script Regular',
        fontURI: 'tp/RougeScript-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rubik-Italic-VariableFont_wght',
        fontFamily: 'Rubik-Italic-VariableFont_wght',
        fontURI: 'tp/Rubik-Italic-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rubik-Regular',
        fontFamily: 'Rubik Regular',
        fontURI: 'tp/Rubik-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Rubik-VariableFont_wght',
        fontFamily: 'Rubik-VariableFont_wght',
        fontURI: 'tp/Rubik-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SalmelaScript',
        fontFamily: 'Salmela',
        fontURI: 'tp/salmela.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Saltacrus',
        fontFamily: 'Saltacrus',
        fontURI: 'tp/Saltacrus.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SanAntonioCharros_personal_use_only',
        fontFamily: 'San Antonio Charros',
        fontURI: 'tp/San Antonio Charros_personal_use_only.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Sanchezregular',
        fontFamily: 'Sanchezregular',
        fontURI: 'tp/Sanchezregular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Serenity Script',
        fontFamily: 'Serenity Script',
        fontURI: 'tp/Serenity Script.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SFComicScript-Bold',
        fontFamily: 'SF Comic Script Bold',
        fontURI: 'tp/SF Comic Script Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SharkParty',
        fontFamily: 'Shark Party',
        fontURI: 'tp/SharkParty.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Sign Painter Regular',
        fontFamily: 'Sign Painter Regular',
        fontURI: 'tp/Sign Painter Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Signatie',
        fontFamily: 'Signatie',
        fontURI: 'tp/Signatie.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SilentWoods',
        fontFamily: 'Silent Woods',
        fontURI: 'tp/Silent Woods.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Snowballs',
        fontFamily: 'Snowballs',
        fontURI: 'tp/Snowballs.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Snowinter-Free',
        fontFamily: 'Snowinter',
        fontURI: 'tp/Snowinter-Free-For-Personal-Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Sofia-Regular',
        fontFamily: 'Sofia Regular',
        fontURI: 'tp/Sofia-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SolsticeOfSuffering',
        fontFamily: 'Solstice of Suffering',
        fontURI: 'tp/SolsticeOfSuffering.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Soopafresh',
        fontFamily: 'Soopafresh',
        fontURI: 'tp/soopafre.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Soria-Bold',
        fontFamily: 'Soria Bold',
        fontURI: 'tp/Soria-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SpiritITCTT',
        fontFamily: 'Spirit Itc Tt',
        fontURI: 'tp/SpiritItcTt.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Sporter',
        fontFamily: 'Sporter',
        fontURI: 'tp/Sporter.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'SunmorePersonalUse',
        fontFamily: 'Sunmore',
        fontURI: 'tp/Sunmore Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Super Sedan',
        fontFamily: 'Super Sedan',
        fontURI: 'tp/Super Sedan.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'The-Soul-Of-Vodka',
        fontFamily: 'The-Soul-Of-Vodka',
        fontURI: 'tp/The-Soul-Of-Vodka.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'TheFontGatsby',
        fontFamily: 'The Font Gatsby',
        fontURI: 'tp/TheFontGatsby.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Tomatoes',
        fontFamily: 'Tomatoes O8 L8',
        fontURI: 'tp/Tomatoes-O8L8.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'TrajanPro-Bold',
        fontFamily: 'Trajan Pro Bold',
        fontURI: 'tp/TrajanPro-Bold.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'TrajanPro-Regular',
        fontFamily: 'Trajan Pro Regular',
        fontURI: 'tp/TrajanPro-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'TrajanPro3-Regular',
        fontFamily: 'Trajan Pro3 Regular',
        fontURI: 'tp/TrajanPro3-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Tribeca',
        fontFamily: 'Tribeca',
        fontURI: 'tp/Tribeca.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'TuscanMFNarrow',
        fontFamily: 'Tuscan MF Narrow',
        fontURI: 'tp/Tuscan MF Narrow.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Typo_Round_Regular_Demo',
        fontFamily: 'Typo Round Regular Demo',
        fontURI: 'tp/Typo_Round_Regular_Demo.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Uchiyama-Regular',
        fontFamily: 'Uchiyama',
        fontURI: 'tp/uchiyama.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'University',
        fontFamily: 'University',
        fontURI: 'tp/University.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Vangeda-Regular',
        fontFamily: 'Vangeda-Regular',
        fontURI: 'tp/Vangeda-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Varino-Normal',
        fontFamily: 'Varino - Normal',
        fontURI: 'tp/Varino - Normal.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Vidaloka-Regular',
        fontFamily: 'Vidaloka Regular',
        fontURI: 'tp/Vidaloka-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Viktor Script Regular',
        fontFamily: 'Viktor Script Regular',
        fontURI: 'tp/Viktor Script Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Vogue',
        fontFamily: 'Vogue',
        fontURI: 'tp/Vogue.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Wavecraft',
        fontFamily: 'Wavecraft',
        fontURI: 'tp/Wavecraft.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Weather Sunday - Personal Use',
        fontFamily: 'Weather Sunday Personal Use',
        fontURI: 'tp/Weather Sunday - Personal Use.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'WhiteAngelicaRegular',
        fontFamily: 'White Angelica',
        fontURI: 'tp/WhiteAngelica.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'WhiteChick',
        fontFamily: 'White Chick',
        fontURI: 'tp/White Chick.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'WorkSans-Black',
        fontFamily: 'Work Sans Black',
        fontURI: 'tp/WorkSans-Black.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'WorkSans-VariableFont_wght',
        fontFamily: 'Work Sans Variable Font Wght',
        fontURI: 'tp/WorkSans-VariableFont_wght.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
    {
        identifier: 'Young20s-Regular',
        fontFamily: 'Young Regular',
        fontURI: 'tp/Young20s-Regular.woff',
        format: 'woff',
        provider: 'file',
        fontWeight: 400,
        textMetrics: { unitsPerEm: 2048, ascender: 3000, descender: -1440 },
    },
];
