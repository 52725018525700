import { StickerCategory } from 'photoeditorsdk';

export const defaultStickers: StickerCategory[] = [
    {
        identifier: 'DEFAULT',
        name: 'DEFAULT',
        thumbnailURI: 'default/donut2.png',
        items: [
            {
                identifier: 'touchpix-qr-file',
                name: 'touchpix-qr-file',
                thumbnailURI: 'default/touchpix-qr-file.png',
                stickerURI: 'default/touchpix-qr-file.png',
            },
            {
                identifier: 'touchpix-qr-gallery',
                name: 'touchpix-qr-gallery',
                thumbnailURI: 'default/touchpix-qr-gallery.png',
                stickerURI: 'default/touchpix-qr-gallery.png',
            },
            {
                identifier: 'arrow1',
                name: 'arrow1',
                thumbnailURI: 'default/arrow1.png',
                stickerURI: 'default/arrow1.png',
            },
            {
                identifier: 'arrow2',
                name: 'arrow2',
                thumbnailURI: 'default/arrow2.png',
                stickerURI: 'default/arrow2.png',
            },
            {
                identifier: 'balloon_dog',
                name: 'balloon_dog',
                thumbnailURI: 'default/balloon_dog.png',
                stickerURI: 'default/balloon_dog.png',
            },
            {
                identifier: 'balloon_heart',
                name: 'balloon_heart',
                thumbnailURI: 'default/balloon_heart.png',
                stickerURI: 'default/balloon_heart.png',
            },
            {
                identifier: 'balloon_moon',
                name: 'balloon_moon',
                thumbnailURI: 'default/balloon_moon.png',
                stickerURI: 'default/balloon_moon.png',
            },
            {
                identifier: 'balloon_star1',
                name: 'balloon_star1',
                thumbnailURI: 'default/balloon_star1.png',
                stickerURI: 'default/balloon_star1.png',
            },
            {
                identifier: 'balloon_star2',
                name: 'balloon_star2',
                thumbnailURI: 'default/balloon_star2.png',
                stickerURI: 'default/balloon_star2.png',
            },
            {
                identifier: 'balloon_star3',
                name: 'balloon_star3',
                thumbnailURI: 'default/balloon_star3.png',
                stickerURI: 'default/balloon_star3.png',
            },
            {
                identifier: 'cake1',
                name: 'cake1',
                thumbnailURI: 'default/cake1.png',
                stickerURI: 'default/cake1.png',
            },
            {
                identifier: 'cake2',
                name: 'cake2',
                thumbnailURI: 'default/cake2.png',
                stickerURI: 'default/cake2.png',
            },
            {
                identifier: 'cocktail',
                name: 'cocktail',
                thumbnailURI: 'default/cocktail.png',
                stickerURI: 'default/cocktail.png',
            },
            {
                identifier: 'confetti_popper',
                name: 'confetti_popper',
                thumbnailURI: 'default/confetti_popper.png',
                stickerURI: 'default/confetti_popper.png',
            },
            {
                identifier: 'cupcake1',
                name: 'cupcake1',
                thumbnailURI: 'default/cupcake1.png',
                stickerURI: 'default/cupcake1.png',
            },
            {
                identifier: 'cupcake2',
                name: 'cupcake2',
                thumbnailURI: 'default/cupcake2.png',
                stickerURI: 'default/cupcake2.png',
            },
            {
                identifier: 'donut1',
                name: 'donut1',
                thumbnailURI: 'default/donut1.png',
                stickerURI: 'default/donut1.png',
            },
            {
                identifier: 'donut2',
                name: 'donut2',
                thumbnailURI: 'default/donut2.png',
                stickerURI: 'default/donut2.png',
            },
            {
                identifier: 'donut3',
                name: 'donut3',
                thumbnailURI: 'default/donut3.png',
                stickerURI: 'default/donut3.png',
            },
            {
                identifier: 'donut4',
                name: 'donut4',
                thumbnailURI: 'default/donut4.png',
                stickerURI: 'default/donut4.png',
            },
            {
                identifier: 'donut5',
                name: 'donut5',
                thumbnailURI: 'default/donut5.png',
                stickerURI: 'default/donut5.png',
            },
            {
                identifier: 'flag_cake_topper1',
                name: 'flag_cake_topper1',
                thumbnailURI: 'default/flag_cake_topper1.png',
                stickerURI: 'default/flag_cake_topper1.png',
            },
            {
                identifier: 'flag_cake_topper2',
                name: 'flag_cake_topper2',
                thumbnailURI: 'default/flag_cake_topper2.png',
                stickerURI: 'default/flag_cake_topper2.png',
            },
            {
                identifier: 'flag_cake_topper3',
                name: 'flag_cake_topper3',
                thumbnailURI: 'default/flag_cake_topper3.png',
                stickerURI: 'default/flag_cake_topper3.png',
            },
            {
                identifier: 'flag_cake_topper4',
                name: 'flag_cake_topper4',
                thumbnailURI: 'default/flag_cake_topper4.png',
                stickerURI: 'default/flag_cake_topper4.png',
            },
            {
                identifier: 'gift',
                name: 'gift',
                thumbnailURI: 'default/gift.png',
                stickerURI: 'default/gift.png',
            },
            {
                identifier: 'happy_birthday_banner',
                name: 'happy_birthday_banner',
                thumbnailURI: 'default/happy_birthday_banner.png',
                stickerURI: 'default/happy_birthday_banner.png',
            },
            {
                identifier: 'lips_cake_topper1',
                name: 'lips_cake_topper1',
                thumbnailURI: 'default/lips_cake_topper1.png',
                stickerURI: 'default/lips_cake_topper1.png',
            },
            {
                identifier: 'lips_cake_topper2',
                name: 'lips_cake_topper2',
                thumbnailURI: 'default/lips_cake_topper2.png',
                stickerURI: 'default/lips_cake_topper2.png',
            },
            {
                identifier: 'paper_bag',
                name: 'paper_bag',
                thumbnailURI: 'default/paper_bag.png',
                stickerURI: 'default/paper_bag.png',
            },
            {
                identifier: 'pink_heart',
                name: 'pink_heart',
                thumbnailURI: 'default/pink_heart.png',
                stickerURI: 'default/pink_heart.png',
            },
            {
                identifier: 'prosecco',
                name: 'prosecco',
                thumbnailURI: 'default/prosecco.png',
                stickerURI: 'default/prosecco.png',
            },
            {
                identifier: 'watermelon',
                name: 'watermelon',
                thumbnailURI: 'default/watermelon.png',
                stickerURI: 'default/watermelon.png',
            },
            {
                identifier: 'white_square',
                name: 'white_square',
                thumbnailURI: 'default/white_square.png',
                stickerURI: 'default/white_square.png',
            },
            // UNO
            {
                identifier: 'Stickers-001',
                name: 'Stickers-001',
                thumbnailURI: 'default/Stickers/Stickers-001.png',
                stickerURI: 'default/Stickers/Stickers-001.png',
            },
            {
                identifier: 'Stickers-002',
                name: 'Stickers-002',
                thumbnailURI: 'default/Stickers/Stickers-002.png',
                stickerURI: 'default/Stickers/Stickers-002.png',
            },
            {
                identifier: 'Stickers-003',
                name: 'Stickers-003',
                thumbnailURI: 'default/Stickers/Stickers-003.png',
                stickerURI: 'default/Stickers/Stickers-003.png',
            },
            {
                identifier: 'Stickers-004',
                name: 'Stickers-004',
                thumbnailURI: 'default/Stickers/Stickers-004.png',
                stickerURI: 'default/Stickers/Stickers-004.png',
            },
            {
                identifier: 'Stickers-005',
                name: 'Stickers-005',
                thumbnailURI: 'default/Stickers/Stickers-005.png',
                stickerURI: 'default/Stickers/Stickers-005.png',
            },
            {
                identifier: 'Stickers-006',
                name: 'Stickers-006',
                thumbnailURI: 'default/Stickers/Stickers-006.png',
                stickerURI: 'default/Stickers/Stickers-006.png',
            },
            {
                identifier: 'Stickers-007',
                name: 'Stickers-007',
                thumbnailURI: 'default/Stickers/Stickers-007.png',
                stickerURI: 'default/Stickers/Stickers-007.png',
            },
            {
                identifier: 'Stickers-008',
                name: 'Stickers-008',
                thumbnailURI: 'default/Stickers/Stickers-008.png',
                stickerURI: 'default/Stickers/Stickers-008.png',
            },
            {
                identifier: 'Stickers-009',
                name: 'Stickers-009',
                thumbnailURI: 'default/Stickers/Stickers-009.png',
                stickerURI: 'default/Stickers/Stickers-009.png',
            },
            {
                identifier: 'Stickers-010',
                name: 'Stickers-010',
                thumbnailURI: 'default/Stickers/Stickers-010.png',
                stickerURI: 'default/Stickers/Stickers-010.png',
            },
            {
                identifier: 'Stickers-011',
                name: 'Stickers-011',
                thumbnailURI: 'default/Stickers/Stickers-011.png',
                stickerURI: 'default/Stickers/Stickers-011.png',
            },
            {
                identifier: 'Stickers-012',
                name: 'Stickers-012',
                thumbnailURI: 'default/Stickers/Stickers-012.png',
                stickerURI: 'default/Stickers/Stickers-012.png',
            },
            {
                identifier: 'Stickers-013',
                name: 'Stickers-013',
                thumbnailURI: 'default/Stickers/Stickers-013.png',
                stickerURI: 'default/Stickers/Stickers-013.png',
            },
            {
                identifier: 'Stickers-014',
                name: 'Stickers-014',
                thumbnailURI: 'default/Stickers/Stickers-014.png',
                stickerURI: 'default/Stickers/Stickers-014.png',
            },
            {
                identifier: 'Stickers-015',
                name: 'Stickers-015',
                thumbnailURI: 'default/Stickers/Stickers-015.png',
                stickerURI: 'default/Stickers/Stickers-015.png',
            },
            {
                identifier: 'Stickers-016',
                name: 'Stickers-016',
                thumbnailURI: 'default/Stickers/Stickers-016.png',
                stickerURI: 'default/Stickers/Stickers-016.png',
            },
            {
                identifier: 'Stickers-017',
                name: 'Stickers-017',
                thumbnailURI: 'default/Stickers/Stickers-017.png',
                stickerURI: 'default/Stickers/Stickers-017.png',
            },
            {
                identifier: 'Stickers-018',
                name: 'Stickers-018',
                thumbnailURI: 'default/Stickers/Stickers-018.png',
                stickerURI: 'default/Stickers/Stickers-018.png',
            },
            {
                identifier: 'Stickers-019',
                name: 'Stickers-019',
                thumbnailURI: 'default/Stickers/Stickers-019.png',
                stickerURI: 'default/Stickers/Stickers-019.png',
            },
            {
                identifier: 'Stickers-020',
                name: 'Stickers-020',
                thumbnailURI: 'default/Stickers/Stickers-020.png',
                stickerURI: 'default/Stickers/Stickers-020.png',
            },
            {
                identifier: 'Stickers-021',
                name: 'Stickers-021',
                thumbnailURI: 'default/Stickers/Stickers-021.png',
                stickerURI: 'default/Stickers/Stickers-021.png',
            },
            {
                identifier: 'Stickers-022',
                name: 'Stickers-022',
                thumbnailURI: 'default/Stickers/Stickers-022.png',
                stickerURI: 'default/Stickers/Stickers-022.png',
            },
            {
                identifier: 'Stickers-023',
                name: 'Stickers-023',
                thumbnailURI: 'default/Stickers/Stickers-023.png',
                stickerURI: 'default/Stickers/Stickers-023.png',
            },
            {
                identifier: 'Stickers-024',
                name: 'Stickers-024',
                thumbnailURI: 'default/Stickers/Stickers-024.png',
                stickerURI: 'default/Stickers/Stickers-024.png',
            },
            {
                identifier: 'Stickers-025',
                name: 'Stickers-025',
                thumbnailURI: 'default/Stickers/Stickers-025.png',
                stickerURI: 'default/Stickers/Stickers-025.png',
            },
            {
                identifier: 'Stickers-026',
                name: 'Stickers-026',
                thumbnailURI: 'default/Stickers/Stickers-026.png',
                stickerURI: 'default/Stickers/Stickers-026.png',
            },
            {
                identifier: 'Stickers-027',
                name: 'Stickers-027',
                thumbnailURI: 'default/Stickers/Stickers-027.png',
                stickerURI: 'default/Stickers/Stickers-027.png',
            },
            {
                identifier: 'Stickers-028',
                name: 'Stickers-028',
                thumbnailURI: 'default/Stickers/Stickers-028.png',
                stickerURI: 'default/Stickers/Stickers-028.png',
            },
            {
                identifier: 'Stickers-029',
                name: 'Stickers-029',
                thumbnailURI: 'default/Stickers/Stickers-029.png',
                stickerURI: 'default/Stickers/Stickers-029.png',
            },
            {
                identifier: 'Stickers-030',
                name: 'Stickers-030',
                thumbnailURI: 'default/Stickers/Stickers-030.png',
                stickerURI: 'default/Stickers/Stickers-030.png',
            },
            {
                identifier: 'Stickers-031',
                name: 'Stickers-031',
                thumbnailURI: 'default/Stickers/Stickers-031.png',
                stickerURI: 'default/Stickers/Stickers-031.png',
            },
            {
                identifier: 'Stickers-032',
                name: 'Stickers-032',
                thumbnailURI: 'default/Stickers/Stickers-032.png',
                stickerURI: 'default/Stickers/Stickers-032.png',
            },
            {
                identifier: 'Stickers-033',
                name: 'Stickers-033',
                thumbnailURI: 'default/Stickers/Stickers-033.png',
                stickerURI: 'default/Stickers/Stickers-033.png',
            },
            {
                identifier: 'Stickers-034',
                name: 'Stickers-034',
                thumbnailURI: 'default/Stickers/Stickers-034.png',
                stickerURI: 'default/Stickers/Stickers-034.png',
            },
            {
                identifier: 'Stickers-035',
                name: 'Stickers-035',
                thumbnailURI: 'default/Stickers/Stickers-035.png',
                stickerURI: 'default/Stickers/Stickers-035.png',
            },
            {
                identifier: 'Stickers-036',
                name: 'Stickers-036',
                thumbnailURI: 'default/Stickers/Stickers-036.png',
                stickerURI: 'default/Stickers/Stickers-036.png',
            },
            {
                identifier: 'Stickers-037',
                name: 'Stickers-037',
                thumbnailURI: 'default/Stickers/Stickers-037.png',
                stickerURI: 'default/Stickers/Stickers-037.png',
            },
            {
                identifier: 'Stickers-038',
                name: 'Stickers-038',
                thumbnailURI: 'default/Stickers/Stickers-038.png',
                stickerURI: 'default/Stickers/Stickers-038.png',
            },
            {
                identifier: 'Stickers-039',
                name: 'Stickers-039',
                thumbnailURI: 'default/Stickers/Stickers-039.png',
                stickerURI: 'default/Stickers/Stickers-039.png',
            },
            {
                identifier: 'Stickers-040',
                name: 'Stickers-040',
                thumbnailURI: 'default/Stickers/Stickers-040.png',
                stickerURI: 'default/Stickers/Stickers-040.png',
            },
            {
                identifier: 'Stickers-041',
                name: 'Stickers-041',
                thumbnailURI: 'default/Stickers/Stickers-041.png',
                stickerURI: 'default/Stickers/Stickers-041.png',
            },
            {
                identifier: 'Stickers-042',
                name: 'Stickers-042',
                thumbnailURI: 'default/Stickers/Stickers-042.png',
                stickerURI: 'default/Stickers/Stickers-042.png',
            },
            {
                identifier: 'Stickers-043',
                name: 'Stickers-043',
                thumbnailURI: 'default/Stickers/Stickers-043.png',
                stickerURI: 'default/Stickers/Stickers-043.png',
            },
            {
                identifier: 'Stickers-044',
                name: 'Stickers-044',
                thumbnailURI: 'default/Stickers/Stickers-044.png',
                stickerURI: 'default/Stickers/Stickers-044.png',
            },
            {
                identifier: 'Stickers-045',
                name: 'Stickers-045',
                thumbnailURI: 'default/Stickers/Stickers-045.png',
                stickerURI: 'default/Stickers/Stickers-045.png',
            },
            {
                identifier: 'Stickers-046',
                name: 'Stickers-046',
                thumbnailURI: 'default/Stickers/Stickers-046.png',
                stickerURI: 'default/Stickers/Stickers-046.png',
            },
            {
                identifier: 'Stickers-047',
                name: 'Stickers-047',
                thumbnailURI: 'default/Stickers/Stickers-047.png',
                stickerURI: 'default/Stickers/Stickers-047.png',
            },
            {
                identifier: 'Stickers-048',
                name: 'Stickers-048',
                thumbnailURI: 'default/Stickers/Stickers-048.png',
                stickerURI: 'default/Stickers/Stickers-048.png',
            },
            {
                identifier: 'Stickers-049',
                name: 'Stickers-049',
                thumbnailURI: 'default/Stickers/Stickers-049.png',
                stickerURI: 'default/Stickers/Stickers-049.png',
            },
            {
                identifier: 'Stickers-050',
                name: 'Stickers-050',
                thumbnailURI: 'default/Stickers/Stickers-050.png',
                stickerURI: 'default/Stickers/Stickers-050.png',
            },
            {
                identifier: 'Stickers-051',
                name: 'Stickers-051',
                thumbnailURI: 'default/Stickers/Stickers-051.png',
                stickerURI: 'default/Stickers/Stickers-051.png',
            },
            {
                identifier: 'Stickers-052',
                name: 'Stickers-052',
                thumbnailURI: 'default/Stickers/Stickers-052.png',
                stickerURI: 'default/Stickers/Stickers-052.png',
            },
            {
                identifier: 'Stickers-053',
                name: 'Stickers-053',
                thumbnailURI: 'default/Stickers/Stickers-053.png',
                stickerURI: 'default/Stickers/Stickers-053.png',
            },
            {
                identifier: 'Stickers-054',
                name: 'Stickers-054',
                thumbnailURI: 'default/Stickers/Stickers-054.png',
                stickerURI: 'default/Stickers/Stickers-054.png',
            },
            {
                identifier: 'Stickers-055',
                name: 'Stickers-055',
                thumbnailURI: 'default/Stickers/Stickers-055.png',
                stickerURI: 'default/Stickers/Stickers-055.png',
            },
            {
                identifier: 'Stickers-056',
                name: 'Stickers-056',
                thumbnailURI: 'default/Stickers/Stickers-056.png',
                stickerURI: 'default/Stickers/Stickers-056.png',
            },
            {
                identifier: 'Stickers-057',
                name: 'Stickers-057',
                thumbnailURI: 'default/Stickers/Stickers-057.png',
                stickerURI: 'default/Stickers/Stickers-057.png',
            },
            {
                identifier: 'Stickers-058',
                name: 'Stickers-058',
                thumbnailURI: 'default/Stickers/Stickers-058.png',
                stickerURI: 'default/Stickers/Stickers-058.png',
            },
            {
                identifier: 'Stickers-059',
                name: 'Stickers-059',
                thumbnailURI: 'default/Stickers/Stickers-059.png',
                stickerURI: 'default/Stickers/Stickers-059.png',
            },
            {
                identifier: 'Stickers-060',
                name: 'Stickers-060',
                thumbnailURI: 'default/Stickers/Stickers-060.png',
                stickerURI: 'default/Stickers/Stickers-060.png',
            },
            {
                identifier: 'Stickers-061',
                name: 'Stickers-061',
                thumbnailURI: 'default/Stickers/Stickers-061.png',
                stickerURI: 'default/Stickers/Stickers-061.png',
            },
            {
                identifier: 'Stickers-062',
                name: 'Stickers-062',
                thumbnailURI: 'default/Stickers/Stickers-062.png',
                stickerURI: 'default/Stickers/Stickers-062.png',
            },
            {
                identifier: 'Stickers-063',
                name: 'Stickers-063',
                thumbnailURI: 'default/Stickers/Stickers-063.png',
                stickerURI: 'default/Stickers/Stickers-063.png',
            },
            {
                identifier: 'Stickers-064',
                name: 'Stickers-064',
                thumbnailURI: 'default/Stickers/Stickers-064.png',
                stickerURI: 'default/Stickers/Stickers-064.png',
            },
            {
                identifier: 'Stickers-065',
                name: 'Stickers-065',
                thumbnailURI: 'default/Stickers/Stickers-065.png',
                stickerURI: 'default/Stickers/Stickers-065.png',
            },
            {
                identifier: 'Stickers-066',
                name: 'Stickers-066',
                thumbnailURI: 'default/Stickers/Stickers-066.png',
                stickerURI: 'default/Stickers/Stickers-066.png',
            },
            {
                identifier: 'Stickers-067',
                name: 'Stickers-067',
                thumbnailURI: 'default/Stickers/Stickers-067.png',
                stickerURI: 'default/Stickers/Stickers-067.png',
            },
            {
                identifier: 'Stickers-068',
                name: 'Stickers-068',
                thumbnailURI: 'default/Stickers/Stickers-068.png',
                stickerURI: 'default/Stickers/Stickers-068.png',
            },
            {
                identifier: 'Stickers-069',
                name: 'Stickers-069',
                thumbnailURI: 'default/Stickers/Stickers-069.png',
                stickerURI: 'default/Stickers/Stickers-069.png',
            },
            {
                identifier: 'Stickers-070',
                name: 'Stickers-070',
                thumbnailURI: 'default/Stickers/Stickers-070.png',
                stickerURI: 'default/Stickers/Stickers-070.png',
            },
            {
                identifier: 'Stickers-071',
                name: 'Stickers-071',
                thumbnailURI: 'default/Stickers/Stickers-071.png',
                stickerURI: 'default/Stickers/Stickers-071.png',
            },
            {
                identifier: 'Stickers-072',
                name: 'Stickers-072',
                thumbnailURI: 'default/Stickers/Stickers-072.png',
                stickerURI: 'default/Stickers/Stickers-072.png',
            },
            {
                identifier: 'Stickers-073',
                name: 'Stickers-073',
                thumbnailURI: 'default/Stickers/Stickers-073.png',
                stickerURI: 'default/Stickers/Stickers-073.png',
            },
            {
                identifier: 'Stickers-074',
                name: 'Stickers-074',
                thumbnailURI: 'default/Stickers/Stickers-074.png',
                stickerURI: 'default/Stickers/Stickers-074.png',
            },
            {
                identifier: 'Stickers-075',
                name: 'Stickers-075',
                thumbnailURI: 'default/Stickers/Stickers-075.png',
                stickerURI: 'default/Stickers/Stickers-075.png',
            },
            {
                identifier: 'Stickers-076',
                name: 'Stickers-076',
                thumbnailURI: 'default/Stickers/Stickers-076.png',
                stickerURI: 'default/Stickers/Stickers-076.png',
            },
            {
                identifier: 'Stickers-077',
                name: 'Stickers-077',
                thumbnailURI: 'default/Stickers/Stickers-077.png',
                stickerURI: 'default/Stickers/Stickers-077.png',
            },
            {
                identifier: 'Stickers-078',
                name: 'Stickers-078',
                thumbnailURI: 'default/Stickers/Stickers-078.png',
                stickerURI: 'default/Stickers/Stickers-078.png',
            },
            {
                identifier: 'Stickers-079',
                name: 'Stickers-079',
                thumbnailURI: 'default/Stickers/Stickers-079.png',
                stickerURI: 'default/Stickers/Stickers-079.png',
            },
            {
                identifier: 'Stickers-080',
                name: 'Stickers-080',
                thumbnailURI: 'default/Stickers/Stickers-080.png',
                stickerURI: 'default/Stickers/Stickers-080.png',
            },
            {
                identifier: 'Stickers-081',
                name: 'Stickers-081',
                thumbnailURI: 'default/Stickers/Stickers-081.png',
                stickerURI: 'default/Stickers/Stickers-081.png',
            },
            {
                identifier: 'Stickers-082',
                name: 'Stickers-082',
                thumbnailURI: 'default/Stickers/Stickers-082.png',
                stickerURI: 'default/Stickers/Stickers-082.png',
            },
            {
                identifier: 'Stickers-083',
                name: 'Stickers-083',
                thumbnailURI: 'default/Stickers/Stickers-083.png',
                stickerURI: 'default/Stickers/Stickers-083.png',
            },
            {
                identifier: 'Stickers-084',
                name: 'Stickers-084',
                thumbnailURI: 'default/Stickers/Stickers-084.png',
                stickerURI: 'default/Stickers/Stickers-084.png',
            },
            {
                identifier: 'Stickers-085',
                name: 'Stickers-085',
                thumbnailURI: 'default/Stickers/Stickers-085.png',
                stickerURI: 'default/Stickers/Stickers-085.png',
            },
            {
                identifier: 'Stickers-086',
                name: 'Stickers-086',
                thumbnailURI: 'default/Stickers/Stickers-086.png',
                stickerURI: 'default/Stickers/Stickers-086.png',
            },
            {
                identifier: 'Stickers-087',
                name: 'Stickers-087',
                thumbnailURI: 'default/Stickers/Stickers-087.png',
                stickerURI: 'default/Stickers/Stickers-087.png',
            },
            {
                identifier: 'Stickers-088',
                name: 'Stickers-088',
                thumbnailURI: 'default/Stickers/Stickers-088.png',
                stickerURI: 'default/Stickers/Stickers-088.png',
            },
            {
                identifier: 'Stickers-089',
                name: 'Stickers-089',
                thumbnailURI: 'default/Stickers/Stickers-089.png',
                stickerURI: 'default/Stickers/Stickers-089.png',
            },
            {
                identifier: 'Stickers-090',
                name: 'Stickers-090',
                thumbnailURI: 'default/Stickers/Stickers-090.png',
                stickerURI: 'default/Stickers/Stickers-090.png',
            },
            {
                identifier: 'Stickers-091',
                name: 'Stickers-091',
                thumbnailURI: 'default/Stickers/Stickers-091.png',
                stickerURI: 'default/Stickers/Stickers-091.png',
            },
            {
                identifier: 'Stickers-092',
                name: 'Stickers-092',
                thumbnailURI: 'default/Stickers/Stickers-092.png',
                stickerURI: 'default/Stickers/Stickers-092.png',
            },
            {
                identifier: 'Stickers-093',
                name: 'Stickers-093',
                thumbnailURI: 'default/Stickers/Stickers-093.png',
                stickerURI: 'default/Stickers/Stickers-093.png',
            },
            {
                identifier: 'Stickers-094',
                name: 'Stickers-094',
                thumbnailURI: 'default/Stickers/Stickers-094.png',
                stickerURI: 'default/Stickers/Stickers-094.png',
            },
            {
                identifier: 'Stickers-095',
                name: 'Stickers-095',
                thumbnailURI: 'default/Stickers/Stickers-095.png',
                stickerURI: 'default/Stickers/Stickers-095.png',
            },
            {
                identifier: 'Stickers-096',
                name: 'Stickers-096',
                thumbnailURI: 'default/Stickers/Stickers-096.png',
                stickerURI: 'default/Stickers/Stickers-096.png',
            },
            {
                identifier: 'Stickers-097',
                name: 'Stickers-097',
                thumbnailURI: 'default/Stickers/Stickers-097.png',
                stickerURI: 'default/Stickers/Stickers-097.png',
            },
            {
                identifier: 'Stickers-098',
                name: 'Stickers-098',
                thumbnailURI: 'default/Stickers/Stickers-098.png',
                stickerURI: 'default/Stickers/Stickers-098.png',
            },
            {
                identifier: 'Stickers-099',
                name: 'Stickers-099',
                thumbnailURI: 'default/Stickers/Stickers-099.png',
                stickerURI: 'default/Stickers/Stickers-099.png',
            },
            {
                identifier: 'Stickers-100',
                name: 'Stickers-100',
                thumbnailURI: 'default/Stickers/Stickers-100.png',
                stickerURI: 'default/Stickers/Stickers-100.png',
            },
            {
                identifier: 'Stickers-101',
                name: 'Stickers-101',
                thumbnailURI: 'default/Stickers/Stickers-101.png',
                stickerURI: 'default/Stickers/Stickers-101.png',
            },
            {
                identifier: 'Stickers-102',
                name: 'Stickers-102',
                thumbnailURI: 'default/Stickers/Stickers-102.png',
                stickerURI: 'default/Stickers/Stickers-102.png',
            },
            {
                identifier: 'Stickers-103',
                name: 'Stickers-103',
                thumbnailURI: 'default/Stickers/Stickers-103.png',
                stickerURI: 'default/Stickers/Stickers-103.png',
            },
            {
                identifier: 'Stickers-104',
                name: 'Stickers-104',
                thumbnailURI: 'default/Stickers/Stickers-104.png',
                stickerURI: 'default/Stickers/Stickers-104.png',
            },
            {
                identifier: 'Stickers-105',
                name: 'Stickers-105',
                thumbnailURI: 'default/Stickers/Stickers-105.png',
                stickerURI: 'default/Stickers/Stickers-105.png',
            },
            {
                identifier: 'Stickers-106',
                name: 'Stickers-106',
                thumbnailURI: 'default/Stickers/Stickers-106.png',
                stickerURI: 'default/Stickers/Stickers-106.png',
            },
            {
                identifier: 'Stickers-107',
                name: 'Stickers-107',
                thumbnailURI: 'default/Stickers/Stickers-107.png',
                stickerURI: 'default/Stickers/Stickers-107.png',
            },
            {
                identifier: 'Stickers-108',
                name: 'Stickers-108',
                thumbnailURI: 'default/Stickers/Stickers-108.png',
                stickerURI: 'default/Stickers/Stickers-108.png',
            },
            {
                identifier: 'Stickers-109',
                name: 'Stickers-109',
                thumbnailURI: 'default/Stickers/Stickers-109.png',
                stickerURI: 'default/Stickers/Stickers-109.png',
            },
            {
                identifier: 'Stickers-110',
                name: 'Stickers-110',
                thumbnailURI: 'default/Stickers/Stickers-110.png',
                stickerURI: 'default/Stickers/Stickers-110.png',
            },
            {
                identifier: 'Stickers-111',
                name: 'Stickers-111',
                thumbnailURI: 'default/Stickers/Stickers-111.png',
                stickerURI: 'default/Stickers/Stickers-111.png',
            },
            {
                identifier: 'Stickers-112',
                name: 'Stickers-112',
                thumbnailURI: 'default/Stickers/Stickers-112.png',
                stickerURI: 'default/Stickers/Stickers-112.png',
            },
            {
                identifier: 'Stickers-113',
                name: 'Stickers-113',
                thumbnailURI: 'default/Stickers/Stickers-113.png',
                stickerURI: 'default/Stickers/Stickers-113.png',
            },
            {
                identifier: 'Stickers-114',
                name: 'Stickers-114',
                thumbnailURI: 'default/Stickers/Stickers-114.png',
                stickerURI: 'default/Stickers/Stickers-114.png',
            },
            {
                identifier: 'Stickers-115',
                name: 'Stickers-115',
                thumbnailURI: 'default/Stickers/Stickers-115.png',
                stickerURI: 'default/Stickers/Stickers-115.png',
            },
            {
                identifier: 'Stickers-116',
                name: 'Stickers-116',
                thumbnailURI: 'default/Stickers/Stickers-116.png',
                stickerURI: 'default/Stickers/Stickers-116.png',
            },
            {
                identifier: 'Stickers-117',
                name: 'Stickers-117',
                thumbnailURI: 'default/Stickers/Stickers-117.png',
                stickerURI: 'default/Stickers/Stickers-117.png',
            },
            {
                identifier: 'Stickers-118',
                name: 'Stickers-118',
                thumbnailURI: 'default/Stickers/Stickers-118.png',
                stickerURI: 'default/Stickers/Stickers-118.png',
            },
            {
                identifier: 'Stickers-119',
                name: 'Stickers-119',
                thumbnailURI: 'default/Stickers/Stickers-119.png',
                stickerURI: 'default/Stickers/Stickers-119.png',
            },
            {
                identifier: 'Stickers-120',
                name: 'Stickers-120',
                thumbnailURI: 'default/Stickers/Stickers-120.png',
                stickerURI: 'default/Stickers/Stickers-120.png',
            },
            {
                identifier: 'Stickers-121',
                name: 'Stickers-121',
                thumbnailURI: 'default/Stickers/Stickers-121.png',
                stickerURI: 'default/Stickers/Stickers-121.png',
            },
            {
                identifier: 'Stickers-122',
                name: 'Stickers-122',
                thumbnailURI: 'default/Stickers/Stickers-122.png',
                stickerURI: 'default/Stickers/Stickers-122.png',
            },
            {
                identifier: 'Stickers-123',
                name: 'Stickers-123',
                thumbnailURI: 'default/Stickers/Stickers-123.png',
                stickerURI: 'default/Stickers/Stickers-123.png',
            },
            {
                identifier: 'Stickers-124',
                name: 'Stickers-124',
                thumbnailURI: 'default/Stickers/Stickers-124.png',
                stickerURI: 'default/Stickers/Stickers-124.png',
            },
            {
                identifier: 'Stickers-125',
                name: 'Stickers-125',
                thumbnailURI: 'default/Stickers/Stickers-125.png',
                stickerURI: 'default/Stickers/Stickers-125.png',
            },
            {
                identifier: 'Stickers-126',
                name: 'Stickers-126',
                thumbnailURI: 'default/Stickers/Stickers-126.png',
                stickerURI: 'default/Stickers/Stickers-126.png',
            },
            {
                identifier: 'Stickers-127',
                name: 'Stickers-127',
                thumbnailURI: 'default/Stickers/Stickers-127.png',
                stickerURI: 'default/Stickers/Stickers-127.png',
            },
            {
                identifier: 'Stickers-128',
                name: 'Stickers-128',
                thumbnailURI: 'default/Stickers/Stickers-128.png',
                stickerURI: 'default/Stickers/Stickers-128.png',
            },
            {
                identifier: 'Stickers-129',
                name: 'Stickers-129',
                thumbnailURI: 'default/Stickers/Stickers-129.png',
                stickerURI: 'default/Stickers/Stickers-129.png',
            },
            {
                identifier: 'Stickers-130',
                name: 'Stickers-130',
                thumbnailURI: 'default/Stickers/Stickers-130.png',
                stickerURI: 'default/Stickers/Stickers-130.png',
            },
            {
                identifier: 'Stickers-131',
                name: 'Stickers-131',
                thumbnailURI: 'default/Stickers/Stickers-131.png',
                stickerURI: 'default/Stickers/Stickers-131.png',
            },
            {
                identifier: 'Stickers-132',
                name: 'Stickers-132',
                thumbnailURI: 'default/Stickers/Stickers-132.png',
                stickerURI: 'default/Stickers/Stickers-132.png',
            },
            {
                identifier: 'Stickers-133',
                name: 'Stickers-133',
                thumbnailURI: 'default/Stickers/Stickers-133.png',
                stickerURI: 'default/Stickers/Stickers-133.png',
            },
            {
                identifier: 'Stickers-134',
                name: 'Stickers-134',
                thumbnailURI: 'default/Stickers/Stickers-134.png',
                stickerURI: 'default/Stickers/Stickers-134.png',
            },
            {
                identifier: 'Stickers-135',
                name: 'Stickers-135',
                thumbnailURI: 'default/Stickers/Stickers-135.png',
                stickerURI: 'default/Stickers/Stickers-135.png',
            },
            {
                identifier: 'Stickers-136',
                name: 'Stickers-136',
                thumbnailURI: 'default/Stickers/Stickers-136.png',
                stickerURI: 'default/Stickers/Stickers-136.png',
            },
            {
                identifier: 'Stickers-137',
                name: 'Stickers-137',
                thumbnailURI: 'default/Stickers/Stickers-137.png',
                stickerURI: 'default/Stickers/Stickers-137.png',
            },
            {
                identifier: 'Stickers-138',
                name: 'Stickers-138',
                thumbnailURI: 'default/Stickers/Stickers-138.png',
                stickerURI: 'default/Stickers/Stickers-138.png',
            },
            {
                identifier: 'Stickers-139',
                name: 'Stickers-139',
                thumbnailURI: 'default/Stickers/Stickers-139.png',
                stickerURI: 'default/Stickers/Stickers-139.png',
            },
            {
                identifier: 'Stickers-140',
                name: 'Stickers-140',
                thumbnailURI: 'default/Stickers/Stickers-140.png',
                stickerURI: 'default/Stickers/Stickers-140.png',
            },
            {
                identifier: 'Stickers-141',
                name: 'Stickers-141',
                thumbnailURI: 'default/Stickers/Stickers-141.png',
                stickerURI: 'default/Stickers/Stickers-141.png',
            },
            {
                identifier: 'Stickers-142',
                name: 'Stickers-142',
                thumbnailURI: 'default/Stickers/Stickers-142.png',
                stickerURI: 'default/Stickers/Stickers-142.png',
            },
            {
                identifier: 'Stickers-143',
                name: 'Stickers-143',
                thumbnailURI: 'default/Stickers/Stickers-143.png',
                stickerURI: 'default/Stickers/Stickers-143.png',
            },
            {
                identifier: 'Stickers-144',
                name: 'Stickers-144',
                thumbnailURI: 'default/Stickers/Stickers-144.png',
                stickerURI: 'default/Stickers/Stickers-144.png',
            },
            {
                identifier: 'Stickers-145',
                name: 'Stickers-145',
                thumbnailURI: 'default/Stickers/Stickers-145.png',
                stickerURI: 'default/Stickers/Stickers-145.png',
            },
            {
                identifier: 'Stickers-146',
                name: 'Stickers-146',
                thumbnailURI: 'default/Stickers/Stickers-146.png',
                stickerURI: 'default/Stickers/Stickers-146.png',
            },
            {
                identifier: 'Stickers-147',
                name: 'Stickers-147',
                thumbnailURI: 'default/Stickers/Stickers-147.png',
                stickerURI: 'default/Stickers/Stickers-147.png',
            },
            {
                identifier: 'Stickers-148',
                name: 'Stickers-148',
                thumbnailURI: 'default/Stickers/Stickers-148.png',
                stickerURI: 'default/Stickers/Stickers-148.png',
            },
            {
                identifier: 'Stickers-149',
                name: 'Stickers-149',
                thumbnailURI: 'default/Stickers/Stickers-149.png',
                stickerURI: 'default/Stickers/Stickers-149.png',
            },
            {
                identifier: 'Stickers-150',
                name: 'Stickers-150',
                thumbnailURI: 'default/Stickers/Stickers-150.png',
                stickerURI: 'default/Stickers/Stickers-150.png',
            },
            {
                identifier: 'Stickers-151',
                name: 'Stickers-151',
                thumbnailURI: 'default/Stickers/Stickers-151.png',
                stickerURI: 'default/Stickers/Stickers-151.png',
            },
            {
                identifier: 'Stickers-152',
                name: 'Stickers-152',
                thumbnailURI: 'default/Stickers/Stickers-152.png',
                stickerURI: 'default/Stickers/Stickers-152.png',
            },
            {
                identifier: 'Stickers-153',
                name: 'Stickers-153',
                thumbnailURI: 'default/Stickers/Stickers-153.png',
                stickerURI: 'default/Stickers/Stickers-153.png',
            },
            {
                identifier: 'Stickers-154',
                name: 'Stickers-154',
                thumbnailURI: 'default/Stickers/Stickers-154.png',
                stickerURI: 'default/Stickers/Stickers-154.png',
            },
            {
                identifier: 'Stickers-155',
                name: 'Stickers-155',
                thumbnailURI: 'default/Stickers/Stickers-155.png',
                stickerURI: 'default/Stickers/Stickers-155.png',
            },
            {
                identifier: 'Stickers-156',
                name: 'Stickers-156',
                thumbnailURI: 'default/Stickers/Stickers-156.png',
                stickerURI: 'default/Stickers/Stickers-156.png',
            },
            {
                identifier: 'Stickers-157',
                name: 'Stickers-157',
                thumbnailURI: 'default/Stickers/Stickers-157.png',
                stickerURI: 'default/Stickers/Stickers-157.png',
            },
            {
                identifier: 'Stickers-158',
                name: 'Stickers-158',
                thumbnailURI: 'default/Stickers/Stickers-158.png',
                stickerURI: 'default/Stickers/Stickers-158.png',
            },
            {
                identifier: 'Stickers-159',
                name: 'Stickers-159',
                thumbnailURI: 'default/Stickers/Stickers-159.png',
                stickerURI: 'default/Stickers/Stickers-159.png',
            },
            {
                identifier: 'Stickers-160',
                name: 'Stickers-160',
                thumbnailURI: 'default/Stickers/Stickers-160.png',
                stickerURI: 'default/Stickers/Stickers-160.png',
            },
            {
                identifier: 'Stickers-161',
                name: 'Stickers-161',
                thumbnailURI: 'default/Stickers/Stickers-161.png',
                stickerURI: 'default/Stickers/Stickers-161.png',
            },
            {
                identifier: 'Stickers-162',
                name: 'Stickers-162',
                thumbnailURI: 'default/Stickers/Stickers-162.png',
                stickerURI: 'default/Stickers/Stickers-162.png',
            },
            {
                identifier: 'Stickers-163',
                name: 'Stickers-163',
                thumbnailURI: 'default/Stickers/Stickers-163.png',
                stickerURI: 'default/Stickers/Stickers-163.png',
            },
            {
                identifier: 'Stickers-164',
                name: 'Stickers-164',
                thumbnailURI: 'default/Stickers/Stickers-164.png',
                stickerURI: 'default/Stickers/Stickers-164.png',
            },
            {
                identifier: 'Stickers-165',
                name: 'Stickers-165',
                thumbnailURI: 'default/Stickers/Stickers-165.png',
                stickerURI: 'default/Stickers/Stickers-165.png',
            },
            {
                identifier: 'Stickers-166',
                name: 'Stickers-166',
                thumbnailURI: 'default/Stickers/Stickers-166.png',
                stickerURI: 'default/Stickers/Stickers-166.png',
            },
            {
                identifier: 'Stickers-167',
                name: 'Stickers-167',
                thumbnailURI: 'default/Stickers/Stickers-167.png',
                stickerURI: 'default/Stickers/Stickers-167.png',
            },
            {
                identifier: 'Stickers-168',
                name: 'Stickers-168',
                thumbnailURI: 'default/Stickers/Stickers-168.png',
                stickerURI: 'default/Stickers/Stickers-168.png',
            },
            {
                identifier: 'Stickers-169',
                name: 'Stickers-169',
                thumbnailURI: 'default/Stickers/Stickers-169.png',
                stickerURI: 'default/Stickers/Stickers-169.png',
            },
            {
                identifier: 'Stickers-170',
                name: 'Stickers-170',
                thumbnailURI: 'default/Stickers/Stickers-170.png',
                stickerURI: 'default/Stickers/Stickers-170.png',
            },
            {
                identifier: 'Stickers-171',
                name: 'Stickers-171',
                thumbnailURI: 'default/Stickers/Stickers-171.png',
                stickerURI: 'default/Stickers/Stickers-171.png',
            },
            {
                identifier: 'Stickers-172',
                name: 'Stickers-172',
                thumbnailURI: 'default/Stickers/Stickers-172.png',
                stickerURI: 'default/Stickers/Stickers-172.png',
            },
            {
                identifier: 'Stickers-173',
                name: 'Stickers-173',
                thumbnailURI: 'default/Stickers/Stickers-173.png',
                stickerURI: 'default/Stickers/Stickers-173.png',
            },
            {
                identifier: 'Stickers-174',
                name: 'Stickers-174',
                thumbnailURI: 'default/Stickers/Stickers-174.png',
                stickerURI: 'default/Stickers/Stickers-174.png',
            },
            {
                identifier: 'Stickers-175',
                name: 'Stickers-175',
                thumbnailURI: 'default/Stickers/Stickers-175.png',
                stickerURI: 'default/Stickers/Stickers-175.png',
            },
            {
                identifier: 'Stickers-176',
                name: 'Stickers-176',
                thumbnailURI: 'default/Stickers/Stickers-176.png',
                stickerURI: 'default/Stickers/Stickers-176.png',
            },
            {
                identifier: 'Stickers-177',
                name: 'Stickers-177',
                thumbnailURI: 'default/Stickers/Stickers-177.png',
                stickerURI: 'default/Stickers/Stickers-177.png',
            },
            {
                identifier: 'Stickers-178',
                name: 'Stickers-178',
                thumbnailURI: 'default/Stickers/Stickers-178.png',
                stickerURI: 'default/Stickers/Stickers-178.png',
            },
            {
                identifier: 'Stickers-179',
                name: 'Stickers-179',
                thumbnailURI: 'default/Stickers/Stickers-179.png',
                stickerURI: 'default/Stickers/Stickers-179.png',
            },
            {
                identifier: 'Stickers-180',
                name: 'Stickers-180',
                thumbnailURI: 'default/Stickers/Stickers-180.png',
                stickerURI: 'default/Stickers/Stickers-180.png',
            },
            {
                identifier: 'Stickers-181',
                name: 'Stickers-181',
                thumbnailURI: 'default/Stickers/Stickers-181.png',
                stickerURI: 'default/Stickers/Stickers-181.png',
            },
            {
                identifier: 'Stickers-182',
                name: 'Stickers-182',
                thumbnailURI: 'default/Stickers/Stickers-182.png',
                stickerURI: 'default/Stickers/Stickers-182.png',
            },
            {
                identifier: 'Stickers-183',
                name: 'Stickers-183',
                thumbnailURI: 'default/Stickers/Stickers-183.png',
                stickerURI: 'default/Stickers/Stickers-183.png',
            },
            {
                identifier: 'Stickers-184',
                name: 'Stickers-184',
                thumbnailURI: 'default/Stickers/Stickers-184.png',
                stickerURI: 'default/Stickers/Stickers-184.png',
            },
            {
                identifier: 'Stickers-185',
                name: 'Stickers-185',
                thumbnailURI: 'default/Stickers/Stickers-185.png',
                stickerURI: 'default/Stickers/Stickers-185.png',
            },
            {
                identifier: 'Stickers-186',
                name: 'Stickers-186',
                thumbnailURI: 'default/Stickers/Stickers-186.png',
                stickerURI: 'default/Stickers/Stickers-186.png',
            },
            {
                identifier: 'Stickers-187',
                name: 'Stickers-187',
                thumbnailURI: 'default/Stickers/Stickers-187.png',
                stickerURI: 'default/Stickers/Stickers-187.png',
            },
            {
                identifier: 'Stickers-188',
                name: 'Stickers-188',
                thumbnailURI: 'default/Stickers/Stickers-188.png',
                stickerURI: 'default/Stickers/Stickers-188.png',
            },
            {
                identifier: 'Stickers-189',
                name: 'Stickers-189',
                thumbnailURI: 'default/Stickers/Stickers-189.png',
                stickerURI: 'default/Stickers/Stickers-189.png',
            },
            {
                identifier: 'Stickers-190',
                name: 'Stickers-190',
                thumbnailURI: 'default/Stickers/Stickers-190.png',
                stickerURI: 'default/Stickers/Stickers-190.png',
            },
            {
                identifier: 'Stickers-191',
                name: 'Stickers-191',
                thumbnailURI: 'default/Stickers/Stickers-191.png',
                stickerURI: 'default/Stickers/Stickers-191.png',
            },
            {
                identifier: 'Stickers-192',
                name: 'Stickers-192',
                thumbnailURI: 'default/Stickers/Stickers-192.png',
                stickerURI: 'default/Stickers/Stickers-192.png',
            },
            {
                identifier: 'Stickers-193',
                name: 'Stickers-193',
                thumbnailURI: 'default/Stickers/Stickers-193.png',
                stickerURI: 'default/Stickers/Stickers-193.png',
            },
            {
                identifier: 'Stickers-194',
                name: 'Stickers-194',
                thumbnailURI: 'default/Stickers/Stickers-194.png',
                stickerURI: 'default/Stickers/Stickers-194.png',
            },
            {
                identifier: 'Stickers-195',
                name: 'Stickers-195',
                thumbnailURI: 'default/Stickers/Stickers-195.png',
                stickerURI: 'default/Stickers/Stickers-195.png',
            },
            {
                identifier: 'Stickers-196',
                name: 'Stickers-196',
                thumbnailURI: 'default/Stickers/Stickers-196.png',
                stickerURI: 'default/Stickers/Stickers-196.png',
            },
            {
                identifier: 'Stickers-197',
                name: 'Stickers-197',
                thumbnailURI: 'default/Stickers/Stickers-197.png',
                stickerURI: 'default/Stickers/Stickers-197.png',
            },
            {
                identifier: 'Stickers-198',
                name: 'Stickers-198',
                thumbnailURI: 'default/Stickers/Stickers-198.png',
                stickerURI: 'default/Stickers/Stickers-198.png',
            },
            {
                identifier: 'Stickers-199',
                name: 'Stickers-199',
                thumbnailURI: 'default/Stickers/Stickers-199.png',
                stickerURI: 'default/Stickers/Stickers-199.png',
            },
            {
                identifier: 'Stickers-200',
                name: 'Stickers-200',
                thumbnailURI: 'default/Stickers/Stickers-200.png',
                stickerURI: 'default/Stickers/Stickers-200.png',
            },
            {
                identifier: 'Stickers-201',
                name: 'Stickers-201',
                thumbnailURI: 'default/Stickers/Stickers-201.png',
                stickerURI: 'default/Stickers/Stickers-201.png',
            },
            {
                identifier: 'Stickers-202',
                name: 'Stickers-202',
                thumbnailURI: 'default/Stickers/Stickers-202.png',
                stickerURI: 'default/Stickers/Stickers-202.png',
            },
            {
                identifier: 'Stickers-203',
                name: 'Stickers-203',
                thumbnailURI: 'default/Stickers/Stickers-203.png',
                stickerURI: 'default/Stickers/Stickers-203.png',
            },
            {
                identifier: 'Stickers-204',
                name: 'Stickers-204',
                thumbnailURI: 'default/Stickers/Stickers-204.png',
                stickerURI: 'default/Stickers/Stickers-204.png',
            },
            {
                identifier: 'Stickers-205',
                name: 'Stickers-205',
                thumbnailURI: 'default/Stickers/Stickers-205.png',
                stickerURI: 'default/Stickers/Stickers-205.png',
            },
            {
                identifier: 'Stickers-206',
                name: 'Stickers-206',
                thumbnailURI: 'default/Stickers/Stickers-206.png',
                stickerURI: 'default/Stickers/Stickers-206.png',
            },
            {
                identifier: 'Stickers-207',
                name: 'Stickers-207',
                thumbnailURI: 'default/Stickers/Stickers-207.png',
                stickerURI: 'default/Stickers/Stickers-207.png',
            },
            {
                identifier: 'Stickers-208',
                name: 'Stickers-208',
                thumbnailURI: 'default/Stickers/Stickers-208.png',
                stickerURI: 'default/Stickers/Stickers-208.png',
            },
            {
                identifier: 'Stickers-209',
                name: 'Stickers-209',
                thumbnailURI: 'default/Stickers/Stickers-209.png',
                stickerURI: 'default/Stickers/Stickers-209.png',
            },
            {
                identifier: 'Stickers-210',
                name: 'Stickers-210',
                thumbnailURI: 'default/Stickers/Stickers-210.png',
                stickerURI: 'default/Stickers/Stickers-210.png',
            },
            {
                identifier: 'Stickers-211',
                name: 'Stickers-211',
                thumbnailURI: 'default/Stickers/Stickers-211.png',
                stickerURI: 'default/Stickers/Stickers-211.png',
            },
            {
                identifier: 'Stickers-212',
                name: 'Stickers-212',
                thumbnailURI: 'default/Stickers/Stickers-212.png',
                stickerURI: 'default/Stickers/Stickers-212.png',
            },
            {
                identifier: 'Stickers-213',
                name: 'Stickers-213',
                thumbnailURI: 'default/Stickers/Stickers-213.png',
                stickerURI: 'default/Stickers/Stickers-213.png',
            },
            {
                identifier: 'Stickers-214',
                name: 'Stickers-214',
                thumbnailURI: 'default/Stickers/Stickers-214.png',
                stickerURI: 'default/Stickers/Stickers-214.png',
            },
            {
                identifier: 'Stickers-215',
                name: 'Stickers-215',
                thumbnailURI: 'default/Stickers/Stickers-215.png',
                stickerURI: 'default/Stickers/Stickers-215.png',
            },
            {
                identifier: 'Stickers-216',
                name: 'Stickers-216',
                thumbnailURI: 'default/Stickers/Stickers-216.png',
                stickerURI: 'default/Stickers/Stickers-216.png',
            },
            {
                identifier: 'Stickers-217',
                name: 'Stickers-217',
                thumbnailURI: 'default/Stickers/Stickers-217.png',
                stickerURI: 'default/Stickers/Stickers-217.png',
            },
            {
                identifier: 'LineShapes-001',
                name: 'LineShapes-001',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-001.png',
                stickerURI: 'default/LineShapes/LineShapes-001.png',
            },
            {
                identifier: 'LineShapes-002',
                name: 'LineShapes-002',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-002.png',
                stickerURI: 'default/LineShapes/LineShapes-002.png',
            },
            {
                identifier: 'LineShapes-003',
                name: 'LineShapes-003',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-003.png',
                stickerURI: 'default/LineShapes/LineShapes-003.png',
            },
            {
                identifier: 'LineShapes-004',
                name: 'LineShapes-004',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-004.png',
                stickerURI: 'default/LineShapes/LineShapes-004.png',
            },
            {
                identifier: 'LineShapes-005',
                name: 'LineShapes-005',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-005.png',
                stickerURI: 'default/LineShapes/LineShapes-005.png',
            },
            {
                identifier: 'LineShapes-006',
                name: 'LineShapes-006',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-006.png',
                stickerURI: 'default/LineShapes/LineShapes-006.png',
            },
            {
                identifier: 'LineShapes-007',
                name: 'LineShapes-007',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-007.png',
                stickerURI: 'default/LineShapes/LineShapes-007.png',
            },
            {
                identifier: 'LineShapes-008',
                name: 'LineShapes-008',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-008.png',
                stickerURI: 'default/LineShapes/LineShapes-008.png',
            },
            {
                identifier: 'LineShapes-009',
                name: 'LineShapes-009',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-009.png',
                stickerURI: 'default/LineShapes/LineShapes-009.png',
            },
            {
                identifier: 'LineShapes-010',
                name: 'LineShapes-010',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-010.png',
                stickerURI: 'default/LineShapes/LineShapes-010.png',
            },
            {
                identifier: 'LineShapes-011',
                name: 'LineShapes-011',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-011.png',
                stickerURI: 'default/LineShapes/LineShapes-011.png',
            },
            {
                identifier: 'LineShapes-012',
                name: 'LineShapes-012',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-012.png',
                stickerURI: 'default/LineShapes/LineShapes-012.png',
            },
            {
                identifier: 'LineShapes-013',
                name: 'LineShapes-013',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-013.png',
                stickerURI: 'default/LineShapes/LineShapes-013.png',
            },
            {
                identifier: 'LineShapes-014',
                name: 'LineShapes-014',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-014.png',
                stickerURI: 'default/LineShapes/LineShapes-014.png',
            },
            {
                identifier: 'LineShapes-015',
                name: 'LineShapes-015',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-015.png',
                stickerURI: 'default/LineShapes/LineShapes-015.png',
            },
            {
                identifier: 'LineShapes-016',
                name: 'LineShapes-016',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-016.png',
                stickerURI: 'default/LineShapes/LineShapes-016.png',
            },
            {
                identifier: 'LineShapes-017',
                name: 'LineShapes-017',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-017.png',
                stickerURI: 'default/LineShapes/LineShapes-017.png',
            },
            {
                identifier: 'LineShapes-018',
                name: 'LineShapes-018',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-018.png',
                stickerURI: 'default/LineShapes/LineShapes-018.png',
            },
            {
                identifier: 'LineShapes-019',
                name: 'LineShapes-019',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-019.png',
                stickerURI: 'default/LineShapes/LineShapes-019.png',
            },
            {
                identifier: 'LineShapes-020',
                name: 'LineShapes-020',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-020.png',
                stickerURI: 'default/LineShapes/LineShapes-020.png',
            },
            {
                identifier: 'LineShapes-021',
                name: 'LineShapes-021',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-021.png',
                stickerURI: 'default/LineShapes/LineShapes-021.png',
            },
            {
                identifier: 'LineShapes-022',
                name: 'LineShapes-022',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-022.png',
                stickerURI: 'default/LineShapes/LineShapes-022.png',
            },
            {
                identifier: 'LineShapes-023',
                name: 'LineShapes-023',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-023.png',
                stickerURI: 'default/LineShapes/LineShapes-023.png',
            },
            {
                identifier: 'LineShapes-024',
                name: 'LineShapes-024',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-024.png',
                stickerURI: 'default/LineShapes/LineShapes-024.png',
            },
            {
                identifier: 'LineShapes-025',
                name: 'LineShapes-025',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-025.png',
                stickerURI: 'default/LineShapes/LineShapes-025.png',
            },
            {
                identifier: 'LineShapes-026',
                name: 'LineShapes-026',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-026.png',
                stickerURI: 'default/LineShapes/LineShapes-026.png',
            },
            {
                identifier: 'LineShapes-027',
                name: 'LineShapes-027',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-027.png',
                stickerURI: 'default/LineShapes/LineShapes-027.png',
            },
            {
                identifier: 'LineShapes-028',
                name: 'LineShapes-028',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-028.png',
                stickerURI: 'default/LineShapes/LineShapes-028.png',
            },
            {
                identifier: 'LineShapes-029',
                name: 'LineShapes-029',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-029.png',
                stickerURI: 'default/LineShapes/LineShapes-029.png',
            },
            {
                identifier: 'LineShapes-030',
                name: 'LineShapes-030',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-030.png',
                stickerURI: 'default/LineShapes/LineShapes-030.png',
            },
            {
                identifier: 'LineShapes-031',
                name: 'LineShapes-031',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-031.png',
                stickerURI: 'default/LineShapes/LineShapes-031.png',
            },
            {
                identifier: 'LineShapes-032',
                name: 'LineShapes-032',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-032.png',
                stickerURI: 'default/LineShapes/LineShapes-032.png',
            },
            {
                identifier: 'LineShapes-033',
                name: 'LineShapes-033',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-033.png',
                stickerURI: 'default/LineShapes/LineShapes-033.png',
            },
            {
                identifier: 'LineShapes-034',
                name: 'LineShapes-034',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-034.png',
                stickerURI: 'default/LineShapes/LineShapes-034.png',
            },
            {
                identifier: 'LineShapes-035',
                name: 'LineShapes-035',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-035.png',
                stickerURI: 'default/LineShapes/LineShapes-035.png',
            },
            {
                identifier: 'LineShapes-036',
                name: 'LineShapes-036',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-036.png',
                stickerURI: 'default/LineShapes/LineShapes-036.png',
            },
            {
                identifier: 'LineShapes-037',
                name: 'LineShapes-037',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-037.png',
                stickerURI: 'default/LineShapes/LineShapes-037.png',
            },
            {
                identifier: 'LineShapes-038',
                name: 'LineShapes-038',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-038.png',
                stickerURI: 'default/LineShapes/LineShapes-038.png',
            },
            {
                identifier: 'LineShapes-039',
                name: 'LineShapes-039',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-039.png',
                stickerURI: 'default/LineShapes/LineShapes-039.png',
            },
            {
                identifier: 'LineShapes-040',
                name: 'LineShapes-040',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-040.png',
                stickerURI: 'default/LineShapes/LineShapes-040.png',
            },
            {
                identifier: 'LineShapes-041',
                name: 'LineShapes-041',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-041.png',
                stickerURI: 'default/LineShapes/LineShapes-041.png',
            },
            {
                identifier: 'LineShapes-042',
                name: 'LineShapes-042',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-042.png',
                stickerURI: 'default/LineShapes/LineShapes-042.png',
            },
            {
                identifier: 'LineShapes-043',
                name: 'LineShapes-043',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-043.png',
                stickerURI: 'default/LineShapes/LineShapes-043.png',
            },
            {
                identifier: 'LineShapes-044',
                name: 'LineShapes-044',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-044.png',
                stickerURI: 'default/LineShapes/LineShapes-044.png',
            },
            {
                identifier: 'LineShapes-045',
                name: 'LineShapes-045',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-045.png',
                stickerURI: 'default/LineShapes/LineShapes-045.png',
            },
            {
                identifier: 'LineShapes-046',
                name: 'LineShapes-046',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-046.png',
                stickerURI: 'default/LineShapes/LineShapes-046.png',
            },
            {
                identifier: 'LineShapes-047',
                name: 'LineShapes-047',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-047.png',
                stickerURI: 'default/LineShapes/LineShapes-047.png',
            },
            {
                identifier: 'LineShapes-048',
                name: 'LineShapes-048',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-048.png',
                stickerURI: 'default/LineShapes/LineShapes-048.png',
            },
            {
                identifier: 'LineShapes-049',
                name: 'LineShapes-049',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-049.png',
                stickerURI: 'default/LineShapes/LineShapes-049.png',
            },
            {
                identifier: 'LineShapes-050',
                name: 'LineShapes-050',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-050.png',
                stickerURI: 'default/LineShapes/LineShapes-050.png',
            },
            {
                identifier: 'LineShapes-051',
                name: 'LineShapes-051',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-051.png',
                stickerURI: 'default/LineShapes/LineShapes-051.png',
            },
            {
                identifier: 'LineShapes-052',
                name: 'LineShapes-052',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-052.png',
                stickerURI: 'default/LineShapes/LineShapes-052.png',
            },
            {
                identifier: 'LineShapes-053',
                name: 'LineShapes-053',
                tintMode: 'solid',
                thumbnailURI: 'default/LineShapes/LineShapes-053.png',
                stickerURI: 'default/LineShapes/LineShapes-053.png',
            },
            {
                identifier: 'Symbols-001',
                name: 'Symbols-001',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-001.png',
                stickerURI: 'default/Symbols/Symbols-001.png',
            },
            {
                identifier: 'Symbols-002',
                name: 'Symbols-002',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-002.png',
                stickerURI: 'default/Symbols/Symbols-002.png',
            },
            {
                identifier: 'Symbols-003',
                name: 'Symbols-003',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-003.png',
                stickerURI: 'default/Symbols/Symbols-003.png',
            },
            {
                identifier: 'Symbols-004',
                name: 'Symbols-004',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-004.png',
                stickerURI: 'default/Symbols/Symbols-004.png',
            },
            {
                identifier: 'Symbols-005',
                name: 'Symbols-005',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-005.png',
                stickerURI: 'default/Symbols/Symbols-005.png',
            },
            {
                identifier: 'Symbols-006',
                name: 'Symbols-006',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-006.png',
                stickerURI: 'default/Symbols/Symbols-006.png',
            },
            {
                identifier: 'Symbols-007',
                name: 'Symbols-007',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-007.png',
                stickerURI: 'default/Symbols/Symbols-007.png',
            },
            {
                identifier: 'Symbols-008',
                name: 'Symbols-008',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-008.png',
                stickerURI: 'default/Symbols/Symbols-008.png',
            },
            {
                identifier: 'Symbols-009',
                name: 'Symbols-009',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-009.png',
                stickerURI: 'default/Symbols/Symbols-009.png',
            },
            {
                identifier: 'Symbols-010',
                name: 'Symbols-010',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-010.png',
                stickerURI: 'default/Symbols/Symbols-010.png',
            },
            {
                identifier: 'Symbols-011',
                name: 'Symbols-011',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-011.png',
                stickerURI: 'default/Symbols/Symbols-011.png',
            },
            {
                identifier: 'Symbols-012',
                name: 'Symbols-012',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-012.png',
                stickerURI: 'default/Symbols/Symbols-012.png',
            },
            {
                identifier: 'Symbols-013',
                name: 'Symbols-013',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-013.png',
                stickerURI: 'default/Symbols/Symbols-013.png',
            },
            {
                identifier: 'Symbols-014',
                name: 'Symbols-014',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-014.png',
                stickerURI: 'default/Symbols/Symbols-014.png',
            },
            {
                identifier: 'Symbols-015',
                name: 'Symbols-015',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-015.png',
                stickerURI: 'default/Symbols/Symbols-015.png',
            },
            {
                identifier: 'Symbols-016',
                name: 'Symbols-016',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-016.png',
                stickerURI: 'default/Symbols/Symbols-016.png',
            },
            {
                identifier: 'Symbols-017',
                name: 'Symbols-017',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-017.png',
                stickerURI: 'default/Symbols/Symbols-017.png',
            },
            {
                identifier: 'Symbols-018',
                name: 'Symbols-018',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-018.png',
                stickerURI: 'default/Symbols/Symbols-018.png',
            },
            {
                identifier: 'Symbols-019',
                name: 'Symbols-019',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-019.png',
                stickerURI: 'default/Symbols/Symbols-019.png',
            },
            {
                identifier: 'Symbols-020',
                name: 'Symbols-020',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-020.png',
                stickerURI: 'default/Symbols/Symbols-020.png',
            },
            {
                identifier: 'Symbols-021',
                name: 'Symbols-021',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-021.png',
                stickerURI: 'default/Symbols/Symbols-021.png',
            },
            {
                identifier: 'Symbols-022',
                name: 'Symbols-022',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-022.png',
                stickerURI: 'default/Symbols/Symbols-022.png',
            },
            {
                identifier: 'Symbols-023',
                name: 'Symbols-023',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-023.png',
                stickerURI: 'default/Symbols/Symbols-023.png',
            },
            {
                identifier: 'Symbols-024',
                name: 'Symbols-024',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-024.png',
                stickerURI: 'default/Symbols/Symbols-024.png',
            },
            {
                identifier: 'Symbols-025',
                name: 'Symbols-025',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-025.png',
                stickerURI: 'default/Symbols/Symbols-025.png',
            },
            {
                identifier: 'Symbols-026',
                name: 'Symbols-026',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-026.png',
                stickerURI: 'default/Symbols/Symbols-026.png',
            },
            {
                identifier: 'Symbols-027',
                name: 'Symbols-027',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-027.png',
                stickerURI: 'default/Symbols/Symbols-027.png',
            },
            {
                identifier: 'Symbols-028',
                name: 'Symbols-028',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-028.png',
                stickerURI: 'default/Symbols/Symbols-028.png',
            },
            {
                identifier: 'Symbols-029',
                name: 'Symbols-029',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-029.png',
                stickerURI: 'default/Symbols/Symbols-029.png',
            },
            {
                identifier: 'Symbols-030',
                name: 'Symbols-030',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-030.png',
                stickerURI: 'default/Symbols/Symbols-030.png',
            },
            {
                identifier: 'Symbols-031',
                name: 'Symbols-031',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-031.png',
                stickerURI: 'default/Symbols/Symbols-031.png',
            },
            {
                identifier: 'Symbols-032',
                name: 'Symbols-032',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-032.png',
                stickerURI: 'default/Symbols/Symbols-032.png',
            },
            {
                identifier: 'Symbols-033',
                name: 'Symbols-033',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-033.png',
                stickerURI: 'default/Symbols/Symbols-033.png',
            },
            {
                identifier: 'Symbols-034',
                name: 'Symbols-034',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-034.png',
                stickerURI: 'default/Symbols/Symbols-034.png',
            },
            {
                identifier: 'Symbols-035',
                name: 'Symbols-035',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-035.png',
                stickerURI: 'default/Symbols/Symbols-035.png',
            },
            {
                identifier: 'Symbols-036',
                name: 'Symbols-036',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-036.png',
                stickerURI: 'default/Symbols/Symbols-036.png',
            },
            {
                identifier: 'Symbols-037',
                name: 'Symbols-037',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-037.png',
                stickerURI: 'default/Symbols/Symbols-037.png',
            },
            {
                identifier: 'Symbols-038',
                name: 'Symbols-038',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-038.png',
                stickerURI: 'default/Symbols/Symbols-038.png',
            },
            {
                identifier: 'Symbols-039',
                name: 'Symbols-039',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-039.png',
                stickerURI: 'default/Symbols/Symbols-039.png',
            },
            {
                identifier: 'Symbols-040',
                name: 'Symbols-040',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-040.png',
                stickerURI: 'default/Symbols/Symbols-040.png',
            },
            {
                identifier: 'Symbols-041',
                name: 'Symbols-041',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-041.png',
                stickerURI: 'default/Symbols/Symbols-041.png',
            },
            {
                identifier: 'Symbols-042',
                name: 'Symbols-042',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-042.png',
                stickerURI: 'default/Symbols/Symbols-042.png',
            },
            {
                identifier: 'Symbols-043',
                name: 'Symbols-043',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-043.png',
                stickerURI: 'default/Symbols/Symbols-043.png',
            },
            {
                identifier: 'Symbols-044',
                name: 'Symbols-044',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-044.png',
                stickerURI: 'default/Symbols/Symbols-044.png',
            },
            {
                identifier: 'Symbols-045',
                name: 'Symbols-045',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-045.png',
                stickerURI: 'default/Symbols/Symbols-045.png',
            },
            {
                identifier: 'Symbols-046',
                name: 'Symbols-046',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-046.png',
                stickerURI: 'default/Symbols/Symbols-046.png',
            },
            {
                identifier: 'Symbols-047',
                name: 'Symbols-047',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-047.png',
                stickerURI: 'default/Symbols/Symbols-047.png',
            },
            {
                identifier: 'Symbols-048',
                name: 'Symbols-048',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-048.png',
                stickerURI: 'default/Symbols/Symbols-048.png',
            },
            {
                identifier: 'Symbols-049',
                name: 'Symbols-049',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-049.png',
                stickerURI: 'default/Symbols/Symbols-049.png',
            },
            {
                identifier: 'Symbols-050',
                name: 'Symbols-050',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-050.png',
                stickerURI: 'default/Symbols/Symbols-050.png',
            },
            {
                identifier: 'Symbols-051',
                name: 'Symbols-051',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-051.png',
                stickerURI: 'default/Symbols/Symbols-051.png',
            },
            {
                identifier: 'Symbols-052',
                name: 'Symbols-052',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-052.png',
                stickerURI: 'default/Symbols/Symbols-052.png',
            },
            {
                identifier: 'Symbols-053',
                name: 'Symbols-053',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-053.png',
                stickerURI: 'default/Symbols/Symbols-053.png',
            },
            {
                identifier: 'Symbols-054',
                name: 'Symbols-054',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-054.png',
                stickerURI: 'default/Symbols/Symbols-054.png',
            },
            {
                identifier: 'Symbols-055',
                name: 'Symbols-055',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-055.png',
                stickerURI: 'default/Symbols/Symbols-055.png',
            },
            {
                identifier: 'Symbols-056',
                name: 'Symbols-056',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-056.png',
                stickerURI: 'default/Symbols/Symbols-056.png',
            },
            {
                identifier: 'Symbols-057',
                name: 'Symbols-057',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-057.png',
                stickerURI: 'default/Symbols/Symbols-057.png',
            },
            {
                identifier: 'Symbols-058',
                name: 'Symbols-058',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-058.png',
                stickerURI: 'default/Symbols/Symbols-058.png',
            },
            {
                identifier: 'Symbols-059',
                name: 'Symbols-059',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-059.png',
                stickerURI: 'default/Symbols/Symbols-059.png',
            },
            {
                identifier: 'Symbols-060',
                name: 'Symbols-060',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-060.png',
                stickerURI: 'default/Symbols/Symbols-060.png',
            },
            {
                identifier: 'Symbols-061',
                name: 'Symbols-061',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-061.png',
                stickerURI: 'default/Symbols/Symbols-061.png',
            },
            {
                identifier: 'Symbols-062',
                name: 'Symbols-062',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-062.png',
                stickerURI: 'default/Symbols/Symbols-062.png',
            },
            {
                identifier: 'Symbols-063',
                name: 'Symbols-063',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-063.png',
                stickerURI: 'default/Symbols/Symbols-063.png',
            },
            {
                identifier: 'Symbols-064',
                name: 'Symbols-064',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-064.png',
                stickerURI: 'default/Symbols/Symbols-064.png',
            },
            {
                identifier: 'Symbols-065',
                name: 'Symbols-065',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-065.png',
                stickerURI: 'default/Symbols/Symbols-065.png',
            },
            {
                identifier: 'Symbols-066',
                name: 'Symbols-066',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-066.png',
                stickerURI: 'default/Symbols/Symbols-066.png',
            },
            {
                identifier: 'Symbols-067',
                name: 'Symbols-067',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-067.png',
                stickerURI: 'default/Symbols/Symbols-067.png',
            },
            {
                identifier: 'Symbols-068',
                name: 'Symbols-068',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-068.png',
                stickerURI: 'default/Symbols/Symbols-068.png',
            },
            {
                identifier: 'Symbols-069',
                name: 'Symbols-069',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-069.png',
                stickerURI: 'default/Symbols/Symbols-069.png',
            },
            {
                identifier: 'Symbols-070',
                name: 'Symbols-070',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-070.png',
                stickerURI: 'default/Symbols/Symbols-070.png',
            },
            {
                identifier: 'Symbols-071',
                name: 'Symbols-071',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-071.png',
                stickerURI: 'default/Symbols/Symbols-071.png',
            },
            {
                identifier: 'Symbols-072',
                name: 'Symbols-072',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-072.png',
                stickerURI: 'default/Symbols/Symbols-072.png',
            },
            {
                identifier: 'Symbols-073',
                name: 'Symbols-073',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-073.png',
                stickerURI: 'default/Symbols/Symbols-073.png',
            },
            {
                identifier: 'Symbols-074',
                name: 'Symbols-074',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-074.png',
                stickerURI: 'default/Symbols/Symbols-074.png',
            },
            {
                identifier: 'Symbols-075',
                name: 'Symbols-075',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-075.png',
                stickerURI: 'default/Symbols/Symbols-075.png',
            },
            {
                identifier: 'Symbols-076',
                name: 'Symbols-076',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-076.png',
                stickerURI: 'default/Symbols/Symbols-076.png',
            },
            {
                identifier: 'Symbols-077',
                name: 'Symbols-077',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-077.png',
                stickerURI: 'default/Symbols/Symbols-077.png',
            },
            {
                identifier: 'Symbols-078',
                name: 'Symbols-078',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-078.png',
                stickerURI: 'default/Symbols/Symbols-078.png',
            },
            {
                identifier: 'Symbols-079',
                name: 'Symbols-079',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-079.png',
                stickerURI: 'default/Symbols/Symbols-079.png',
            },
            {
                identifier: 'Symbols-080',
                name: 'Symbols-080',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-080.png',
                stickerURI: 'default/Symbols/Symbols-080.png',
            },
            {
                identifier: 'Symbols-081',
                name: 'Symbols-081',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-081.png',
                stickerURI: 'default/Symbols/Symbols-081.png',
            },
            {
                identifier: 'Symbols-082',
                name: 'Symbols-082',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-082.png',
                stickerURI: 'default/Symbols/Symbols-082.png',
            },
            {
                identifier: 'Symbols-083',
                name: 'Symbols-083',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-083.png',
                stickerURI: 'default/Symbols/Symbols-083.png',
            },
            {
                identifier: 'Symbols-084',
                name: 'Symbols-084',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-084.png',
                stickerURI: 'default/Symbols/Symbols-084.png',
            },
            {
                identifier: 'Symbols-085',
                name: 'Symbols-085',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-085.png',
                stickerURI: 'default/Symbols/Symbols-085.png',
            },
            {
                identifier: 'Symbols-086',
                name: 'Symbols-086',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-086.png',
                stickerURI: 'default/Symbols/Symbols-086.png',
            },
            {
                identifier: 'Symbols-087',
                name: 'Symbols-087',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-087.png',
                stickerURI: 'default/Symbols/Symbols-087.png',
            },
            {
                identifier: 'Symbols-088',
                name: 'Symbols-088',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-088.png',
                stickerURI: 'default/Symbols/Symbols-088.png',
            },
            {
                identifier: 'Symbols-089',
                name: 'Symbols-089',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-089.png',
                stickerURI: 'default/Symbols/Symbols-089.png',
            },
            {
                identifier: 'Symbols-090',
                name: 'Symbols-090',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-090.png',
                stickerURI: 'default/Symbols/Symbols-090.png',
            },
            {
                identifier: 'Symbols-091',
                name: 'Symbols-091',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-091.png',
                stickerURI: 'default/Symbols/Symbols-091.png',
            },
            {
                identifier: 'Symbols-092',
                name: 'Symbols-092',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-092.png',
                stickerURI: 'default/Symbols/Symbols-092.png',
            },
            {
                identifier: 'Symbols-093',
                name: 'Symbols-093',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-093.png',
                stickerURI: 'default/Symbols/Symbols-093.png',
            },
            {
                identifier: 'Symbols-094',
                name: 'Symbols-094',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-094.png',
                stickerURI: 'default/Symbols/Symbols-094.png',
            },
            {
                identifier: 'Symbols-095',
                name: 'Symbols-095',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-095.png',
                stickerURI: 'default/Symbols/Symbols-095.png',
            },
            {
                identifier: 'Symbols-096',
                name: 'Symbols-096',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-096.png',
                stickerURI: 'default/Symbols/Symbols-096.png',
            },
            {
                identifier: 'Symbols-097',
                name: 'Symbols-097',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-097.png',
                stickerURI: 'default/Symbols/Symbols-097.png',
            },
            {
                identifier: 'Symbols-098',
                name: 'Symbols-098',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-098.png',
                stickerURI: 'default/Symbols/Symbols-098.png',
            },
            {
                identifier: 'Symbols-099',
                name: 'Symbols-099',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-099.png',
                stickerURI: 'default/Symbols/Symbols-099.png',
            },
            {
                identifier: 'Symbols-100',
                name: 'Symbols-100',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-100.png',
                stickerURI: 'default/Symbols/Symbols-100.png',
            },
            {
                identifier: 'Symbols-101',
                name: 'Symbols-101',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-101.png',
                stickerURI: 'default/Symbols/Symbols-101.png',
            },
            {
                identifier: 'Symbols-102',
                name: 'Symbols-102',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-102.png',
                stickerURI: 'default/Symbols/Symbols-102.png',
            },
            {
                identifier: 'Symbols-103',
                name: 'Symbols-103',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-103.png',
                stickerURI: 'default/Symbols/Symbols-103.png',
            },
            {
                identifier: 'Symbols-104',
                name: 'Symbols-104',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-104.png',
                stickerURI: 'default/Symbols/Symbols-104.png',
            },
            {
                identifier: 'Symbols-105',
                name: 'Symbols-105',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-105.png',
                stickerURI: 'default/Symbols/Symbols-105.png',
            },
            {
                identifier: 'Symbols-106',
                name: 'Symbols-106',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-106.png',
                stickerURI: 'default/Symbols/Symbols-106.png',
            },
            {
                identifier: 'Symbols-107',
                name: 'Symbols-107',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-107.png',
                stickerURI: 'default/Symbols/Symbols-107.png',
            },
            {
                identifier: 'Symbols-108',
                name: 'Symbols-108',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-108.png',
                stickerURI: 'default/Symbols/Symbols-108.png',
            },
            {
                identifier: 'Symbols-109',
                name: 'Symbols-109',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-109.png',
                stickerURI: 'default/Symbols/Symbols-109.png',
            },
            {
                identifier: 'Symbols-110',
                name: 'Symbols-110',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-110.png',
                stickerURI: 'default/Symbols/Symbols-110.png',
            },
            {
                identifier: 'Symbols-111',
                name: 'Symbols-111',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-111.png',
                stickerURI: 'default/Symbols/Symbols-111.png',
            },
            {
                identifier: 'Symbols-112',
                name: 'Symbols-112',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-112.png',
                stickerURI: 'default/Symbols/Symbols-112.png',
            },
            {
                identifier: 'Symbols-113',
                name: 'Symbols-113',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-113.png',
                stickerURI: 'default/Symbols/Symbols-113.png',
            },
            {
                identifier: 'Symbols-114',
                name: 'Symbols-114',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-114.png',
                stickerURI: 'default/Symbols/Symbols-114.png',
            },
            {
                identifier: 'Symbols-115',
                name: 'Symbols-115',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-115.png',
                stickerURI: 'default/Symbols/Symbols-115.png',
            },
            {
                identifier: 'Symbols-116',
                name: 'Symbols-116',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-116.png',
                stickerURI: 'default/Symbols/Symbols-116.png',
            },
            {
                identifier: 'Symbols-117',
                name: 'Symbols-117',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-117.png',
                stickerURI: 'default/Symbols/Symbols-117.png',
            },
            {
                identifier: 'Symbols-118',
                name: 'Symbols-118',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-118.png',
                stickerURI: 'default/Symbols/Symbols-118.png',
            },
            {
                identifier: 'Symbols-119',
                name: 'Symbols-119',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-119.png',
                stickerURI: 'default/Symbols/Symbols-119.png',
            },
            {
                identifier: 'Symbols-120',
                name: 'Symbols-120',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-120.png',
                stickerURI: 'default/Symbols/Symbols-120.png',
            },
            {
                identifier: 'Symbols-121',
                name: 'Symbols-121',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-121.png',
                stickerURI: 'default/Symbols/Symbols-121.png',
            },
            {
                identifier: 'Symbols-122',
                name: 'Symbols-122',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-122.png',
                stickerURI: 'default/Symbols/Symbols-122.png',
            },
            {
                identifier: 'Symbols-123',
                name: 'Symbols-123',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-123.png',
                stickerURI: 'default/Symbols/Symbols-123.png',
            },
            {
                identifier: 'Symbols-124',
                name: 'Symbols-124',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-124.png',
                stickerURI: 'default/Symbols/Symbols-124.png',
            },
            {
                identifier: 'Symbols-125',
                name: 'Symbols-125',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-125.png',
                stickerURI: 'default/Symbols/Symbols-125.png',
            },
            {
                identifier: 'Symbols-126',
                name: 'Symbols-126',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-126.png',
                stickerURI: 'default/Symbols/Symbols-126.png',
            },
            {
                identifier: 'Symbols-127',
                name: 'Symbols-127',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-127.png',
                stickerURI: 'default/Symbols/Symbols-127.png',
            },
            {
                identifier: 'Symbols-128',
                name: 'Symbols-128',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-128.png',
                stickerURI: 'default/Symbols/Symbols-128.png',
            },
            {
                identifier: 'Symbols-129',
                name: 'Symbols-129',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-129.png',
                stickerURI: 'default/Symbols/Symbols-129.png',
            },
            {
                identifier: 'Symbols-130',
                name: 'Symbols-130',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-130.png',
                stickerURI: 'default/Symbols/Symbols-130.png',
            },
            {
                identifier: 'Symbols-131',
                name: 'Symbols-131',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-131.png',
                stickerURI: 'default/Symbols/Symbols-131.png',
            },
            {
                identifier: 'Symbols-132',
                name: 'Symbols-132',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-132.png',
                stickerURI: 'default/Symbols/Symbols-132.png',
            },
            {
                identifier: 'Symbols-133',
                name: 'Symbols-133',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-133.png',
                stickerURI: 'default/Symbols/Symbols-133.png',
            },
            {
                identifier: 'Symbols-134',
                name: 'Symbols-134',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-134.png',
                stickerURI: 'default/Symbols/Symbols-134.png',
            },
            {
                identifier: 'Symbols-135',
                name: 'Symbols-135',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-135.png',
                stickerURI: 'default/Symbols/Symbols-135.png',
            },
            {
                identifier: 'Symbols-136',
                name: 'Symbols-136',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-136.png',
                stickerURI: 'default/Symbols/Symbols-136.png',
            },
            {
                identifier: 'Symbols-137',
                name: 'Symbols-137',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-137.png',
                stickerURI: 'default/Symbols/Symbols-137.png',
            },
            {
                identifier: 'Symbols-138',
                name: 'Symbols-138',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-138.png',
                stickerURI: 'default/Symbols/Symbols-138.png',
            },
            {
                identifier: 'Symbols-139',
                name: 'Symbols-139',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-139.png',
                stickerURI: 'default/Symbols/Symbols-139.png',
            },
            {
                identifier: 'Symbols-140',
                name: 'Symbols-140',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-140.png',
                stickerURI: 'default/Symbols/Symbols-140.png',
            },
            {
                identifier: 'Symbols-141',
                name: 'Symbols-141',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-141.png',
                stickerURI: 'default/Symbols/Symbols-141.png',
            },
            {
                identifier: 'Symbols-142',
                name: 'Symbols-142',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-142.png',
                stickerURI: 'default/Symbols/Symbols-142.png',
            },
            {
                identifier: 'Symbols-143',
                name: 'Symbols-143',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-143.png',
                stickerURI: 'default/Symbols/Symbols-143.png',
            },
            {
                identifier: 'Symbols-144',
                name: 'Symbols-144',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-144.png',
                stickerURI: 'default/Symbols/Symbols-144.png',
            },
            {
                identifier: 'Symbols-145',
                name: 'Symbols-145',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-145.png',
                stickerURI: 'default/Symbols/Symbols-145.png',
            },
            {
                identifier: 'Symbols-146',
                name: 'Symbols-146',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-146.png',
                stickerURI: 'default/Symbols/Symbols-146.png',
            },
            {
                identifier: 'Symbols-147',
                name: 'Symbols-147',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-147.png',
                stickerURI: 'default/Symbols/Symbols-147.png',
            },
            {
                identifier: 'Symbols-148',
                name: 'Symbols-148',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-148.png',
                stickerURI: 'default/Symbols/Symbols-148.png',
            },
            {
                identifier: 'Symbols-149',
                name: 'Symbols-149',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-149.png',
                stickerURI: 'default/Symbols/Symbols-149.png',
            },
            {
                identifier: 'Symbols-150',
                name: 'Symbols-150',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-150.png',
                stickerURI: 'default/Symbols/Symbols-150.png',
            },
            {
                identifier: 'Symbols-151',
                name: 'Symbols-151',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-151.png',
                stickerURI: 'default/Symbols/Symbols-151.png',
            },
            {
                identifier: 'Symbols-152',
                name: 'Symbols-152',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-152.png',
                stickerURI: 'default/Symbols/Symbols-152.png',
            },
            {
                identifier: 'Symbols-153',
                name: 'Symbols-153',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-153.png',
                stickerURI: 'default/Symbols/Symbols-153.png',
            },
            {
                identifier: 'Symbols-154',
                name: 'Symbols-154',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-154.png',
                stickerURI: 'default/Symbols/Symbols-154.png',
            },
            {
                identifier: 'Symbols-155',
                name: 'Symbols-155',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-155.png',
                stickerURI: 'default/Symbols/Symbols-155.png',
            },
            {
                identifier: 'Symbols-156',
                name: 'Symbols-156',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-156.png',
                stickerURI: 'default/Symbols/Symbols-156.png',
            },
            {
                identifier: 'Symbols-157',
                name: 'Symbols-157',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-157.png',
                stickerURI: 'default/Symbols/Symbols-157.png',
            },
            {
                identifier: 'Symbols-158',
                name: 'Symbols-158',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-158.png',
                stickerURI: 'default/Symbols/Symbols-158.png',
            },
            {
                identifier: 'Symbols-159',
                name: 'Symbols-159',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-159.png',
                stickerURI: 'default/Symbols/Symbols-159.png',
            },
            {
                identifier: 'Symbols-160',
                name: 'Symbols-160',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-160.png',
                stickerURI: 'default/Symbols/Symbols-160.png',
            },
            {
                identifier: 'Symbols-161',
                name: 'Symbols-161',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-161.png',
                stickerURI: 'default/Symbols/Symbols-161.png',
            },
            {
                identifier: 'Symbols-162',
                name: 'Symbols-162',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-162.png',
                stickerURI: 'default/Symbols/Symbols-162.png',
            },
            {
                identifier: 'Symbols-163',
                name: 'Symbols-163',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-163.png',
                stickerURI: 'default/Symbols/Symbols-163.png',
            },
            {
                identifier: 'Symbols-164',
                name: 'Symbols-164',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-164.png',
                stickerURI: 'default/Symbols/Symbols-164.png',
            },
            {
                identifier: 'Symbols-165',
                name: 'Symbols-165',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-165.png',
                stickerURI: 'default/Symbols/Symbols-165.png',
            },
            {
                identifier: 'Symbols-166',
                name: 'Symbols-166',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-166.png',
                stickerURI: 'default/Symbols/Symbols-166.png',
            },
            {
                identifier: 'Symbols-167',
                name: 'Symbols-167',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-167.png',
                stickerURI: 'default/Symbols/Symbols-167.png',
            },
            {
                identifier: 'Symbols-168',
                name: 'Symbols-168',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-168.png',
                stickerURI: 'default/Symbols/Symbols-168.png',
            },
            {
                identifier: 'Symbols-169',
                name: 'Symbols-169',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-169.png',
                stickerURI: 'default/Symbols/Symbols-169.png',
            },
            {
                identifier: 'Symbols-170',
                name: 'Symbols-170',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-170.png',
                stickerURI: 'default/Symbols/Symbols-170.png',
            },
            {
                identifier: 'Symbols-171',
                name: 'Symbols-171',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-171.png',
                stickerURI: 'default/Symbols/Symbols-171.png',
            },
            {
                identifier: 'Symbols-172',
                name: 'Symbols-172',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-172.png',
                stickerURI: 'default/Symbols/Symbols-172.png',
            },
            {
                identifier: 'Symbols-173',
                name: 'Symbols-173',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-173.png',
                stickerURI: 'default/Symbols/Symbols-173.png',
            },
            {
                identifier: 'Symbols-174',
                name: 'Symbols-174',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-174.png',
                stickerURI: 'default/Symbols/Symbols-174.png',
            },
            {
                identifier: 'Symbols-175',
                name: 'Symbols-175',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-175.png',
                stickerURI: 'default/Symbols/Symbols-175.png',
            },
            {
                identifier: 'Symbols-176',
                name: 'Symbols-176',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-176.png',
                stickerURI: 'default/Symbols/Symbols-176.png',
            },
            {
                identifier: 'Symbols-177',
                name: 'Symbols-177',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-177.png',
                stickerURI: 'default/Symbols/Symbols-177.png',
            },
            {
                identifier: 'Symbols-178',
                name: 'Symbols-178',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-178.png',
                stickerURI: 'default/Symbols/Symbols-178.png',
            },
            {
                identifier: 'Symbols-179',
                name: 'Symbols-179',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-179.png',
                stickerURI: 'default/Symbols/Symbols-179.png',
            },
            {
                identifier: 'Symbols-180',
                name: 'Symbols-180',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-180.png',
                stickerURI: 'default/Symbols/Symbols-180.png',
            },
            {
                identifier: 'Symbols-181',
                name: 'Symbols-181',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-181.png',
                stickerURI: 'default/Symbols/Symbols-181.png',
            },
            {
                identifier: 'Symbols-182',
                name: 'Symbols-182',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-182.png',
                stickerURI: 'default/Symbols/Symbols-182.png',
            },
            {
                identifier: 'Symbols-183',
                name: 'Symbols-183',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-183.png',
                stickerURI: 'default/Symbols/Symbols-183.png',
            },
            {
                identifier: 'Symbols-184',
                name: 'Symbols-184',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-184.png',
                stickerURI: 'default/Symbols/Symbols-184.png',
            },
            {
                identifier: 'Symbols-185',
                name: 'Symbols-185',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-185.png',
                stickerURI: 'default/Symbols/Symbols-185.png',
            },
            {
                identifier: 'Symbols-186',
                name: 'Symbols-186',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-186.png',
                stickerURI: 'default/Symbols/Symbols-186.png',
            },
            {
                identifier: 'Symbols-187',
                name: 'Symbols-187',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-187.png',
                stickerURI: 'default/Symbols/Symbols-187.png',
            },
            {
                identifier: 'Symbols-188',
                name: 'Symbols-188',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-188.png',
                stickerURI: 'default/Symbols/Symbols-188.png',
            },
            {
                identifier: 'Symbols-189',
                name: 'Symbols-189',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-189.png',
                stickerURI: 'default/Symbols/Symbols-189.png',
            },
            {
                identifier: 'Symbols-190',
                name: 'Symbols-190',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-190.png',
                stickerURI: 'default/Symbols/Symbols-190.png',
            },
            {
                identifier: 'Symbols-191',
                name: 'Symbols-191',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-191.png',
                stickerURI: 'default/Symbols/Symbols-191.png',
            },
            {
                identifier: 'Symbols-192',
                name: 'Symbols-192',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-192.png',
                stickerURI: 'default/Symbols/Symbols-192.png',
            },
            {
                identifier: 'Symbols-193',
                name: 'Symbols-193',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-193.png',
                stickerURI: 'default/Symbols/Symbols-193.png',
            },
            {
                identifier: 'Symbols-194',
                name: 'Symbols-194',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-194.png',
                stickerURI: 'default/Symbols/Symbols-194.png',
            },
            {
                identifier: 'Symbols-195',
                name: 'Symbols-195',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-195.png',
                stickerURI: 'default/Symbols/Symbols-195.png',
            },
            {
                identifier: 'Symbols-196',
                name: 'Symbols-196',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-196.png',
                stickerURI: 'default/Symbols/Symbols-196.png',
            },
            {
                identifier: 'Symbols-197',
                name: 'Symbols-197',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-197.png',
                stickerURI: 'default/Symbols/Symbols-197.png',
            },
            {
                identifier: 'Symbols-198',
                name: 'Symbols-198',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-198.png',
                stickerURI: 'default/Symbols/Symbols-198.png',
            },
            {
                identifier: 'Symbols-199',
                name: 'Symbols-199',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-199.png',
                stickerURI: 'default/Symbols/Symbols-199.png',
            },
            {
                identifier: 'Symbols-200',
                name: 'Symbols-200',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-200.png',
                stickerURI: 'default/Symbols/Symbols-200.png',
            },
            {
                identifier: 'Symbols-201',
                name: 'Symbols-201',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-201.png',
                stickerURI: 'default/Symbols/Symbols-201.png',
            },
            {
                identifier: 'Symbols-202',
                name: 'Symbols-202',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-202.png',
                stickerURI: 'default/Symbols/Symbols-202.png',
            },
            {
                identifier: 'Symbols-203',
                name: 'Symbols-203',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-203.png',
                stickerURI: 'default/Symbols/Symbols-203.png',
            },
            {
                identifier: 'Symbols-204',
                name: 'Symbols-204',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-204.png',
                stickerURI: 'default/Symbols/Symbols-204.png',
            },
            {
                identifier: 'Symbols-205',
                name: 'Symbols-205',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-205.png',
                stickerURI: 'default/Symbols/Symbols-205.png',
            },
            {
                identifier: 'Symbols-206',
                name: 'Symbols-206',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-206.png',
                stickerURI: 'default/Symbols/Symbols-206.png',
            },
            {
                identifier: 'Symbols-207',
                name: 'Symbols-207',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-207.png',
                stickerURI: 'default/Symbols/Symbols-207.png',
            },
            {
                identifier: 'Symbols-208',
                name: 'Symbols-208',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-208.png',
                stickerURI: 'default/Symbols/Symbols-208.png',
            },
            {
                identifier: 'Symbols-209',
                name: 'Symbols-209',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-209.png',
                stickerURI: 'default/Symbols/Symbols-209.png',
            },
            {
                identifier: 'Symbols-210',
                name: 'Symbols-210',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-210.png',
                stickerURI: 'default/Symbols/Symbols-210.png',
            },
            {
                identifier: 'Symbols-211',
                name: 'Symbols-211',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-211.png',
                stickerURI: 'default/Symbols/Symbols-211.png',
            },
            {
                identifier: 'Symbols-212',
                name: 'Symbols-212',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-212.png',
                stickerURI: 'default/Symbols/Symbols-212.png',
            },
            {
                identifier: 'Symbols-213',
                name: 'Symbols-213',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-213.png',
                stickerURI: 'default/Symbols/Symbols-213.png',
            },
            {
                identifier: 'Symbols-214',
                name: 'Symbols-214',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-214.png',
                stickerURI: 'default/Symbols/Symbols-214.png',
            },
            {
                identifier: 'Symbols-215',
                name: 'Symbols-215',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-215.png',
                stickerURI: 'default/Symbols/Symbols-215.png',
            },
            {
                identifier: 'Symbols-216',
                name: 'Symbols-216',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-216.png',
                stickerURI: 'default/Symbols/Symbols-216.png',
            },
            {
                identifier: 'Symbols-217',
                name: 'Symbols-217',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-217.png',
                stickerURI: 'default/Symbols/Symbols-217.png',
            },
            {
                identifier: 'Symbols-218',
                name: 'Symbols-218',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-218.png',
                stickerURI: 'default/Symbols/Symbols-218.png',
            },
            {
                identifier: 'Symbols-219',
                name: 'Symbols-219',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-219.png',
                stickerURI: 'default/Symbols/Symbols-219.png',
            },
            {
                identifier: 'Symbols-220',
                name: 'Symbols-220',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-220.png',
                stickerURI: 'default/Symbols/Symbols-220.png',
            },
            {
                identifier: 'Symbols-221',
                name: 'Symbols-221',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-221.png',
                stickerURI: 'default/Symbols/Symbols-221.png',
            },
            {
                identifier: 'Symbols-222',
                name: 'Symbols-222',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-222.png',
                stickerURI: 'default/Symbols/Symbols-222.png',
            },
            {
                identifier: 'Symbols-223',
                name: 'Symbols-223',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-223.png',
                stickerURI: 'default/Symbols/Symbols-223.png',
            },
            {
                identifier: 'Symbols-224',
                name: 'Symbols-224',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-224.png',
                stickerURI: 'default/Symbols/Symbols-224.png',
            },
            {
                identifier: 'Symbols-225',
                name: 'Symbols-225',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-225.png',
                stickerURI: 'default/Symbols/Symbols-225.png',
            },
            {
                identifier: 'Symbols-226',
                name: 'Symbols-226',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-226.png',
                stickerURI: 'default/Symbols/Symbols-226.png',
            },
            {
                identifier: 'Symbols-227',
                name: 'Symbols-227',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-227.png',
                stickerURI: 'default/Symbols/Symbols-227.png',
            },
            {
                identifier: 'Symbols-228',
                name: 'Symbols-228',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-228.png',
                stickerURI: 'default/Symbols/Symbols-228.png',
            },
            {
                identifier: 'Symbols-229',
                name: 'Symbols-229',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-229.png',
                stickerURI: 'default/Symbols/Symbols-229.png',
            },
            {
                identifier: 'Symbols-230',
                name: 'Symbols-230',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-230.png',
                stickerURI: 'default/Symbols/Symbols-230.png',
            },
            {
                identifier: 'Symbols-231',
                name: 'Symbols-231',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-231.png',
                stickerURI: 'default/Symbols/Symbols-231.png',
            },
            {
                identifier: 'Symbols-232',
                name: 'Symbols-232',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-232.png',
                stickerURI: 'default/Symbols/Symbols-232.png',
            },
            {
                identifier: 'Symbols-233',
                name: 'Symbols-233',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-233.png',
                stickerURI: 'default/Symbols/Symbols-233.png',
            },
            {
                identifier: 'Symbols-234',
                name: 'Symbols-234',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-234.png',
                stickerURI: 'default/Symbols/Symbols-234.png',
            },
            {
                identifier: 'Symbols-235',
                name: 'Symbols-235',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-235.png',
                stickerURI: 'default/Symbols/Symbols-235.png',
            },
            {
                identifier: 'Symbols-236',
                name: 'Symbols-236',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-236.png',
                stickerURI: 'default/Symbols/Symbols-236.png',
            },
            {
                identifier: 'Symbols-237',
                name: 'Symbols-237',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-237.png',
                stickerURI: 'default/Symbols/Symbols-237.png',
            },
            {
                identifier: 'Symbols-238',
                name: 'Symbols-238',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-238.png',
                stickerURI: 'default/Symbols/Symbols-238.png',
            },
            {
                identifier: 'Symbols-239',
                name: 'Symbols-239',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-239.png',
                stickerURI: 'default/Symbols/Symbols-239.png',
            },
            {
                identifier: 'Symbols-240',
                name: 'Symbols-240',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-240.png',
                stickerURI: 'default/Symbols/Symbols-240.png',
            },
            {
                identifier: 'Symbols-241',
                name: 'Symbols-241',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-241.png',
                stickerURI: 'default/Symbols/Symbols-241.png',
            },
            {
                identifier: 'Symbols-242',
                name: 'Symbols-242',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-242.png',
                stickerURI: 'default/Symbols/Symbols-242.png',
            },
            {
                identifier: 'Symbols-243',
                name: 'Symbols-243',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-243.png',
                stickerURI: 'default/Symbols/Symbols-243.png',
            },
            {
                identifier: 'Symbols-244',
                name: 'Symbols-244',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-244.png',
                stickerURI: 'default/Symbols/Symbols-244.png',
            },
            {
                identifier: 'Symbols-245',
                name: 'Symbols-245',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-245.png',
                stickerURI: 'default/Symbols/Symbols-245.png',
            },
            {
                identifier: 'Symbols-246',
                name: 'Symbols-246',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-246.png',
                stickerURI: 'default/Symbols/Symbols-246.png',
            },
            {
                identifier: 'Symbols-247',
                name: 'Symbols-247',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-247.png',
                stickerURI: 'default/Symbols/Symbols-247.png',
            },
            {
                identifier: 'Symbols-248',
                name: 'Symbols-248',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-248.png',
                stickerURI: 'default/Symbols/Symbols-248.png',
            },
            {
                identifier: 'Symbols-249',
                name: 'Symbols-249',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-249.png',
                stickerURI: 'default/Symbols/Symbols-249.png',
            },
            {
                identifier: 'Symbols-250',
                name: 'Symbols-250',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-250.png',
                stickerURI: 'default/Symbols/Symbols-250.png',
            },
            {
                identifier: 'Symbols-251',
                name: 'Symbols-251',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-251.png',
                stickerURI: 'default/Symbols/Symbols-251.png',
            },
            {
                identifier: 'Symbols-252',
                name: 'Symbols-252',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-252.png',
                stickerURI: 'default/Symbols/Symbols-252.png',
            },
            {
                identifier: 'Symbols-253',
                name: 'Symbols-253',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-253.png',
                stickerURI: 'default/Symbols/Symbols-253.png',
            },
            {
                identifier: 'Symbols-254',
                name: 'Symbols-254',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-254.png',
                stickerURI: 'default/Symbols/Symbols-254.png',
            },
            {
                identifier: 'Symbols-255',
                name: 'Symbols-255',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-255.png',
                stickerURI: 'default/Symbols/Symbols-255.png',
            },
            {
                identifier: 'Symbols-256',
                name: 'Symbols-256',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-256.png',
                stickerURI: 'default/Symbols/Symbols-256.png',
            },
            {
                identifier: 'Symbols-257',
                name: 'Symbols-257',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-257.png',
                stickerURI: 'default/Symbols/Symbols-257.png',
            },
            {
                identifier: 'Symbols-258',
                name: 'Symbols-258',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-258.png',
                stickerURI: 'default/Symbols/Symbols-258.png',
            },
            {
                identifier: 'Symbols-259',
                name: 'Symbols-259',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-259.png',
                stickerURI: 'default/Symbols/Symbols-259.png',
            },
            {
                identifier: 'Symbols-260',
                name: 'Symbols-260',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-260.png',
                stickerURI: 'default/Symbols/Symbols-260.png',
            },
            {
                identifier: 'Symbols-261',
                name: 'Symbols-261',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-261.png',
                stickerURI: 'default/Symbols/Symbols-261.png',
            },
            {
                identifier: 'Symbols-262',
                name: 'Symbols-262',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-262.png',
                stickerURI: 'default/Symbols/Symbols-262.png',
            },
            {
                identifier: 'Symbols-263',
                name: 'Symbols-263',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-263.png',
                stickerURI: 'default/Symbols/Symbols-263.png',
            },
            {
                identifier: 'Symbols-264',
                name: 'Symbols-264',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-264.png',
                stickerURI: 'default/Symbols/Symbols-264.png',
            },
            {
                identifier: 'Symbols-265',
                name: 'Symbols-265',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-265.png',
                stickerURI: 'default/Symbols/Symbols-265.png',
            },
            {
                identifier: 'Symbols-266',
                name: 'Symbols-266',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-266.png',
                stickerURI: 'default/Symbols/Symbols-266.png',
            },
            {
                identifier: 'Symbols-267',
                name: 'Symbols-267',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-267.png',
                stickerURI: 'default/Symbols/Symbols-267.png',
            },
            {
                identifier: 'Symbols-268',
                name: 'Symbols-268',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-268.png',
                stickerURI: 'default/Symbols/Symbols-268.png',
            },
            {
                identifier: 'Symbols-269',
                name: 'Symbols-269',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-269.png',
                stickerURI: 'default/Symbols/Symbols-269.png',
            },
            {
                identifier: 'Symbols-270',
                name: 'Symbols-270',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-270.png',
                stickerURI: 'default/Symbols/Symbols-270.png',
            },
            {
                identifier: 'Symbols-271',
                name: 'Symbols-271',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-271.png',
                stickerURI: 'default/Symbols/Symbols-271.png',
            },
            {
                identifier: 'Symbols-272',
                name: 'Symbols-272',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-272.png',
                stickerURI: 'default/Symbols/Symbols-272.png',
            },
            {
                identifier: 'Symbols-273',
                name: 'Symbols-273',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-273.png',
                stickerURI: 'default/Symbols/Symbols-273.png',
            },
            {
                identifier: 'Symbols-274',
                name: 'Symbols-274',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-274.png',
                stickerURI: 'default/Symbols/Symbols-274.png',
            },
            {
                identifier: 'Symbols-275',
                name: 'Symbols-275',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-275.png',
                stickerURI: 'default/Symbols/Symbols-275.png',
            },
            {
                identifier: 'Symbols-276',
                name: 'Symbols-276',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-276.png',
                stickerURI: 'default/Symbols/Symbols-276.png',
            },
            {
                identifier: 'Symbols-277',
                name: 'Symbols-277',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-277.png',
                stickerURI: 'default/Symbols/Symbols-277.png',
            },
            {
                identifier: 'Symbols-278',
                name: 'Symbols-278',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-278.png',
                stickerURI: 'default/Symbols/Symbols-278.png',
            },
            {
                identifier: 'Symbols-279',
                name: 'Symbols-279',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-279.png',
                stickerURI: 'default/Symbols/Symbols-279.png',
            },
            {
                identifier: 'Symbols-280',
                name: 'Symbols-280',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-280.png',
                stickerURI: 'default/Symbols/Symbols-280.png',
            },
            {
                identifier: 'Symbols-281',
                name: 'Symbols-281',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-281.png',
                stickerURI: 'default/Symbols/Symbols-281.png',
            },
            {
                identifier: 'Symbols-282',
                name: 'Symbols-282',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-282.png',
                stickerURI: 'default/Symbols/Symbols-282.png',
            },
            {
                identifier: 'Symbols-283',
                name: 'Symbols-283',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-283.png',
                stickerURI: 'default/Symbols/Symbols-283.png',
            },
            {
                identifier: 'Symbols-284',
                name: 'Symbols-284',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-284.png',
                stickerURI: 'default/Symbols/Symbols-284.png',
            },
            {
                identifier: 'Symbols-285',
                name: 'Symbols-285',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-285.png',
                stickerURI: 'default/Symbols/Symbols-285.png',
            },
            {
                identifier: 'Symbols-286',
                name: 'Symbols-286',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-286.png',
                stickerURI: 'default/Symbols/Symbols-286.png',
            },
            {
                identifier: 'Symbols-287',
                name: 'Symbols-287',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-287.png',
                stickerURI: 'default/Symbols/Symbols-287.png',
            },
            {
                identifier: 'Symbols-288',
                name: 'Symbols-288',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-288.png',
                stickerURI: 'default/Symbols/Symbols-288.png',
            },
            {
                identifier: 'Symbols-289',
                name: 'Symbols-289',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-289.png',
                stickerURI: 'default/Symbols/Symbols-289.png',
            },
            {
                identifier: 'Symbols-290',
                name: 'Symbols-290',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-290.png',
                stickerURI: 'default/Symbols/Symbols-290.png',
            },
            {
                identifier: 'Symbols-291',
                name: 'Symbols-291',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-291.png',
                stickerURI: 'default/Symbols/Symbols-291.png',
            },
            {
                identifier: 'Symbols-292',
                name: 'Symbols-292',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-292.png',
                stickerURI: 'default/Symbols/Symbols-292.png',
            },
            {
                identifier: 'Symbols-293',
                name: 'Symbols-293',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-293.png',
                stickerURI: 'default/Symbols/Symbols-293.png',
            },
            {
                identifier: 'Symbols-294',
                name: 'Symbols-294',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-294.png',
                stickerURI: 'default/Symbols/Symbols-294.png',
            },
            {
                identifier: 'Symbols-295',
                name: 'Symbols-295',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-295.png',
                stickerURI: 'default/Symbols/Symbols-295.png',
            },
            {
                identifier: 'Symbols-296',
                name: 'Symbols-296',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-296.png',
                stickerURI: 'default/Symbols/Symbols-296.png',
            },
            {
                identifier: 'Symbols-297',
                name: 'Symbols-297',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-297.png',
                stickerURI: 'default/Symbols/Symbols-297.png',
            },
            {
                identifier: 'Symbols-298',
                name: 'Symbols-298',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-298.png',
                stickerURI: 'default/Symbols/Symbols-298.png',
            },
            {
                identifier: 'Symbols-299',
                name: 'Symbols-299',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-299.png',
                stickerURI: 'default/Symbols/Symbols-299.png',
            },
            {
                identifier: 'Symbols-300',
                name: 'Symbols-300',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-300.png',
                stickerURI: 'default/Symbols/Symbols-300.png',
            },
            {
                identifier: 'Symbols-301',
                name: 'Symbols-301',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-301.png',
                stickerURI: 'default/Symbols/Symbols-301.png',
            },
            {
                identifier: 'Symbols-302',
                name: 'Symbols-302',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-302.png',
                stickerURI: 'default/Symbols/Symbols-302.png',
            },
            {
                identifier: 'Symbols-303',
                name: 'Symbols-303',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-303.png',
                stickerURI: 'default/Symbols/Symbols-303.png',
            },
            {
                identifier: 'Symbols-304',
                name: 'Symbols-304',
                tintMode: 'solid',
                thumbnailURI: 'default/Symbols/Symbols-304.png',
                stickerURI: 'default/Symbols/Symbols-304.png',
            },
            {
                identifier: 'TextDividers-001',
                name: 'TextDividers-001',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-001.png',
                stickerURI: 'default/TextDividers/TextDividers-001.png',
            },
            {
                identifier: 'TextDividers-002',
                name: 'TextDividers-002',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-002.png',
                stickerURI: 'default/TextDividers/TextDividers-002.png',
            },
            {
                identifier: 'TextDividers-003',
                name: 'TextDividers-003',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-003.png',
                stickerURI: 'default/TextDividers/TextDividers-003.png',
            },
            {
                identifier: 'TextDividers-004',
                name: 'TextDividers-004',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-004.png',
                stickerURI: 'default/TextDividers/TextDividers-004.png',
            },
            {
                identifier: 'TextDividers-005',
                name: 'TextDividers-005',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-005.png',
                stickerURI: 'default/TextDividers/TextDividers-005.png',
            },
            {
                identifier: 'TextDividers-006',
                name: 'TextDividers-006',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-006.png',
                stickerURI: 'default/TextDividers/TextDividers-006.png',
            },
            {
                identifier: 'TextDividers-007',
                name: 'TextDividers-007',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-007.png',
                stickerURI: 'default/TextDividers/TextDividers-007.png',
            },
            {
                identifier: 'TextDividers-008',
                name: 'TextDividers-008',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-008.png',
                stickerURI: 'default/TextDividers/TextDividers-008.png',
            },
            {
                identifier: 'TextDividers-009',
                name: 'TextDividers-009',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-009.png',
                stickerURI: 'default/TextDividers/TextDividers-009.png',
            },
            {
                identifier: 'TextDividers-010',
                name: 'TextDividers-010',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-010.png',
                stickerURI: 'default/TextDividers/TextDividers-010.png',
            },
            {
                identifier: 'TextDividers-011',
                name: 'TextDividers-011',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-011.png',
                stickerURI: 'default/TextDividers/TextDividers-011.png',
            },
            {
                identifier: 'TextDividers-012',
                name: 'TextDividers-012',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-012.png',
                stickerURI: 'default/TextDividers/TextDividers-012.png',
            },
            {
                identifier: 'TextDividers-013',
                name: 'TextDividers-013',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-013.png',
                stickerURI: 'default/TextDividers/TextDividers-013.png',
            },
            {
                identifier: 'TextDividers-014',
                name: 'TextDividers-014',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-014.png',
                stickerURI: 'default/TextDividers/TextDividers-014.png',
            },
            {
                identifier: 'TextDividers-015',
                name: 'TextDividers-015',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-015.png',
                stickerURI: 'default/TextDividers/TextDividers-015.png',
            },
            {
                identifier: 'TextDividers-016',
                name: 'TextDividers-016',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-016.png',
                stickerURI: 'default/TextDividers/TextDividers-016.png',
            },
            {
                identifier: 'TextDividers-017',
                name: 'TextDividers-017',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-017.png',
                stickerURI: 'default/TextDividers/TextDividers-017.png',
            },
            {
                identifier: 'TextDividers-018',
                name: 'TextDividers-018',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-018.png',
                stickerURI: 'default/TextDividers/TextDividers-018.png',
            },
            {
                identifier: 'TextDividers-019',
                name: 'TextDividers-019',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-019.png',
                stickerURI: 'default/TextDividers/TextDividers-019.png',
            },
            {
                identifier: 'TextDividers-020',
                name: 'TextDividers-020',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-020.png',
                stickerURI: 'default/TextDividers/TextDividers-020.png',
            },
            {
                identifier: 'TextDividers-021',
                name: 'TextDividers-021',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-021.png',
                stickerURI: 'default/TextDividers/TextDividers-021.png',
            },
            {
                identifier: 'TextDividers-022',
                name: 'TextDividers-022',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-022.png',
                stickerURI: 'default/TextDividers/TextDividers-022.png',
            },
            {
                identifier: 'TextDividers-023',
                name: 'TextDividers-023',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-023.png',
                stickerURI: 'default/TextDividers/TextDividers-023.png',
            },
            {
                identifier: 'TextDividers-024',
                name: 'TextDividers-024',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-024.png',
                stickerURI: 'default/TextDividers/TextDividers-024.png',
            },
            {
                identifier: 'TextDividers-025',
                name: 'TextDividers-025',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-025.png',
                stickerURI: 'default/TextDividers/TextDividers-025.png',
            },
            {
                identifier: 'TextDividers-026',
                name: 'TextDividers-026',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-026.png',
                stickerURI: 'default/TextDividers/TextDividers-026.png',
            },
            {
                identifier: 'TextDividers-027',
                name: 'TextDividers-027',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-027.png',
                stickerURI: 'default/TextDividers/TextDividers-027.png',
            },
            {
                identifier: 'TextDividers-028',
                name: 'TextDividers-028',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-028.png',
                stickerURI: 'default/TextDividers/TextDividers-028.png',
            },
            {
                identifier: 'TextDividers-029',
                name: 'TextDividers-029',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-029.png',
                stickerURI: 'default/TextDividers/TextDividers-029.png',
            },
            {
                identifier: 'TextDividers-030',
                name: 'TextDividers-030',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-030.png',
                stickerURI: 'default/TextDividers/TextDividers-030.png',
            },
            {
                identifier: 'TextDividers-031',
                name: 'TextDividers-031',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-031.png',
                stickerURI: 'default/TextDividers/TextDividers-031.png',
            },
            {
                identifier: 'TextDividers-032',
                name: 'TextDividers-032',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-032.png',
                stickerURI: 'default/TextDividers/TextDividers-032.png',
            },
            {
                identifier: 'TextDividers-033',
                name: 'TextDividers-033',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-033.png',
                stickerURI: 'default/TextDividers/TextDividers-033.png',
            },
            {
                identifier: 'TextDividers-034',
                name: 'TextDividers-034',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-034.png',
                stickerURI: 'default/TextDividers/TextDividers-034.png',
            },
            {
                identifier: 'TextDividers-035',
                name: 'TextDividers-035',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-035.png',
                stickerURI: 'default/TextDividers/TextDividers-035.png',
            },
            {
                identifier: 'TextDividers-036',
                name: 'TextDividers-036',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-036.png',
                stickerURI: 'default/TextDividers/TextDividers-036.png',
            },
            {
                identifier: 'TextDividers-037',
                name: 'TextDividers-037',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-037.png',
                stickerURI: 'default/TextDividers/TextDividers-037.png',
            },
            {
                identifier: 'TextDividers-038',
                name: 'TextDividers-038',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-038.png',
                stickerURI: 'default/TextDividers/TextDividers-038.png',
            },
            {
                identifier: 'TextDividers-039',
                name: 'TextDividers-039',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-039.png',
                stickerURI: 'default/TextDividers/TextDividers-039.png',
            },
            {
                identifier: 'TextDividers-040',
                name: 'TextDividers-040',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-040.png',
                stickerURI: 'default/TextDividers/TextDividers-040.png',
            },
            {
                identifier: 'TextDividers-041',
                name: 'TextDividers-041',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-041.png',
                stickerURI: 'default/TextDividers/TextDividers-041.png',
            },
            {
                identifier: 'TextDividers-042',
                name: 'TextDividers-042',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-042.png',
                stickerURI: 'default/TextDividers/TextDividers-042.png',
            },
            {
                identifier: 'TextDividers-043',
                name: 'TextDividers-043',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-043.png',
                stickerURI: 'default/TextDividers/TextDividers-043.png',
            },
            {
                identifier: 'TextDividers-044',
                name: 'TextDividers-044',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-044.png',
                stickerURI: 'default/TextDividers/TextDividers-044.png',
            },
            {
                identifier: 'TextDividers-045',
                name: 'TextDividers-045',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-045.png',
                stickerURI: 'default/TextDividers/TextDividers-045.png',
            },
            {
                identifier: 'TextDividers-046',
                name: 'TextDividers-046',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-046.png',
                stickerURI: 'default/TextDividers/TextDividers-046.png',
            },
            {
                identifier: 'TextDividers-047',
                name: 'TextDividers-047',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-047.png',
                stickerURI: 'default/TextDividers/TextDividers-047.png',
            },
            {
                identifier: 'TextDividers-048',
                name: 'TextDividers-048',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-048.png',
                stickerURI: 'default/TextDividers/TextDividers-048.png',
            },
            {
                identifier: 'TextDividers-049',
                name: 'TextDividers-049',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-049.png',
                stickerURI: 'default/TextDividers/TextDividers-049.png',
            },
            {
                identifier: 'TextDividers-050',
                name: 'TextDividers-050',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-050.png',
                stickerURI: 'default/TextDividers/TextDividers-050.png',
            },
            {
                identifier: 'TextDividers-051',
                name: 'TextDividers-051',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-051.png',
                stickerURI: 'default/TextDividers/TextDividers-051.png',
            },
            {
                identifier: 'TextDividers-052',
                name: 'TextDividers-052',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-052.png',
                stickerURI: 'default/TextDividers/TextDividers-052.png',
            },
            {
                identifier: 'TextDividers-053',
                name: 'TextDividers-053',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-053.png',
                stickerURI: 'default/TextDividers/TextDividers-053.png',
            },
            {
                identifier: 'TextDividers-054',
                name: 'TextDividers-054',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-054.png',
                stickerURI: 'default/TextDividers/TextDividers-054.png',
            },
            {
                identifier: 'TextDividers-055',
                name: 'TextDividers-055',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-055.png',
                stickerURI: 'default/TextDividers/TextDividers-055.png',
            },
            {
                identifier: 'TextDividers-056',
                name: 'TextDividers-056',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-056.png',
                stickerURI: 'default/TextDividers/TextDividers-056.png',
            },
            {
                identifier: 'TextDividers-057',
                name: 'TextDividers-057',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-057.png',
                stickerURI: 'default/TextDividers/TextDividers-057.png',
            },
            {
                identifier: 'TextDividers-058',
                name: 'TextDividers-058',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-058.png',
                stickerURI: 'default/TextDividers/TextDividers-058.png',
            },
            {
                identifier: 'TextDividers-059',
                name: 'TextDividers-059',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-059.png',
                stickerURI: 'default/TextDividers/TextDividers-059.png',
            },
            {
                identifier: 'TextDividers-060',
                name: 'TextDividers-060',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-060.png',
                stickerURI: 'default/TextDividers/TextDividers-060.png',
            },
            {
                identifier: 'TextDividers-061',
                name: 'TextDividers-061',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-061.png',
                stickerURI: 'default/TextDividers/TextDividers-061.png',
            },
            {
                identifier: 'TextDividers-062',
                name: 'TextDividers-062',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-062.png',
                stickerURI: 'default/TextDividers/TextDividers-062.png',
            },
            {
                identifier: 'TextDividers-063',
                name: 'TextDividers-063',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-063.png',
                stickerURI: 'default/TextDividers/TextDividers-063.png',
            },
            {
                identifier: 'TextDividers-064',
                name: 'TextDividers-064',
                tintMode: 'solid',
                thumbnailURI: 'default/TextDividers/TextDividers-064.png',
                stickerURI: 'default/TextDividers/TextDividers-064.png',
            },
        ],
    },
];
