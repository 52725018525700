import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { authInterface } from './router';

interface Props {
    auth: authInterface;
    children: JSX.Element;
}

export const PrivateRoute = ({ auth, children }: Props) => {
    return auth?.auth ? children : <Navigate to={`/?event_id=${auth?.eventId}`} />;
};
