import Button from 'components/button/button';
import Checkbox from 'components/checkbox/checkbox';
import Modal from 'components/modal/modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    currentTemplate: string;
    postEventToServer: (sessionType: string[]) => void;
}

const SessionSelector: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    const [photo, setPhoto] = useState(true);
    const [gif, setGif] = useState(false);
    const [slomo, setSlomo] = useState(true);
    const [boomerang, setBoomerang] = useState(true);
    const [video, setVideo] = useState(true);
    const [animatedGif, setAnimatedGif] = useState(true);

    const handlePostEventToServer = () => {
        let sessions = [];
        if (props.currentTemplate.includes('Photo')) {
            sessions = [...(photo ? ['photo_overlay'] : []), ...(gif ? ['gif_still_overlay'] : [])];
        } else if (props.currentTemplate.includes('4K')) {
            sessions = [
                ...(slomo ? ['4K_slomo_overlay'] : []),
                ...(boomerang ? ['4K_boomerang_overlay'] : []),
                ...(video ? ['4K_video_overlay'] : []),
                ...(animatedGif ? ['4K_gif_animated_overlay'] : []),
            ];
        } else {
            sessions = [
                ...(slomo ? ['slomo_overlay'] : []),
                ...(boomerang ? ['boomerang_overlay'] : []),
                ...(video ? ['video_overlay'] : []),
                ...(animatedGif ? ['gif_animated_overlay'] : []),
            ];
        }

        props.postEventToServer(sessions);
    };

    return (
        <Modal title={t('TITLE_SESSION_TYPE_SELECTOR')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-session'>
                <div className='container-checkbox'>
                    {props.currentTemplate.includes('Photo') ? (
                        <>
                            <Checkbox checked={photo} label={t('PHOTO')} handleChange={() => setPhoto(!photo)} />
                            <Checkbox checked={gif} label={t('PHOTO_GIF')} handleChange={() => setGif(!gif)} />
                        </>
                    ) : (
                        <>
                            <Checkbox checked={slomo} label={t('SLOMO')} handleChange={() => setSlomo(!slomo)} />
                            <Checkbox
                                checked={boomerang}
                                label={t('BOOMERANG')}
                                handleChange={() => setBoomerang(!boomerang)}
                            />
                            <Checkbox checked={video} label={t('VIDEO')} handleChange={() => setVideo(!video)} />
                            <Checkbox
                                checked={animatedGif}
                                label={t('ANIMATED_GIF')}
                                handleChange={() => setAnimatedGif(!animatedGif)}
                            />
                        </>
                    )}
                </div>

                <div className='container-button'>
                    <Button onClick={handlePostEventToServer} title={t('MENU_ADD_TO_EVENT')} />
                </div>
            </div>
        </Modal>
    );
};

export default SessionSelector;
