import axios from 'axios';

const config = {
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_DESIGNS_TOKEN}`,
    },
};

export const getCategories = async () => {
    const url = `${process.env.REACT_APP_DESIGNS_HOST}/items/Category?fields=*.*`;

    return new Promise<any>((resolve) => {
        axios
            .get(url, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const getTemplates = async (type: 'video' | 'photo') => {
    const searchFiedls = type === 'video' ? '"gruvy","both"' : '"touchpix"';
    const url = `${process.env.REACT_APP_DESIGNS_HOST}/items/template?fields=*.*&limit=1000&filter={"_and":[{"available_app":{"_in":[${searchFiedls}]}}]}`;

    return new Promise<any>((resolve) => {
        axios
            .get(url, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const getResourceById = async (id: string) => {
    const url = `${process.env.REACT_APP_DESIGNS_HOST}/files/${id}`;

    return new Promise<any>((resolve) => {
        axios
            .get(url, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const getPreset = async (url: string) => {
    return new Promise<any>((resolve) => {
        axios
            .get(url, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const updateUseTemplate = async (id: number, uses: number) => {
    const url = `${process.env.REACT_APP_DESIGNS_HOST}/items/template/${id}`;
    const data = {
        uses,
    };

    return new Promise<any>((resolve) => {
        axios
            .patch(url, data, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const updateUseCategory = async (id: number, uses: number) => {
    const url = `${process.env.REACT_APP_DESIGNS_HOST}/items/Category/${id}`;
    const data = {
        uses,
    };

    return new Promise<any>((resolve) => {
        axios
            .patch(url, data, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const postStats = async (sessionType: string[], template: string) => {
    const extractNumber = (str: string) => {
        const match = str.match(/\b(\d+)\b/);
        return match ? parseInt(match[1], 10) : null;
    };

    const url = `${process.env.REACT_APP_DESIGNS_HOST}/items/statistics`;
    const templateNumber = extractNumber(template);
    const videoTypes = [
        'slomo_overlay',
        'boomerang_overlay',
        'video_overlay',
        'gif_animated_overlay',
        '4K_slomo_overlay',
        '4K_boomerang_overlay',
        '4K_video_overlay',
        '4K_gif_animated_overlay',
    ];
    const photoTypes = ['photo_overlay', 'gif_still_overlay'];
    const type = videoTypes.includes(sessionType[0])
        ? 'Video'
        : photoTypes.includes(sessionType[0])
        ? 'Photo'
        : 'Photo AI';

    const data = {
        video: sessionType.includes('video_overlay') || sessionType.includes('4K_video_overlay'),
        slomo: sessionType.includes('slomo_overlay') || sessionType.includes('4K_slomo_overlay'),
        boomerang: sessionType.includes('boomerang_overlay') || sessionType.includes('4K_boomerang_overlay'),
        animated_gif: sessionType.includes('gif_animated_overlay') || sessionType.includes('4K_gif_animated_overlay'),
        photo: sessionType.includes('photo_overlay'),
        gif_photo_print: sessionType.includes('gif_still_overlay'),
        photo_ai: sessionType.includes('photo_ai_overlay'),
        template: `${templateNumber} ${type}`,
    };

    return new Promise<any>((resolve) => {
        axios
            .post(url, data, config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};
