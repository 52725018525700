import { setIsOpenModalQR } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { SerialisationSchema, EditorApi } from 'photoeditorsdk';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useChangedHistoryHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);
    const overlaysChangedSizeRef = useRef<string[]>([]);
    const backgroundsChangedSizeRef = useRef<string[]>([]);
    const stickersChangedSizeRef = useRef<string[]>([]);

    const clearOverlaysAndBackgroundsRefs = () => {
        overlaysChangedSizeRef.current = [];
        backgroundsChangedSizeRef.current = [];
        stickersChangedSizeRef.current = [];
    };

    const changedHistory = async (ed: EditorApi) => {
        const manageOverlayChanged = (allSprites: any, currentSerialization: SerialisationSchema) => {
            const spritesFlatted = allSprites.map((sp: any) => sp.options.identifier);
            const filtered = overlaysChangedSizeRef.current.filter((ov) => spritesFlatted.includes(ov));
            overlaysChangedSizeRef.current = filtered;

            const overlays = editorContext.stickerCategories
                .find((cat) => cat.identifier === 'OVERLAYS')
                ?.items.map((ov) => ov.identifier);
            if (!overlays) return undefined;

            const overlayToChange = allSprites
                .filter((sp: any) => {
                    return (
                        overlays.includes(sp.options.identifier) &&
                        !overlaysChangedSizeRef.current.includes(sp.options.identifier)
                    );
                })
                .reverse()[0];
            if (!overlayToChange) return undefined;
            overlaysChangedSizeRef.current.push(overlayToChange.options.identifier);

            const imageWidth = currentSerialization.image.width;
            const imageHeight = currentSerialization.image.height;
            const newWidth = imageWidth < imageHeight ? 1 : imageWidth / imageHeight;
            const newHeight = imageWidth < imageHeight ? imageHeight / imageWidth : 1;
            const oldWidth =
                overlayToChange.options.dimensions.x === 0.9999999999999998 ? 1 : overlayToChange.options.dimensions.x;
            const oldHeight =
                overlayToChange.options.dimensions.y === 0.9999999999999998 ? 1 : overlayToChange.options.dimensions.y;

            if (
                overlayToChange.options.position.x === 0.5 &&
                overlayToChange.options.position.y === 0.5 &&
                oldWidth === newWidth &&
                oldHeight === newHeight &&
                overlayToChange.options.rotation === 0
            ) {
                return undefined;
            }

            const overlayModified = {
                ...overlayToChange,
                options: {
                    ...overlayToChange.options,
                    dimensions: { x: newWidth, y: newHeight },
                    position: { x: 0.5, y: 0.5 },
                    rotation: 0,
                },
            };

            const spritesWithoutOverlays = allSprites.filter((sp: any) => {
                return !overlays.includes(sp.options.identifier);
            });
            const spritesWithModified = [...spritesWithoutOverlays, overlayModified];
            const serializationFormatted: any = {
                ...currentSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: spritesWithModified },
                    },
                ],
            };
            return serializationFormatted;
        };

        const manageBackgroundChanged = (allSprites: any, currentSerialization: SerialisationSchema) => {
            const spritesFlatted = allSprites.map((sp: any) => sp.options.identifier);
            const filtered = backgroundsChangedSizeRef.current.filter((bg) => spritesFlatted.includes(bg));
            backgroundsChangedSizeRef.current = filtered;

            const backgrounds = editorContext.stickerCategories
                .find((cat) => cat.identifier === 'BACKGROUNDS')
                ?.items.map((ov) => ov.identifier);
            if (!backgrounds) return undefined;

            const backgroundToChange = allSprites
                .filter((sp: any) => {
                    return (
                        backgrounds.includes(sp.options.identifier) &&
                        !backgroundsChangedSizeRef.current.includes(sp.options.identifier)
                    );
                })
                .reverse()[0];
            if (!backgroundToChange) return undefined;
            backgroundsChangedSizeRef.current.push(backgroundToChange.options.identifier);

            const imageWidth = currentSerialization.image.width;
            const imageHeight = currentSerialization.image.height;
            const newWidth = imageWidth < imageHeight ? 1 : imageWidth / imageHeight;
            const newHeight = imageWidth < imageHeight ? imageHeight / imageWidth : 1;
            const oldWidth =
                backgroundToChange.options.dimensions.x === 0.9999999999999998
                    ? 1
                    : backgroundToChange.options.dimensions.x;
            const oldHeight =
                backgroundToChange.options.dimensions.y === 0.9999999999999998
                    ? 1
                    : backgroundToChange.options.dimensions.y;

            if (
                backgroundToChange.options.position.x === 0.5 &&
                backgroundToChange.options.position.y === 0.5 &&
                oldWidth === newWidth &&
                oldHeight === newHeight &&
                backgroundToChange.options.rotation === 0
            ) {
                return undefined;
            }

            const backgroundModified = {
                ...backgroundToChange,
                options: {
                    ...backgroundToChange.options,
                    dimensions: {
                        x: editorContext.template?.includes('strip') ? newWidth * 2 : newWidth,
                        y: editorContext.template?.includes('horizontal') ? newHeight * 2 : newHeight,
                    },
                    position: { x: 0.5, y: 0.5 },
                    rotation: 0,
                },
            };
            const spritesWithoutBackgrounds = allSprites.filter((sp: any) => {
                return !backgrounds.includes(sp.options.identifier);
            });
            const spritesWithModified = [backgroundModified, ...spritesWithoutBackgrounds];
            const serializationFormatted: any = {
                ...currentSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: spritesWithModified },
                    },
                ],
            };
            return serializationFormatted;
        };

        const manageStickerChanged = (allSprites: any, currentSerialization: SerialisationSchema) => {
            const stickerToChange = allSprites
                .filter((sp: any, index: number) => {
                    const { identifier } = sp.options;
                    return (
                        sp.type === 'sticker' &&
                        index === allSprites.length - 1 &&
                        (identifier.includes('touchpix-qr-file') || identifier.includes('touchpix-qr-gallery')) &&
                        !stickersChangedSizeRef.current.includes(identifier)
                    );
                })
                .reverse()[0];
            if (!stickerToChange) return undefined;

            stickersChangedSizeRef.current = stickerToChange.options.identifier;
            const dimensions = editorContext.template?.includes('strip') ? 0.24 : 0.12;
            const stickerModified = {
                ...stickerToChange,
                options: {
                    ...stickerToChange.options,
                    dimensions: { x: dimensions, y: dimensions },
                },
            };

            const spritesWithoutModified = allSprites.filter((sp: any, index: number) => {
                return index !== allSprites.length - 1;
            });
            const spritesWithModified = [...spritesWithoutModified, stickerModified];
            const serializationFormatted: any = {
                ...currentSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: spritesWithModified },
                    },
                ],
            };
            return serializationFormatted;
        };

        const currentSerialization = await ed
            .serialize({ image: false })
            .then((data) => data)
            .catch((err) => {
                return {
                    version: '',
                    meta: {
                        platform: 'html5',
                        version: '',
                        createdAt: '',
                    },
                    image: {
                        width: 0,
                        height: 0,
                    },
                    operations: [],
                    assetLibrary: { assets: { stickers: [] } },
                } as SerialisationSchema;
            });
        const sprites =
            currentSerialization.operations.length > 0
                ? currentSerialization.operations.find((sp: any) => sp.type === 'sprite')
                : {
                      type: 'sprite',
                      options: { sprites: [] },
                  };
        // @ts-ignore
        const allSprites = sprites.options.sprites;

        const overlaysSerialization = manageOverlayChanged(allSprites, currentSerialization);
        const backgroundsSerialization = manageBackgroundChanged(allSprites, currentSerialization);
        const stickersSerialization = manageStickerChanged(allSprites, currentSerialization);

        if (overlaysSerialization !== undefined) {
            await ed.deserialize(overlaysSerialization);
        } else if (backgroundsSerialization !== undefined) {
            await ed.deserialize(backgroundsSerialization);
        } else if (stickersSerialization !== undefined) {
            await ed.deserialize(stickersSerialization);
            dispatch(setIsOpenModalQR(true));
        }
    };

    return { changedHistory, clearOverlaysAndBackgroundsRefs };
};
