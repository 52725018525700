import './style.scss';
import ErrorUpload from 'components/errorUpload/errorUpload';
import LoaderEditor from 'components/loaderEditor/loaderEditor';
import { useEffect, useState } from 'react';
import TopBar from '../../components/topBar/topBar';
import useWindowSize from 'utils/useWindowSize';
import { configurationDesktop } from './defaults/configurationDesktop';
import { configurationMobile } from './defaults/configurationMobile';
import StickersSidebar from 'components/stickersSidebar/stickersSidebar';
import { useTranslation } from 'react-i18next';
import { getTemplateNameBackground } from 'utils/utils';
import { useInitEditorHook } from 'functions/useInitEditorHook';
import { useDispatch, useSelector } from 'react-redux';
import {
    setConfiguration,
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setIsOpenModalErrorUpload,
    setIsOpenModalQR,
    setLoader,
    setSerializationToLoad,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useLoadPhotoStickersHook } from 'functions/useLoadPhotoStickersHook';
import { useRemovePhotoStickersHook } from 'functions/useRemovePhotoStickersHook';
import TemplatesSidebar from 'components/templatesSidebar/templatesSidebar';
import UploadFinished from 'components/uploadFinished/uploadFinished';
import FourK from 'components/fourK/fourK';
import InfoQR from 'components/infoQR/infoQR';

const PhotoEditor = () => {
    const size = useWindowSize();
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const {
        editor,
        editorLoading,
        template,
        configuration,
        stickerCategories,
        typeEvent,
        serializationToLoad,
        finishedLoadStickers,
        finishedLoadSerialization,
        loader,
        isOpenModalErrorUpload,
        isOpenModalQR,
    } = useSelector((state: RootState) => state.editorReducer);
    const [isOpenStickersSidebar, setIsOpenStickersSidebar] = useState(false);
    const [serializationStickers, setSerializationStickers] = useState<{
        width: number;
        height: number;
        stickers: any[];
    } | null>(null);
    const [textLoader, setTextLoader] = useState<string | undefined>(undefined);

    const { initEditor } = useInitEditorHook();
    const { loadPhotoStickers } = useLoadPhotoStickersHook();
    const { removePhotoStickers } = useRemovePhotoStickersHook();

    useEffect(() => {
        const changeSize = async () => {
            dispatch(
                setConfiguration({
                    ...(size.width < 900 ? configurationMobile : configurationDesktop),
                    image: `library/images/mockup/${getTemplateNameBackground(template, typeEvent?.value)}.png`,
                })
            );

            if (editor) {
                const serialization = await editor.serialize({ image: false }).then((data) => data);

                if (serialization !== undefined) {
                    dispatch(setSerializationToLoad(serialization));
                }
            }

            dispatch(setFinishedLoadStickers(true));
            setTimeout(() => {
                dispatch(setFinishedLoadSerialization(true));
            }, 1000);
        };

        if (
            (configuration.layout === 'advanced' && size.width > 0 && size.width < 900) ||
            (configuration.layout === 'basic' && size.width >= 900)
        ) {
            changeSize();
        }
    }, [size]);

    useEffect(() => {
        if (finishedLoadStickers && finishedLoadSerialization && !editorLoading) {
            initEditor();
        }
    }, [stickerCategories, serializationToLoad, finishedLoadStickers, finishedLoadSerialization]);

    // Change the template selected with the image with faces
    useEffect(() => {
        const loadTemplate = async () => {
            dispatch(setLoader(true));
            if (editor === undefined) {
                return;
            }
            dispatch(
                setConfiguration({
                    ...configuration,
                    image: `library/images/mockup/${getTemplateNameBackground(template, typeEvent?.value)}.png`,
                })
            );

            if (template && !template.includes('Video')) {
                loadPhotoStickers();
            } else if (template && template.includes('Video')) {
                removePhotoStickers();
            }
        };

        if (template === undefined) return;
        loadTemplate();
    }, [template]);

    useEffect(() => {
        if (editor === undefined) return;
        const inSerializationToLoad =
            serializationToLoad !== undefined && serializationToLoad.template?.includes('white') ? true : false;

        if ((template === undefined || template.includes('Video')) && !inSerializationToLoad) {
            if (
                stickerCategories.find((st) => st.identifier === 'CUSTOM TEMPLATES') &&
                stickerCategories.find((st) => st.identifier === 'BACKGROUNDS')
            ) {
                removePhotoStickers();
            }
        }
    }, [editor, template]);

    const handleSidebar = () => {
        if (!isOpenStickersSidebar && !loader) {
            dispatch(setLoader(true));
            setTextLoader(t('SIDEBAR_STICKERS_TEXT_LOADER'));
            getStickersDesign();
            setIsOpenStickersSidebar(true);
        } else {
            dispatch(setLoader(false));
            setTextLoader(undefined);
            setSerializationStickers(null);
            setIsOpenStickersSidebar(false);
        }
    };

    const getStickersDesign = async () => {
        if (editor === undefined) return;
        const serializationStickers = await editor
            .serialize({ image: false })
            .then((data) => data)
            .catch((err) => console.log(err));

        if (serializationStickers) {
            if (serializationStickers.operations.length > 0) {
                const spriteArray = serializationStickers.operations.find((op: any) => op.type === 'sprite');
                // @ts-ignore
                const spritesInTemplate = spriteArray.options.sprites;
                setSerializationStickers({
                    width: serializationStickers.image.width,
                    height: serializationStickers.image.height,
                    stickers: spritesInTemplate,
                });
            } else {
                setSerializationStickers({
                    width: serializationStickers.image.width,
                    height: serializationStickers.image.height,
                    stickers: [],
                });
            }
        }
    };

    const saveStickersFromSidebar = async (newStickers: any[]) => {
        if (editor === undefined) return;
        const serialization = await editor
            .serialize({ image: false })
            .then((data) => data)
            .catch((err) => console.log(err));

        const serializationFormatted: any = {
            ...serialization,
            operations: [
                {
                    type: 'sprite',
                    options: { sprites: newStickers },
                },
            ],
        };

        await editor.deserialize(serializationFormatted);
        handleSidebar();
    };

    return (
        <div className={`container-app ${template?.includes('AI') && 'hide-custom-templates'}`}>
            <UploadFinished />
            <ErrorUpload
                isOpen={isOpenModalErrorUpload}
                closeModal={() => dispatch(setIsOpenModalErrorUpload(false))}
            />
            <InfoQR isOpen={isOpenModalQR} closeModal={() => dispatch(setIsOpenModalQR(false))} />
            <TopBar />
            {template?.includes('4K') && <FourK />}

            <div id='editor' className='photo-editor' />
            {editor && (
                <>
                    <TemplatesSidebar />
                    <StickersSidebar
                        showSidebar={isOpenStickersSidebar}
                        setIsOpenStickersSidebar={handleSidebar}
                        stickers={serializationStickers?.stickers ?? []}
                        size={{ width: serializationStickers?.width ?? 0, height: serializationStickers?.height ?? 0 }}
                        allStickers={stickerCategories}
                        saveStickersFromSidebar={saveStickersFromSidebar}
                    />
                </>
            )}

            {loader && <LoaderEditor text={textLoader} />}
        </div>
    );
};
export default PhotoEditor;
