import './style.scss';

const FourK = () => {
    return (
        <img
            src={`${process.env.PUBLIC_URL}/assets/img/4k.png?version=${process.env.REACT_APP_VERSION}`}
            alt='4k'
            className='fourK'
        />
    );
};

export default FourK;
