import axios from 'axios';
import { ACCESS_TOKEN, REFRESH_TOKEN, REFRESH_TOKEN_EXPIRY_TIME, TOKEN_EXPIRY_TIME } from 'constants/constants';
import Cookie from 'utils/cookie';

const cookie = new Cookie();

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.message === 'Network Error') {
            const refresh_token = cookie.getCookie(REFRESH_TOKEN);

            if (!refresh_token) return Promise.reject(error);
            const host = `${process.env.REACT_APP_TP_HOST}/oauth/token`;
            const grantType = 'refresh_token';
            const client = process.env.REACT_APP_TP_CLIENT ?? '';

            // Build params to send to token endpoint
            const params = new FormData();
            params.append('client_id', client);
            params.append('grant_type', grantType);
            params.append('refresh_token', refresh_token);

            // Make a POST request
            try {
                const response = await fetch(host, {
                    method: 'POST',
                    body: params,
                });
                const { status } = response;
                if (status === 200) {
                    const data = await response.json();
                    cookie.setCookie(ACCESS_TOKEN, data.access_token, TOKEN_EXPIRY_TIME);
                    cookie.setCookie(REFRESH_TOKEN, data.refresh_token, REFRESH_TOKEN_EXPIRY_TIME);

                    originalRequest.headers['Authorization'] = 'Bearer ' + data.ACCESS_TOKEN;
                    return axios(originalRequest);
                } else {
                    cookie.deleteCookie(ACCESS_TOKEN);
                    cookie.deleteCookie(REFRESH_TOKEN);
                    cookie.deleteAllCookies();
                    return Promise.reject(error);
                }
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);
