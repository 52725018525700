import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const ConfirmReturnTP: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal title={t('CONFIRM_RETURN')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div>
                <p>
                    {t('TEXT_EXIT')}
                    <br></br>
                    {t('TEXT_EXIT_2')}
                </p>
            </div>
            <div className='container-buttons'>
                <Button onClick={props.closeModal} title={t('RETURN_EDITOR')} />
                <Button
                    onClick={() => (window.location.href = 'https://touchpix.com/my-account/events/')}
                    title={t('MENU_RETURN_TP')}
                    style='red'
                />
            </div>
        </Modal>
    );
};

export default ConfirmReturnTP;
