import { FC, useEffect, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export interface SizePosition {
    xPosition: string;
    yPosition: string;
    width: string;
    height: string;
}

interface Props {
    sticker: any;
    allStickers: any[];
    size: { height: number; width: number };
    moveUp: (id: number) => void;
    moveDown: (id: number) => void;
    changeSizePosition: (id: number, sizePosition: SizePosition) => void;
    deleteElement: (id: number) => void;
    updateSticker: () => void;
    isFirst: boolean;
    isLast: boolean;
}

const ElementCarouselSticker: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [originalSticker, setOriginalSticker] = useState<any>(undefined);
    const [relation, setRelation] = useState(0);
    const [blockRelation, setBlockRelation] = useState(true);
    const [sizePosition, setSizePosition] = useState<SizePosition>({
        xPosition: '0',
        yPosition: '0',
        width: '0',
        height: '0',
    });

    useEffect(() => {
        const width = props.size.width;
        const height = props.size.height;
        const newRelation = props.sticker.options.dimensions.x / props.sticker.options.dimensions.y;
        const newStickerWidth =
            width < height
                ? Math.round(props.sticker.options.dimensions.x * width)
                : Math.round(props.sticker.options.dimensions.y * height * newRelation);
        const newStickerHeight =
            width < height
                ? Math.round((props.sticker.options.dimensions.x * width) / newRelation)
                : Math.round(props.sticker.options.dimensions.y * height);

        const newStickerXPosition = (width * (props.sticker.options.position.x * 100)) / 100;
        const newStickerYPosition = (height * (props.sticker.options.position.y * 100)) / 100;

        setRelation(newRelation);
        setSizePosition({
            xPosition: `${Math.round(newStickerXPosition - newStickerWidth / 2)}`,
            yPosition: `${Math.round(newStickerYPosition - newStickerHeight / 2)}`,
            width: `${Math.round(newStickerWidth)}`,
            height: `${Math.round(newStickerHeight)}`,
        });

        const collectionsFlatted = props.allStickers.map((collection) => collection.items);
        const stickersFlatted = collectionsFlatted.flat();
        const newOriginalSticker = stickersFlatted.find((st) => {
            return st.identifier === props.sticker.options.identifier;
        });
        setOriginalSticker(newOriginalSticker);
    }, []);

    useEffect(() => {
        if (relation !== 0) {
            const width = props.size.width;
            const height = props.size.height;

            const newStickerWidthBlocked =
                width < height ? +sizePosition.width / width : (+sizePosition.height / height) * relation;
            const newStickerHeightBlocked =
                width < height ? +sizePosition.width / width / relation : +sizePosition.height / height;

            const newStickerWidth = width < height ? +sizePosition.width / width : +sizePosition.width / height;
            const newStickerHeight = width < height ? +sizePosition.height / width : +sizePosition.height / height;

            const newStickerXPosition =
                width < height
                    ? +sizePosition.xPosition / width + newStickerWidthBlocked / 2
                    : (+sizePosition.xPosition + +sizePosition.width / 2) / width;
            const newStickerYPosition =
                width < height
                    ? (+sizePosition.yPosition + +sizePosition.height / 2) / height
                    : +sizePosition.yPosition / height + newStickerHeightBlocked / 2;

            const parsedSizePosition: SizePosition = {
                xPosition: `${newStickerXPosition}`,
                yPosition: `${newStickerYPosition}`,
                width: blockRelation ? `${newStickerWidthBlocked}` : `${newStickerWidth}`,
                height: blockRelation ? `${newStickerHeightBlocked}` : `${newStickerHeight}`,
            };
            props.changeSizePosition(props.sticker.id, parsedSizePosition);
        }
    }, [sizePosition]);

    const getImage = (uri: string) => {
        if (uri.includes('https')) {
            return uri;
        } else {
            return `${process.env.PUBLIC_URL}/assets/photodesigner/sticker/${uri}`;
        }
    };

    return (
        <div className='element-carousel' id={props.sticker.id}>
            <div className='image-container'>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/trash.png?version=${process.env.REACT_APP_VERSION}`}
                    alt='Remove'
                    className='trash'
                    onClick={() => {
                        props.updateSticker();
                        props.deleteElement(props.sticker.id);
                    }}
                />
                <img
                    className='stiker'
                    src={getImage(originalSticker ? originalSticker.thumbnailURI : '')}
                    alt={props.sticker.options.name}
                />
            </div>
            <div className='controls'>
                <div className='positions-container'>
                    <span className='text-explanation'>{t('SIDEBAR_STICKERS_POSITION')}</span>
                    <div className='container-col'>
                        <div className='input-selection'>
                            <p className='input-text'>{t('SIDEBAR_STICKERS_POSITION_X')}</p>
                            <div className='input-container'>
                                <input
                                    value={sizePosition.xPosition}
                                    onChange={(val: any) => {
                                        if (isNaN(val.target.value)) return;
                                        props.updateSticker();
                                        setSizePosition({
                                            ...sizePosition,
                                            xPosition: val.target.value,
                                        });
                                    }}
                                />
                                <span className='px'>px</span>
                            </div>
                        </div>
                        <div className='input-selection'>
                            <p className='input-text'>{t('SIDEBAR_STICKERS_POSITION_Y')}</p>
                            <div className='input-container'>
                                <input
                                    value={sizePosition.yPosition}
                                    onChange={(val: any) => {
                                        if (isNaN(val.target.value)) return;
                                        props.updateSticker();
                                        setSizePosition({
                                            ...sizePosition,
                                            yPosition: val.target.value,
                                        });
                                    }}
                                />
                                <span className='px'>px</span>
                            </div>
                        </div>
                    </div>

                    <div className='size-container'>
                        <span className='text-explanation'>{t('SIDEBAR_STICKERS_SIZE')}</span>
                        {props.sticker.options.identifier.includes('mock') && (
                            <div className='chain-container' onClick={() => setBlockRelation(!blockRelation)}>
                                {blockRelation ? (
                                    <img
                                        className='chain'
                                        src={`${process.env.PUBLIC_URL}/assets/img/chain_on.svg?version=${process.env.REACT_APP_VERSION}`}
                                    />
                                ) : (
                                    <img
                                        className='chain'
                                        src={`${process.env.PUBLIC_URL}/assets/img/chain_off.svg?version=${process.env.REACT_APP_VERSION}`}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className='container-col'>
                        <div className='input-selection'>
                            <p className='input-text'>{t('SIDEBAR_STICKERS_POSITION_WIDTH')}</p>
                            <div className='input-container'>
                                <input
                                    value={sizePosition.width}
                                    onChange={(val: any) => {
                                        if (isNaN(val.target.value)) return;
                                        props.updateSticker();
                                        const newHeight = blockRelation
                                            ? Math.round(+val.target.value / relation)
                                            : +sizePosition.height;
                                        setSizePosition({
                                            ...sizePosition,
                                            width: val.target.value,
                                            height: `${isNaN(newHeight) ? 1 : newHeight}`,
                                        });
                                    }}
                                />
                                <span className='px'>px</span>
                            </div>
                        </div>
                        <div className='input-selection'>
                            <p className='input-text'>{t('SIDEBAR_STICKERS_POSITION_HEIGHT')}</p>
                            <div className='input-container'>
                                <input
                                    value={sizePosition.height}
                                    onChange={(val: any) => {
                                        if (isNaN(val.target.value)) return;
                                        props.updateSticker();
                                        const newWidth = blockRelation
                                            ? Math.round(+val.target.value * relation)
                                            : +sizePosition.width;
                                        setSizePosition({
                                            ...sizePosition,
                                            width: `${isNaN(newWidth) ? 1 : newWidth}`,
                                            height: val.target.value,
                                        });
                                    }}
                                />
                                <span className='px'>px</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buttons-container'>
                    <div
                        className={`button-move ${props.isFirst && 'disabled'}`}
                        onClick={() => {
                            if (props.isFirst) return;
                            props.updateSticker();
                            props.moveUp(props.sticker.id);
                        }}
                    >
                        <img
                            className='arrow'
                            src={`${process.env.PUBLIC_URL}/assets/img/chevron_left.svg?version=${process.env.REACT_APP_VERSION}`}
                        />
                        <span className='text'>{t('SIDEBAR_STICKERS_MOVE_UP')}</span>
                    </div>
                    <div
                        className={`button-move ${props.isLast && 'disabled'}`}
                        onClick={() => {
                            if (props.isLast) return;
                            props.updateSticker();
                            props.moveDown(props.sticker.id);
                        }}
                    >
                        <img
                            className='arrow down'
                            src={`${process.env.PUBLIC_URL}/assets/img/chevron_left.svg?version=${process.env.REACT_APP_VERSION}`}
                        />
                        <span className='text'>{t('SIDEBAR_STICKERS_MOVE_DOWN')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ElementCarouselSticker;
