import axios from 'axios';

export const deleteOverlay = async (filename: string[]) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/v1/overlays/?keys=${filename}`;

    return new Promise<any>((resolve) => {
        axios
            .delete(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};
