import axios from 'axios';

export const getAllUserPresets = async () => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/beta/template-presets/`;

    return new Promise<any>((resolve) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const getTemplatePresetById = async (id: number) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/beta/template-presets/${id}`;

    return new Promise<any>((resolve) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const postTemplatePreset = async (name: string, preview: File, data: string) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/beta/template-presets/`;

    const params = new FormData();
    params.append('name', name);
    params.append('preview', preview, `${name}_${Date.now()}.png`);
    params.append('data', data);

    return new Promise<any>((resolve) => {
        axios
            .post(url, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};

export const deleteTemplatePreset = async (id: number) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/beta/template-presets/${id}`;

    return new Promise<any>((resolve) => {
        axios
            .delete(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};
