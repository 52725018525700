import { setLoader } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { Selector } from 'models/selector';
import { ExportFormat, ImageFormat } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postEvent } from 'services/events/postEvent';
import { postStats } from 'services/templates/templatesServer';
import { getTemplateName, getTemplateNameBackground, isStickerInCategory } from 'utils/utils';

export const usePostEventToServerHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const postEventToServer = async (sessionType: string[], event: Selector | undefined) => {
        if (
            editorContext.editor === undefined ||
            event === undefined ||
            editorContext.typeEvent === undefined ||
            editorContext.template === undefined
        ) {
            return;
        }
        dispatch(setLoader(true));
        postStats(sessionType, editorContext.template);

        const currentSerialization = await editorContext.editor.serialize({ image: false }).then((data) => {
            // @ts-ignore
            data.stickerCategories = editorContext.stickerCategories;
            // @ts-ignore
            data.template = editorContext.template;
            // @ts-ignore
            data.typeEvent = editorContext.typeEvent;
            return data;
        });

        let templateFormatted = editorContext.template;
        if (editorContext.template.includes('white')) {
            templateFormatted = editorContext.template.replace(' white', '');
        }
        await editorContext.editor.setImage(
            `library/images/white/${getTemplateName(templateFormatted, editorContext.typeEvent.value)} white.png`
        );

        const categories = editorContext.stickerCategories;
        const sprites = currentSerialization.operations.find((sp: any) => sp.type === 'sprite');
        // @ts-ignore
        const allSprites = sprites ? sprites.options.sprites : [];

        // Change the mockup sprites with white squares to get the image to display in the website
        const spritesChangedWhite = allSprites.map((sp: any) => {
            if (sp.type === 'text') {
                return sp;
            } else if (
                isStickerInCategory(categories, 'CUSTOM TEMPLATES', sp.options.identifier) ||
                isStickerInCategory(categories, 'AI TEMPLATES', sp.options.identifier)
            ) {
                return {
                    ...sp,
                    options: {
                        ...sp.options,
                        identifier: 'white_square',
                    },
                };
            } else {
                return sp;
            }
        });

        const serializationSpritesChanged: any = {
            ...currentSerialization,
            operations: [
                {
                    type: 'sprite',
                    options: { sprites: spritesChangedWhite },
                },
            ],
        };

        await editorContext.editor.deserialize(serializationSpritesChanged);
        const currentImg = await editorContext.editor
            .export({
                format: ImageFormat.PNG,
                exportType: ExportFormat.BLOB,
                quality: 1.0,
                enableDownload: false,
                preventExportEvent: true,
            })
            .then(async (image) => {
                const file = new File([image as Blob], 'template.png', { type: 'image/png' });
                return file;
            });

        // Leave the editor as the begining
        await editorContext.editor.deserialize(currentSerialization);

        await editorContext.editor.setImage(
            `library/images/transparent/${getTemplateName(templateFormatted, editorContext.typeEvent.value)} trans.png`
        );

        const width = currentSerialization.image.width;
        const height = currentSerialization.image.height;

        const positionQR = allSprites
            .filter((sp: any) => {
                const { identifier } = sp.options;
                return (
                    sp.type === 'sticker' &&
                    (identifier.includes('touchpix-qr-file') || identifier.includes('touchpix-qr-gallery'))
                );
            })
            .map((sp: any) => {
                const { identifier } = sp.options;
                const relation = sp.options.dimensions.x / sp.options.dimensions.y;
                const stickerWidth =
                    width < height
                        ? Math.round(sp.options.dimensions.x * width)
                        : Math.round(sp.options.dimensions.y * height * relation);
                const stickerHeight =
                    width < height
                        ? Math.round((sp.options.dimensions.x * width) / relation)
                        : Math.round(sp.options.dimensions.y * height);
                return {
                    centerX: Math.round(sp.options.position.x * width),
                    centerY: Math.round(sp.options.position.y * height),
                    width: stickerWidth,
                    height: stickerHeight,
                    type: identifier.includes('touchpix-qr-file') ? 'file' : 'gallery',
                };
            });

        // Fill the position of the mockups
        let positioningData = {};
        let photoIds: any[] = [];
        if (sprites) {
            // Get the positioning data
            const mockups = allSprites
                .filter((sp: any) => sp.type !== 'text')
                .filter((sp: any) => {
                    return (
                        isStickerInCategory(categories, 'CUSTOM TEMPLATES', sp.options.identifier) ||
                        isStickerInCategory(categories, 'AI TEMPLATES', sp.options.identifier)
                    );
                })
                .map((sp: any) => {
                    photoIds.push(sp.options.identifier);
                    const relation = sp.options.dimensions.x / sp.options.dimensions.y;
                    const stickerWidth =
                        width < height
                            ? Math.round(sp.options.dimensions.x * width)
                            : Math.round(sp.options.dimensions.y * height * relation);
                    const stickerHeight =
                        width < height
                            ? Math.round((sp.options.dimensions.x * width) / relation)
                            : Math.round(sp.options.dimensions.y * height);
                    return {
                        photoId: sp.options.identifier,
                        centerX: Math.round(sp.options.position.x * width),
                        centerY: Math.round(sp.options.position.y * height),
                        width: stickerWidth,
                        height: stickerHeight,
                        rotation: sp.options.rotation,
                        type: sp.options.identifier.includes('AI') ? 'AI' : 'regular',
                    };
                });
            const uniqueIds = photoIds.filter((item, pos) => photoIds.indexOf(item) === pos);
            const mockupsCorrectId = mockups.map((mock: any) => {
                return {
                    ...mock,
                    photoId: uniqueIds.indexOf(mock.photoId),
                };
            });

            positioningData = {
                photos: mockupsCorrectId,
                width: editorContext.template.includes('strip') ? width * 2 : width,
                stripWidth: width,
                height: editorContext.template.includes('horizontal') ? height * 2 : height,
                stripHeight: height,
                numberOfPhotos: uniqueIds.length,
                isStrip: editorContext.template.includes('strip') || editorContext.template.includes('horizontal'),
                customTemplate: editorContext.template.includes('white'),
                positionQR: positionQR,
                version: 3,
            };

            // Move from here
            // Get the image without mocks
            const spritesWithoutMocks = allSprites.filter((sp: any) => {
                return (
                    sp.type === 'text' ||
                    (sp.type !== 'text' &&
                        !(
                            isStickerInCategory(categories, 'CUSTOM TEMPLATES', sp.options.identifier) ||
                            isStickerInCategory(categories, 'AI TEMPLATES', sp.options.identifier)
                        ))
                );
            });

            const serializationFormatted: any = {
                ...currentSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: spritesWithoutMocks },
                    },
                ],
            };

            await editorContext.editor.deserialize(serializationFormatted);
            // To here
        } else {
            positioningData = {
                photos: [],
                width: 0,
                height: 0,
                numberOfPhotos: 0,
                positionQR: positionQR,
                version: 3,
            };
        }

        // Get the background
        const backgroundStickers = allSprites.filter((sp: any) => {
            return sp.type === 'sticker' && isStickerInCategory(categories, 'BACKGROUNDS', sp.options.identifier);
        });
        const existsBackground = backgroundStickers.length > 0;

        let backgroundImage: File | undefined = undefined;
        if (existsBackground) {
            const serializationFormattedBackground: any = {
                ...currentSerialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: backgroundStickers },
                    },
                ],
            };

            await editorContext.editor.deserialize(serializationFormattedBackground);
            // This is the image to upload as a background
            backgroundImage = await editorContext.editor
                .export({
                    format: ImageFormat.PNG,
                    exportType: ExportFormat.BLOB,
                    quality: 1.0,
                    enableDownload: false,
                    preventExportEvent: true,
                    transparent: true,
                })
                .then(async (image) => {
                    return new File([image as Blob], 'background.png', { type: 'image/png' });
                });
        }

        // Get the overlays without mockups and backgrounds
        const overlayStickers = allSprites
            .filter((sp: any) => {
                return (
                    sp.type === 'text' ||
                    (sp.type !== 'text' &&
                        !isStickerInCategory(categories, 'CUSTOM TEMPLATES', sp.options.identifier) &&
                        !isStickerInCategory(categories, 'AI TEMPLATES', sp.options.identifier) &&
                        !isStickerInCategory(categories, 'BACKGROUNDS', sp.options.identifier))
                );
            })
            .map((sp: any) => {
                const { identifier } = sp.options;
                if (
                    sp.type === 'sprite' &&
                    (identifier.includes('touchpix-qr-file') || identifier.includes('touchpix-qr-gallery'))
                ) {
                    return {
                        ...sp,
                        options: {
                            ...sp.options,
                            identifier: 'white_square',
                        },
                    };
                } else {
                    return sp;
                }
            });
        const serializationFormattedOverlayStickers: any = {
            ...currentSerialization,
            operations: [
                {
                    type: 'sprite',
                    options: { sprites: overlayStickers },
                },
            ],
        };

        await editorContext.editor.deserialize(serializationFormattedOverlayStickers);
        // This is the image to upload as a overlay
        const overlayImage = await editorContext.editor
            .export({
                format: ImageFormat.PNG,
                exportType: ExportFormat.BLOB,
                quality: 1.0,
                enableDownload: false,
                preventExportEvent: true,
                transparent: true,
            })
            .then(async (image) => {
                return new File([image as Blob], 'template.png', { type: 'image/png' });
            });

        const wordsNotAllowed = ['Photo', 'strip', 'horizontal', 'white', 'Video', 'Boomerang', 'Gif', 'Slomo', '4K'];
        const templateId = getTemplateName(editorContext.template, editorContext.typeEvent?.value)
            .split(' ')
            .filter((word: string) => !wordsNotAllowed.includes(word))[0];
        const responses = await Promise.all(
            sessionType.map((type) => {
                return postEvent(
                    event.value,
                    type,
                    templateId,
                    overlayImage,
                    backgroundImage,
                    JSON.stringify(currentSerialization),
                    currentImg,
                    JSON.stringify(positioningData)
                )
                    .then((res) => {
                        if (res.status === 200) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .catch((err) => navigate('/'));
            })
        );

        if (responses.every((r) => r)) {
            const idModal = document.getElementById('upload-finished-modal');
            idModal?.classList.add('show');

            setTimeout(() => {
                idModal?.classList.remove('show');
            }, 3000);
        }

        await editorContext.editor.deserialize(currentSerialization);
        await editorContext.editor.setImage(
            `library/images/mockup/${getTemplateNameBackground(
                editorContext.template,
                editorContext.typeEvent?.value
            )}.png`
        );
        dispatch(setLoader(false));
    };

    return { postEventToServer };
};
