import {
    setConfiguration,
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setSerializationToLoad,
    setTemplate,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateNameBackground } from 'utils/utils';

export const useDoFourKHook = () => {
    const dispatch = useDispatch();
    const { editor, configuration, typeEvent, template } = useSelector((state: RootState) => state.editorReducer);

    const doFourK = async () => {
        const changeStickers = async (template: string) => {
            dispatch(setLoader(true));
            if (editor === undefined) return;
            dispatch(
                setConfiguration({
                    ...configuration,
                    image: `library/images/mockup/${getTemplateNameBackground(template, typeEvent?.value)}.png`,
                })
            );

            const serializationStickers = await editor
                .serialize({ image: false })
                .then((data) => data)
                .catch((err) => console.log(err));

            const serializationSizes = await editor
                .serialize({ image: false })
                .then((data) => data)
                .catch((err) => console.log(err));
            if (serializationStickers === undefined || serializationSizes === undefined) return;

            const serializationFormatted: any = {
                ...serializationStickers,
                image: {
                    width: serializationSizes.image.width,
                    height: serializationSizes.image.height,
                },
            };

            dispatch(setTemplate(template));
            dispatch(setSerializationToLoad(serializationFormatted));
            dispatch(setFinishedLoadSerialization(true));
            setTimeout(() => {
                dispatch(setFinishedLoadStickers(true));
            }, 1000);
        };

        if (template?.includes('4K')) {
            const newTemplate = template.replace(' 4K', '');
            changeStickers(newTemplate);
        } else {
            const newTemplate = `${template} 4K`;
            changeStickers(newTemplate);
        }
    };

    return { doFourK };
};
