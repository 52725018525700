export const selectTheme = (theme: any) => {
    return {
        ...theme,
        borderRadius: 4,
        colors: {
            ...theme.colors,
            primary25: '#8E8FFA',
            primary: '#591fe2',
        },
    };
};
