import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import Checkbox from 'components/checkbox/checkbox';
import { NOT_SHOW_ALERT_4K } from 'constants/constants';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    confirmFourK: () => void;
}

const AlertFourK: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [rememberChoice, setRememberChoice] = useState(false);

    const handleRememberChoice = () => {
        setRememberChoice((prev) => {
            if (prev) {
                localStorage.removeItem(NOT_SHOW_ALERT_4K);
            } else {
                localStorage.setItem(NOT_SHOW_ALERT_4K, 'true');
            }
            return !prev;
        });
    };

    return (
        <Modal title={t('TITLE_MODAL_4K')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-modal-four-k'>
                <p>{t('TEXT_MODAL_4K')}</p>
                <Checkbox checked={rememberChoice} label={t('CHECKBOX_MODAL_4K')} handleChange={handleRememberChoice} />
            </div>
            <div className='container-buttons'>
                <Button onClick={props.closeModal} title={t('GO_BACK')} />
                <Button onClick={props.confirmFourK} title={t('CONFIRM_CHANGE_4K')} style='red' />
            </div>
        </Modal>
    );
};

export default AlertFourK;
