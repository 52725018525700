import { FC } from 'react';
import DisplayPhoto from '../displayPhoto/displayPhoto';
import DisplayVideo from '../displayVideo/displayVideo';
import DisplayAI from '../displayAI/displayAI';
import { TypeEvent } from 'models/typeEvent';
import './style.scss';

const mockupPath = `${process.env.PUBLIC_URL}/assets/photodesigner/library/images/mockup`;

interface Props {
    type: TypeEvent | undefined;
    selectTemplate: (template: string) => void;
    template: string | undefined;
    emptyTemplate: boolean;
    fourK: boolean;
    isStrip: boolean;
    cutHorizontal: boolean;
}

const GalleryTemplates: FC<Props> = (props) => {
    if (props.type === TypeEvent.Photo) {
        return (
            <DisplayPhoto
                type={props.type}
                mockupPath={mockupPath}
                template={props.template}
                isStrip={props.isStrip}
                cutHorizontal={props.cutHorizontal}
                emptyTemplate={props.emptyTemplate}
                selectTemplate={props.selectTemplate}
            />
        );
    } else if (props.type === TypeEvent.AI) {
        return (
            <DisplayAI
                type={props.type}
                mockupPath={mockupPath}
                template={props.template}
                selectTemplate={props.selectTemplate}
            />
        );
    } else if (props.type === TypeEvent.Video) {
        return (
            <DisplayVideo
                mockupPath={mockupPath}
                template={props.template}
                fourK={props.fourK}
                selectTemplate={props.selectTemplate}
            />
        );
    } else {
        return null;
    }
};

export default GalleryTemplates;
