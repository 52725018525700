import {
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setSerializationToLoad,
    setStickerCategories,
    setTemplatesLoaded,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { Template } from 'models/template';
import { useDispatch, useSelector } from 'react-redux';

export const useRemoveTemplateHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    // Remove template of stickers from the list of available stickers
    // First serializate the current template, and remove the stickers included in the template to remove
    // And then we can remove the stickers of the editor
    const removeTemplate = async (template: Template) => {
        dispatch(setLoader(true));
        const serialization = await editorContext.editor?.serialize({ image: false }).then((data) => data);
        if (serialization === undefined) return;
        if (editorContext.template === undefined) return;
        // All templates without the objective to remove
        const filteredTemplates = editorContext.templatesLoaded.filter((t) => {
            return t.id.toString() !== template.id.toString();
        });

        // All stickers without the removed in the target category
        const filteredStickersCategories = editorContext.stickerCategories.filter((t) => {
            return t.identifier.toString() !== template.id.toString();
        });

        if (serialization.operations.length > 0) {
            // @ts-ignore
            const sprites = serialization.operations.find((sp: any) => sp.type === 'sprite').options.sprites;
            const stickersLoaded = filteredStickersCategories
                .map((st: any) => {
                    return st.items.map((item: any) => {
                        return item.identifier;
                    });
                })
                .flat();

            const newSprites = sprites.filter((sp: any) => {
                if (sp.type === 'sticker' && stickersLoaded.includes(sp.options.identifier)) {
                    return sp;
                }
            });

            const serializationFormatted: any = {
                ...serialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: newSprites },
                    },
                ],
            };
            dispatch(setSerializationToLoad(serializationFormatted));
        }

        dispatch(setTemplatesLoaded(filteredTemplates));
        dispatch(setStickerCategories(filteredStickersCategories));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    return { removeTemplate };
};
