import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';

export const useSavePresetLocallyHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const savePresetLocally = async (name: string) => {
        if (editorContext.editor === undefined) return;
        editorContext.editor.serialize({ image: false }).then((data) => {
            // @ts-ignore
            data.stickerCategories = editorContext.stickerCategories;
            // @ts-ignore
            data.template = editorContext.template;
            // @ts-ignore
            data.typeEvent = editorContext.typeEvent;
            const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
            const link = document.createElement('a');
            link.setAttribute('href', dataStr);
            link.setAttribute('download', `${name}.json`);
            link.click();
        });
    };

    return { savePresetLocally };
};
