import Button from 'components/button/button';
import LoadPresetLocally from 'components/loadPresetLocally/loadPresetLocally';
import Logout from 'components/logout/logout';
import { Template } from 'models/template';
import { FC, useEffect, useState } from 'react';
import './style.scss';
import LoadTemplateTP from 'components/loadTemplates/loadTemplate';
import LoadPresetServer from 'components/loadPresetServer/loadPresetServer';
import SavePresetServer from 'components/savePresetServer/savePresetServer';
import UploadOverlays from 'components/uploadOverlay/uploadOverlay';
import ConfirmReturnTP from 'components/confirmReturnTP/confirmReturnTP';
import { useTranslation } from 'react-i18next';
import SessionSelector from 'components/sessionSelector/sessionSelector';
import Select from 'react-select';
import { selectTheme } from 'components/select/selectTheme';
import { selectStyleType, selectStyleTypeFullWidth } from 'components/select/selectStyle';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEvents } from 'services/events/getEvents';
import { Selector } from 'models/selector';
import UploadStickers from 'components/uploadStickers/uploadStickers';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    setEvent,
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setStickerCategories,
} from 'context/actions/editorActions';
import { usePostEventToServerHook } from 'functions/usePostEventToServerHook';
import { useDoFourKHook } from 'functions/useDoFourKHook';
import { useLoadUserOverlaysHook } from 'functions/useLoadUserOverlaysHook';
import { useLoadUserStickersHook } from 'functions/useLoadUserStickersHook';
import { useRemoveTemplateHook } from 'functions/useRemoveTemplateHook';
import { useSelectTemplateHook } from 'functions/useSelectTemplateHook';
import { useSavePresetServerHook } from 'functions/useSavePresetServerHook';
import { useUploadPresetHook } from 'functions/useUploadPresetHook';
import { useSavePresetPreviewHook } from 'functions/useSavePresetPreviewHook';
import { useDeleteUserStickersOverlaysHook } from 'functions/useDeleteUserStickersOverlaysHook';
import UploadBackground from 'components/uploadBackground/uploadBackground';
import { useLoadUserBackgroundsHook } from 'functions/useLoadUserBackgroundsHook';
import Cookie from 'utils/cookie';
import { ACCESS_TOKEN, NOT_SHOW_ALERT_4K, REFRESH_TOKEN } from 'constants/constants';
import AlertFourK from 'components/alertFourK/alertFourK';
import DeleteStickers from 'components/deleteStickers/deleteStickers';
import { TypeEvent } from 'models/typeEvent';

const cookie = new Cookie();

interface Props {}

const TopBar: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const { event, template, typeEvent, templatesLoaded, stickerCategories } = useSelector(
        (state: RootState) => state.editorReducer
    );
    const dispatch = useDispatch();

    const [isOpenSessionSelector, setIsOpenSessionSelector] = useState(false);
    const [isOpenUploadQuickOverlay, setIsOpenUploadQuickOverlay] = useState(false);
    const [isOpenUploadBackground, setIsOpenUploadBackground] = useState(false);
    const [isOpenUploadStickers, setIsOpenUploadStickers] = useState(false);
    const [isOpenLoadPresetLocally, setIsOpenLoadPresetLocally] = useState(false);
    const [isOpenLoadPresetServer, setIsOpenLoadPresetServer] = useState(false);
    const [isOpenSavePresetServer, setIsOpenSavePresetServer] = useState(false);
    const [isOpenLoadStickers, setIsOpenLoadStickers] = useState(false);
    const [isOpenDeleteStickers, setIsOpenDeleteStickers] = useState(false);
    const [isOpenModalReturnTP, setIsOpenModalReturnTP] = useState(false);
    const [isOpenLogout, setIsOpenLogout] = useState(false);
    const [isOpenAlertFourK, setIsOpenAlertFourK] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [listEvents, setListEvents] = useState<Selector[]>([]);

    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
    const [displaySubmenuPresets, setDisplaySubmenuPresets] = useState(false);
    const [displaySubmenuDesign, setDisplaySubmenuDesign] = useState(false);

    const { postEventToServer } = usePostEventToServerHook();
    const { loadUserStickers } = useLoadUserStickersHook();
    const { loadUserOverlays } = useLoadUserOverlaysHook();
    const { loadUserBackgrounds } = useLoadUserBackgroundsHook();
    const { removeTemplate } = useRemoveTemplateHook();
    const { selectTemplate } = useSelectTemplateHook();
    const { uploadPreset } = useUploadPresetHook();
    const { savePresetServer } = useSavePresetServerHook();
    const { savePresetPreview } = useSavePresetPreviewHook();
    const { deleteUserStickersOverlays } = useDeleteUserStickersOverlaysHook();
    const { doFourK } = useDoFourKHook();

    useEffect(() => {
        getEvents()
            .then((res) => {
                if (res.data.length > 0) {
                    const mapped = res.data.map((ev: any) => {
                        return {
                            value: ev.id,
                            label: `${ev.id} - ${ev.title}`,
                        };
                    });
                    const currentEvent = searchParams.get('event_id');
                    if (currentEvent === null || currentEvent === '0') {
                        setSearchParams({ event_id: mapped[0].value });
                        dispatch(setEvent(mapped[0]));
                    } else {
                        const findedEvent = mapped.find((ev: any) => +ev.value === +currentEvent);
                        findedEvent && dispatch(setEvent(findedEvent));
                    }

                    setListEvents(mapped);
                } else if (res.status !== 200) {
                    cookie.deleteCookie(ACCESS_TOKEN);
                    cookie.deleteCookie(REFRESH_TOKEN);
                    cookie.deleteAllCookies();
                    window.location.reload();
                }
            })
            .catch((err) => {
                cookie.deleteCookie(ACCESS_TOKEN);
                cookie.deleteCookie(REFRESH_TOKEN);
                cookie.deleteAllCookies();
                window.location.reload();
            });
    }, []);

    const handleChangeEvent = (selected: any) => {
        setSearchParams({ event_id: selected.value });
        dispatch(setEvent(selected));
    };

    const handleUploadToServer = () => {
        if (typeEvent === undefined) return;
        if (typeEvent.value === TypeEvent.AI) {
            postEventToServer(['photo_ai_overlay'], event);
        } else {
            setIsOpenSessionSelector(true);
        }
    };

    const handleUploadPreset = (file: File) => {
        setIsOpenLoadPresetLocally(false);
        setIsOpenLoadPresetServer(false);
        uploadPreset(file);
    };

    const handleSavePresetServer = (name: string) => {
        setIsOpenSavePresetServer(false);
        savePresetServer(name);
    };

    const handleSelectTemplate = (template: Template) => {
        setIsOpenLoadStickers(false);
        selectTemplate(template);
    };

    const handleRemoveTemplate = (template: Template) => {
        setIsOpenLoadStickers(false);
        removeTemplate(template);
    };

    const handleLoadUserStickers = async () => {
        const response = await loadUserStickers();
        const stickersWithoutNew = stickerCategories.filter((st) => {
            if (st.identifier !== response.identifier) return st;
        });

        const newCategories = [
            ...stickersWithoutNew,
            {
                identifier: response.identifier,
                name: response.identifier,
                thumbnailURI: response.items[0].stickerURI,
                items: response.items,
            },
        ];

        dispatch(setStickerCategories(newCategories));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    const handleLoadUserOverlays = async (overlay: string | undefined) => {
        const response = await loadUserOverlays(overlay);
        const stickersWithoutNew = stickerCategories.filter((st) => {
            if (st.identifier !== response.identifier) return st;
        });

        const newCategories = [
            ...stickersWithoutNew,
            {
                identifier: response.identifier,
                name: response.identifier,
                thumbnailURI: response.items[0].stickerURI,
                items: response.items,
            },
        ];

        dispatch(setStickerCategories(newCategories));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    const handleLoadUserBackgrounds = async (background: string) => {
        const response = await loadUserBackgrounds(background);
        const stickersWithoutNew = stickerCategories.filter((st) => {
            if (st.identifier !== response.identifier) return st;
        });

        const newCategories = [
            ...stickersWithoutNew,
            {
                identifier: response.identifier,
                name: response.identifier,
                thumbnailURI: response.items[0].stickerURI,
                items: response.items,
            },
        ];

        dispatch(setStickerCategories(newCategories));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    const handleDoFourK = () => {
        if (template?.includes('4K')) {
            doFourK();
        } else {
            const cookie4K = localStorage.getItem(NOT_SHOW_ALERT_4K);
            if (!cookie4K) {
                setIsOpenAlertFourK(true);
            } else {
                doFourK();
            }
        }
    };

    return (
        <div className='top-bar'>
            {isOpenSessionSelector && (
                <SessionSelector
                    isOpen={isOpenSessionSelector}
                    closeModal={() => setIsOpenSessionSelector(false)}
                    currentTemplate={template ?? ''}
                    postEventToServer={(sessions) => {
                        setIsOpenSessionSelector(false);
                        postEventToServer(sessions, event);
                    }}
                />
            )}
            {isOpenLoadPresetLocally && (
                <LoadPresetLocally
                    isOpen={isOpenLoadPresetLocally}
                    closeModal={() => setIsOpenLoadPresetLocally(false)}
                    uploadPreset={(file: File) => handleUploadPreset(file)}
                />
            )}
            {isOpenSavePresetServer && (
                <SavePresetServer
                    isOpen={isOpenSavePresetServer}
                    closeModal={() => setIsOpenSavePresetServer(false)}
                    savePresetServer={(name) => handleSavePresetServer(name)}
                />
            )}
            {isOpenLoadPresetServer && (
                <LoadPresetServer
                    isOpen={isOpenLoadPresetServer}
                    closeModal={() => setIsOpenLoadPresetServer(false)}
                    loadPresetLocally={() => {
                        setIsOpenLoadPresetServer(false);
                        setIsOpenLoadPresetLocally(true);
                    }}
                    uploadPreset={(file: File) => handleUploadPreset(file)}
                />
            )}
            {isOpenUploadQuickOverlay && (
                <UploadOverlays
                    isOpen={isOpenUploadQuickOverlay}
                    closeModal={() => setIsOpenUploadQuickOverlay(false)}
                    loadUserOverlays={handleLoadUserOverlays}
                    quickOverlay
                />
            )}
            {isOpenUploadBackground && (
                <UploadBackground
                    isOpen={isOpenUploadBackground}
                    closeModal={() => setIsOpenUploadBackground(false)}
                    loadUserBackgrounds={handleLoadUserBackgrounds}
                />
            )}
            {isOpenUploadStickers && (
                <UploadStickers
                    isOpen={isOpenUploadStickers}
                    closeModal={() => setIsOpenUploadStickers(false)}
                    loadUserStickers={handleLoadUserStickers}
                />
            )}
            {isOpenLoadStickers && (
                <LoadTemplateTP
                    isOpen={isOpenLoadStickers}
                    closeModal={() => setIsOpenLoadStickers(false)}
                    currentTemplate={template}
                    templatesLoaded={templatesLoaded}
                    selectTemplate={(template) => handleSelectTemplate(template)}
                    removeTemplate={(template) => handleRemoveTemplate(template)}
                />
            )}
            {isOpenDeleteStickers && (
                <DeleteStickers
                    isOpen={isOpenDeleteStickers}
                    closeModal={() => setIsOpenDeleteStickers(false)}
                    confirmDelete={() => {
                        deleteUserStickersOverlays();
                        setIsOpenDeleteStickers(false);
                    }}
                />
            )}
            {isOpenAlertFourK && (
                <AlertFourK
                    isOpen={isOpenAlertFourK}
                    closeModal={() => setIsOpenAlertFourK(false)}
                    confirmFourK={() => {
                        setIsOpenAlertFourK(false);
                        doFourK();
                    }}
                />
            )}
            {isOpenModalReturnTP && (
                <ConfirmReturnTP isOpen={isOpenModalReturnTP} closeModal={() => setIsOpenModalReturnTP(false)} />
            )}
            {isOpenLogout && <Logout isOpen={isOpenLogout} closeModal={() => setIsOpenLogout(false)} />}
            <div className='hamburger-menu'>
                <label
                    className={`hamburger ${displayMobileMenu && 'open'}`}
                    onClick={() => setDisplayMobileMenu(!displayMobileMenu)}
                >
                    <div className='top-bun'></div>
                    <div className='meat'></div>
                    <div className='bottom-bun'></div>
                </label>
            </div>

            <div className={`container-buttons-mobile ${displayMobileMenu && 'open'}`}>
                <div className='group'>
                    <Select
                        placeholder={t('SELECT_EVENT')}
                        theme={selectTheme}
                        styles={selectStyleTypeFullWidth}
                        onChange={handleChangeEvent}
                        defaultValue={event}
                        value={event}
                        options={listEvents}
                    />
                    <Button
                        onClick={handleUploadToServer}
                        title={t('MENU_ADD_TO_EVENT')}
                        style='secondary'
                        disabled={event === undefined}
                    />
                </div>
                <div className='group'>
                    <div className='separator'>
                        <p>{t('MENU_SEPARATOR_PRESETS')}</p>
                    </div>
                    <Button onClick={() => setIsOpenSavePresetServer(true)} title={t('MENU_SAVE_PRESET')} />
                    <Button onClick={() => setIsOpenLoadPresetServer(true)} title={t('MENU_LOAD_PRESET')} />
                    <Button onClick={() => savePresetPreview(true)} title={t('MENU_SAVE_PRESET_PREVIEW')} />
                    <Button onClick={() => savePresetPreview(false)} title={t('MENU_DOWNLOAD_OVERLAY')} />
                </div>
                <div className='group'>
                    <div className='separator'>
                        <p>{t('MENU_SEPARATOR_DESIGN')}</p>
                    </div>
                    <Button onClick={() => setIsOpenUploadQuickOverlay(true)} title={t('MENU_QUICK_OVERLAY')} />
                    {typeEvent?.value !== TypeEvent.Video && (
                        <Button onClick={() => setIsOpenUploadBackground(true)} title={t('TITLE_UPLOAD_BACKGROUND')} />
                    )}
                    <Button onClick={() => setIsOpenUploadStickers(true)} title={t('TITLE_UPLOAD_STICKER')} />
                    <Button onClick={() => setIsOpenDeleteStickers(true)} title={t('MENU_REMOVE_STICKES_OVERLAYS')} />
                    <Button onClick={() => setIsOpenLoadStickers(true)} title={t('MENU_DESIGN_LIBRARY')} />
                    {template && !template.includes('Photo') && (
                        <Button
                            onClick={handleDoFourK}
                            title={template.includes('4K') ? t('MENU_SWITCH_HD') : t('MENU_SWITCH_4K')}
                        />
                    )}
                </div>
                <div className='group'>
                    <Button style='grey' onClick={() => setIsOpenModalReturnTP(true)} title={t('MENU_RETURN_TP')} />
                    <Button style='grey' onClick={() => setIsOpenLogout(true)} title={t('MENU_LOGOUT')} />
                </div>
            </div>
            <div className='container-buttons-desktop'>
                <Select
                    placeholder={t('SELECT_EVENT')}
                    theme={selectTheme}
                    styles={selectStyleType}
                    onChange={handleChangeEvent}
                    defaultValue={event}
                    value={event}
                    options={listEvents}
                />
                <Button
                    onClick={handleUploadToServer}
                    title={t('MENU_ADD_TO_EVENT')}
                    style='secondary'
                    disabled={event === undefined}
                />

                <div
                    className='container-submenu'
                    onClick={() => setDisplaySubmenuPresets(!displaySubmenuPresets)}
                    onMouseEnter={() => setDisplaySubmenuPresets(true)}
                    onMouseLeave={() => setDisplaySubmenuPresets(false)}
                >
                    <Button onClick={() => {}} title={t('MENU_MANAGE_PRESETS')} />
                    <div className={`submenu ${displaySubmenuPresets && 'open'}`}>
                        <Button onClick={() => setIsOpenSavePresetServer(true)} title={t('MENU_SAVE_PRESET')} />
                        <Button onClick={() => setIsOpenLoadPresetServer(true)} title={t('MENU_LOAD_PRESET')} />
                        <Button onClick={() => savePresetPreview(true)} title={t('MENU_SAVE_PRESET_PREVIEW')} />
                        <Button onClick={() => savePresetPreview(false)} title={t('MENU_DOWNLOAD_OVERLAY')} />
                    </div>
                </div>

                <div
                    className='container-submenu'
                    onClick={() => setDisplaySubmenuDesign(!displaySubmenuDesign)}
                    onMouseEnter={() => setDisplaySubmenuDesign(true)}
                    onMouseLeave={() => setDisplaySubmenuDesign(false)}
                >
                    <Button onClick={() => {}} title={t('MENU_MANAGE_DESIGN')} />
                    <div className={`submenu ${displaySubmenuDesign && 'open'}`}>
                        <Button onClick={() => setIsOpenUploadQuickOverlay(true)} title={t('MENU_QUICK_OVERLAY')} />
                        {typeEvent?.value !== TypeEvent.Video && (
                            <Button
                                onClick={() => setIsOpenUploadBackground(true)}
                                title={t('TITLE_UPLOAD_BACKGROUND')}
                            />
                        )}
                        <Button onClick={() => setIsOpenUploadStickers(true)} title={t('TITLE_UPLOAD_STICKER')} />
                        <Button
                            onClick={() => setIsOpenDeleteStickers(true)}
                            title={t('MENU_REMOVE_STICKES_OVERLAYS')}
                        />
                        <Button onClick={() => setIsOpenLoadStickers(true)} title={t('MENU_DESIGN_LIBRARY')} />
                        <Button
                            onClick={() => {
                                const url = 'https://touchpix.com/download-resources/';
                                const w = window.open(url, '_blank');
                                if (w) w.focus();
                            }}
                            title={t('MENU_DESIGN_DOWNLOADS')}
                        />
                    </div>
                </div>
                {template && !template.includes('Photo') && (
                    <Button
                        onClick={handleDoFourK}
                        title={template.includes('4K') ? t('MENU_SWITCH_HD') : t('MENU_SWITCH_4K')}
                    />
                )}
                <Button style='grey' onClick={() => setIsOpenModalReturnTP(true)} title={t('MENU_RETURN_TP')} />
                <Button style='grey' onClick={() => setIsOpenLogout(true)} title={t('MENU_LOGOUT')} />
            </div>
            <div className='container-logo'>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/touchpix-logo.png?version=${process.env.REACT_APP_VERSION}`}
                    alt='Touchpix'
                />
            </div>
        </div>
    );
};

export default TopBar;
