import {
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setSerializationToLoad,
    setStickerCategories,
} from 'context/actions/editorActions';
import { defaultBackgrounds } from 'pages/photoEditor/defaults/defaultBackgrounds';
import { defaultStickers } from 'pages/photoEditor/defaults/stickers';
import { getTemplateName } from 'utils/utils';
import { mockupsStickers } from 'pages/photoEditor/defaults/mockupsStickers';
import { RootState } from 'context/store/store';
import { SerialisationSchema } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';
import { useLoadUserBackgroundsHook } from './useLoadUserBackgroundsHook';
import { mockupsAIStickers } from 'pages/photoEditor/defaults/mockupsAIStickers';
import axios from 'axios';

export const useLoadPhotoStickersHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);
    const { loadUserBackgrounds } = useLoadUserBackgroundsHook();

    const loadPhotoStickers = async () => {
        dispatch(setLoader(true));
        if (editorContext.serializationToLoad) return;

        // Remove the mockupStickers by css because we need it in order to display the 'stickers' when we are in AI template
        if (!editorContext.stickerCategories.find((st) => st.identifier === 'CUSTOM TEMPLATES')) {
            const bg = await Promise.all([loadUserBackgrounds()]);
            const newBackgrounds = [
                {
                    identifier: 'BACKGROUNDS',
                    name: 'BACKGROUNDS',
                    thumbnailURI: defaultBackgrounds[1].stickerURI,
                    items: [...bg[0].items, ...defaultBackgrounds],
                },
            ];
            dispatch(
                setStickerCategories([
                    ...defaultStickers,
                    ...editorContext.stickerCategories.filter((cat) => cat.identifier !== 'DEFAULT'),
                    ...mockupsStickers,
                    ...mockupsAIStickers,
                    ...newBackgrounds,
                ])
            );
        }

        // prettier-ignore
        const urlPositions = `${process.env.PUBLIC_URL}/assets/photodesigner/library/images/positions/${getTemplateName(editorContext.template, editorContext.typeEvent?.value)}.json`;
        const jsonPositions = await axios
            .get(urlPositions)
            .then((data) => data.data)
            .catch(() => []);

        if (!editorContext.editor) return;
        const currentSerialization = await editorContext.editor.serialize({ image: false }).then((data) => data);

        const newSerialization: SerialisationSchema = {
            ...currentSerialization,
            operations: [
                {
                    type: 'sprite',
                    options: {
                        sprites: [...(editorContext.template ? jsonPositions : [])],
                    },
                },
            ],
        };
        dispatch(setSerializationToLoad(newSerialization));
        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 500);
    };

    return { loadPhotoStickers };
};
