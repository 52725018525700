import {
    setFinishedLoadSerialization,
    setFinishedLoadStickers,
    setLoader,
    setStickerCategories,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getOverlaysUser } from 'services/overlays/getOverlaysUser';
import { deleteSticker } from 'services/stickers/deleteSticker';
import { deleteOverlay } from 'services/overlays/deleteOverlay';
import { getStickersUser } from 'services/stickers/getStickersUser';
import { getBackgroundsUser } from 'services/backgrounds/getBackgroundUser';
import { deleteBackground } from 'services/backgrounds/deleteBackground';

export const useDeleteUserStickersOverlaysHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const deleteUserStickersOverlays = async () => {
        dispatch(setLoader(true));

        const stickers = await getStickersUser()
            .then((res) => {
                if (res.status === 200) {
                    return res.data.stickers.user.stickers;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                return [];
            });
        const overlays = await getOverlaysUser()
            .then((res) => {
                if (res.status === 200) {
                    return res.data.overlays.user.overlays;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                return [];
            });
        const backgrounds = await getBackgroundsUser()
            .then((res) => {
                if (res.status === 200) {
                    return res.data.backgrounds.user.backgrounds;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                return [];
            });

        const nameStickers = stickers.map((st: any) => st.filename);
        const nameOverlays = overlays.map((ov: any) => ov.filename);
        const nameBackgrounds = backgrounds.map((ov: any) => ov.filename);

        const promisesStickers = deleteSticker(nameStickers);
        const promisesOverlays = deleteOverlay(nameOverlays);
        const promisesBackgrounds = deleteBackground(nameBackgrounds);

        const resolves = await Promise.all([promisesStickers, promisesOverlays, promisesBackgrounds]);

        const oldCategories = editorContext.stickerCategories.filter((cat) => {
            return !['OVERLAYS', 'USER STICKERS'].includes(cat.identifier);
        });

        dispatch(setStickerCategories(oldCategories));

        dispatch(setFinishedLoadSerialization(true));
        setTimeout(() => {
            dispatch(setFinishedLoadStickers(true));
        }, 1000);
    };

    return { deleteUserStickersOverlays };
};
