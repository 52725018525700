import Button from 'components/button/button';
import FilesUpload from 'components/filesUpload/filesUpload';
import Loader from 'components/loader/loader';
import Modal from 'components/modal/modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { postOverlays } from 'services/overlays/postOverlays';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    loadUserOverlays: (overlay: string | undefined) => void;
    quickOverlay?: boolean;
}

const UploadOverlay: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    const handleUpload = () => {
        if (files.length > 0) {
            const filesToUpload = files.map((file) => {
                return {
                    file: file,
                    name: `${Date.now()}_${file.name}`,
                };
            });
            setLoading(true);
            postOverlays(filesToUpload)
                .then((res) => {
                    if (res.status === 200) {
                        setFiles([]);
                        props.loadUserOverlays(props.quickOverlay ? res.data.uploaded[0] : undefined);
                        props.closeModal();
                    }
                })
                .catch((err) => navigate('/'))
                .finally(() => setLoading(false));
        }
    };

    return (
        <Modal title={t('TITLE_UPLOAD_OVERLAY')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className='container-upload-stickers'>
                {loading ? (
                    <div className='container-loader'>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <FilesUpload
                            fileTypes={[
                                {
                                    name: '.png',
                                    format: 'image/png',
                                },
                            ]}
                            setFiles={(files) => setFiles(files)}
                            filesSelected={files}
                            multiple={!props.quickOverlay}
                            isOverlay={true}
                        />
                        <div className='container-button'>
                            <Button onClick={handleUpload} title={t('UPLOAD')} />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UploadOverlay;
