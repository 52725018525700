export const spanishLanguageObject = {
    common: {
        error: 'Error',
        warning: 'Alerta',
        color: {
            colorPicker: {
                hex: 'Hex',
                r: 'R',
                g: 'G',
                b: 'B',
                sliderHue: 'Color',
                sliderOpacity: 'Opacidad color',
            },
        },
    },
    mainCanvasActions: {
        buttonUndo: 'Deshacer',
        buttonRedo: 'Rehacer',
    },
    infoModals: {
        exporting: {
            heading: 'Exportando...',
            body: 'Solo unos segundos...',
        },
        saving: {
            heading: 'Guardando...',
            body: 'Solo unos segundos...',
        },
        loading: {
            heading: 'Cargando...',
            body: 'Solo unos segundos...',
        },
        resizing: {
            heading: 'Reajustando...',
            body: 'Solo unos segundos...',
        },
        loadingFonts: {
            heading: 'Cargando fuentes...',
            body: 'Solo unos segundos...',
        },
        stickerLoading: {
            heading: 'Cargando pegatinas...',
            body: 'Solo unos segundos...',
        },
    },
    errorModals: {
        rendering: {
            headingDelimiter: ':',
            body: 'Ha ocurrido un error mientras se cargaba la imagen',
            buttonYes: 'Reintentar',
            buttonNo: 'Cerrar',
        },
        fontLoading: {
            headingDelimiter: ':',
            heading: 'Fallo al cargar la fuente',
            body: 'Algunas fuentes no se han podido cargar porque el servidor tarda demasiado en responder. Por favor, prueba otra vez',
            buttonNo: 'Cerrar',
        },
        stickerLoading: {
            headingDelimiter: ':',
            body: 'Imposible de cargar el sticker',
            buttonNo: 'Cerrar',
        },
        unexpectedError: {
            headingDelimiter: ':',
            body: 'Ha ocurrido un error inesperado, ${error}',
            buttonYes: 'Reintentar',
            buttonNo: 'Cerrar',
        },
    },
    warningModals: {
        unsupportedSerializationVersion: {
            headingDelimiter: ':',
            body: 'Tus datos se han restaurado de una versión anterior y podría tener cambios',
            buttonYes: 'Aplicar cambios',
            buttonNo: 'Cancelar',
        },
        discardChanges: {
            headingDelimiter: ':',
            body: 'Tienes cambios sin guardar, ¿Está seguro de querer salir?',
            buttonYes: 'Descartar cambios',
            buttonNo: 'Mantener cambios',
        },
        unsavedChanges: {
            headingDelimiter: ':',
            body: 'Tienes cambios sin guardar, ¿Está seguro de querer salir?',
            buttonYes: 'Salir sin salvar',
            buttonNo: 'Cancelar',
        },
        unsupportedWebGLRenderer: {
            body: 'Se ha detectado un problema en el navegador que podría causar largos tiempos de carga. Por favor inténtelo en otro navegador.',
            buttonYes: 'Continuar',
        },
    },
    library: {
        title: 'Plantillas',
    },
    sticker: {
        title: 'Pegatinas',
        canvasActions: {
            buttonDelete: 'Borrar',
            buttonBringToFront: 'Mover al frente',
            buttonDuplicate: 'Duplicar',
            buttonFlipHorizontal: 'Voltear',
            buttonFlipVertical: 'Voltear',
        },
    },
    text: {
        title: 'Textos',
        controls: {
            buttonNew: 'Nuevo texto',
            dropdownFontFamily: 'Familia',
            textFontSize: 'Tamaño',
            selectAlignment: 'Alineamiento',
            selectFontColor: 'Color de fuente',
            selectBackgroundColor: 'Color de fondo',
            sliderLineSpacing: 'Espacio entre líneas',
            tabColor: 'Color',
            tabBgColor: 'Color fondo',
            tabAlignment: 'Alineamiento',
            tabLineHeight: 'Altura línea',
            tabFontSize: 'Tamaño',
        },
        canvasControls: {
            placeholderText: 'Escribe algo',
            buttonSave: 'Hecho',
            buttonClose: 'Cancelar',
            inputText: 'Entrada de texto',
        },
        canvasActions: {
            buttonEdit: 'Editar',
            buttonDelete: 'Borrar',
            buttonBringToFront: 'Mover al frente',
            buttonDuplicate: 'Duplicar',
        },
    },
};
