import { FC, useEffect, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { selectTheme } from 'components/select/selectTheme';
import { selectStyleFont } from 'components/select/selectStyle';
import Select from 'react-select';
import { TPFonts } from 'pages/photoEditor/defaults/TPfonts';

interface Props {
    sticker: any;
    moveUp: (id: number) => void;
    moveDown: (id: number) => void;
    deleteElement: (id: number) => void;
    changeTypo: (id: number, identifier: string) => void;
    updateSticker: () => void;
    isFirst: boolean;
    isLast: boolean;
}

const ElementCarouselText: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [fontSelected, setFontSelected] = useState({
        value: props.sticker.options.fontIdentifier,
        label: props.sticker.options.fontIdentifier,
    });
    const [style, setStyle] = useState({});

    useEffect(() => {
        const newFont = TPFonts.find((font) => font.identifier === fontSelected.value);
        const options = props.sticker.options;
        const color = `rgba(
            ${oneTo255(options.color.rgba[0])}, 
            ${oneTo255(options.color.rgba[1])}, 
            ${oneTo255(options.color.rgba[2])}, 
            ${options.color.rgba[3]})`;
        const backgroundColor = `rgba(
            ${oneTo255(options.backgroundColor.rgba[0])}, 
            ${oneTo255(options.backgroundColor.rgba[1])}, 
            ${oneTo255(options.backgroundColor.rgba[2])}, 
            ${options.backgroundColor.rgba[3]})`;
        const fontSize = 30;
        const textAlign = 'center' as 'center';
        const font = fontSelected;
        const newStyles = {
            color,
            backgroundColor,
            textAlign,
            fontSize,
            fontFamily: `'${newFont?.fontFamily}'`,
        };

        setStyle(newStyles);
        props.changeTypo(props.sticker.id, font.value);
        props.updateSticker();
    }, [fontSelected]);

    const oneTo255 = (value: number) => {
        return Math.round(value * 255);
    };

    return (
        <div className='element-carousel element-text' id={props.sticker.id}>
            <div className='image-container'>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/trash.png?version=${process.env.REACT_APP_VERSION}`}
                    alt='Remove'
                    className='trash'
                    onClick={() => {
                        props.updateSticker();
                        props.deleteElement(props.sticker.id);
                    }}
                />
                <div style={style}>{props.sticker.options.text}</div>
            </div>
            <div className='font-selector-container'>
                <Select
                    theme={selectTheme}
                    styles={selectStyleFont}
                    onChange={(selected) => {
                        selected && setFontSelected(selected);
                    }}
                    value={fontSelected}
                    options={TPFonts.map((font) => {
                        return {
                            value: font.identifier,
                            label: font.identifier,
                        };
                    })}
                />
            </div>
            <div className='controls'>
                <div className='buttons-container'>
                    <div
                        className={`button-move ${props.isFirst && 'disabled'}`}
                        onClick={() => {
                            if (props.isFirst) return;
                            props.updateSticker();
                            props.moveUp(props.sticker.id);
                        }}
                    >
                        <img
                            className='arrow'
                            src={`${process.env.PUBLIC_URL}/assets/img/chevron_left.svg?version=${process.env.REACT_APP_VERSION}`}
                        />
                        <span className='text'>{t('SIDEBAR_STICKERS_MOVE_UP')}</span>
                    </div>
                    <div
                        className={`button-move ${props.isLast && 'disabled'}`}
                        onClick={() => {
                            if (props.isLast) return;
                            props.updateSticker();
                            props.moveDown(props.sticker.id);
                        }}
                    >
                        <img
                            className='arrow down'
                            src={`${process.env.PUBLIC_URL}/assets/img/chevron_left.svg?version=${process.env.REACT_APP_VERSION}`}
                        />
                        <span className='text'>{t('SIDEBAR_STICKERS_MOVE_DOWN')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ElementCarouselText;
