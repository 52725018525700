import { Category } from 'models/category';
import { Template } from 'models/template';
import { FC, useEffect, useState } from 'react';
import { getCategories, getTemplates } from 'services/templates/templatesServer';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './style.scss';
import { selectTheme } from 'components/select/selectTheme';
import Loader from 'components/loader/loader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { RootState } from 'context/store/store';

const URL_ASSET = 'https://slomospin.tecdam.io/assets/';
const templatesWithoutDesign = [
    'Photo 1 horizontal white',
    'Photo 1 strip white',
    'Photo 1 white',
    'Photo 2',
    'Photo 3',
    'Photo 4',
    'Photo 5',
    'Photo 6',
    'Photo 7',
    'Photo 8',
    'Photo 9',
    'Photo 11',
    'Photo 12 horizontal white',
    'Photo 12 strip white',
    'Photo 12 white',
    'Photo 13',
    'Photo 14',
    'Photo 16 horizontal white',
    'Photo 16 strip white',
    'Photo 16 white',
    'Photo 17',
];

interface Props {
    currentTemplate: string | undefined;
    selectTemplate: (template: Template) => void;
    removeTemplate: (template: Template) => void;
    templatesLoaded: Template[];
}

interface selectFilter {
    value: string;
    label: string;
}

const TemplatesTP: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const { template } = useSelector((state: RootState) => state.editorReducer);
    const [filter, setFilter] = useState<selectFilter[]>([]);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        const type =
            props.currentTemplate?.includes('Photo') || props.currentTemplate?.includes('AI') ? 'photo' : 'video';
        getTemplates(type)
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.data;
                    setTemplates(data);
                }
            })
            .catch((err) => navigate('/'));

        getCategories()
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.data;
                    setCategories(data);
                }
            })
            .catch((err) => navigate('/'));
    }, []);

    if (templates.length === 0) {
        return (
            <div className='container-loader-stickers'>
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <Select
                    placeholder={t('FILTER')}
                    components={animatedComponents}
                    isMulti
                    theme={selectTheme}
                    onChange={(selected) => setFilter(selected as never)}
                    options={categories
                        .filter((cat) => {
                            const found = templates.find((t) => t.category.name === cat.name);
                            return found !== undefined ? true : false;
                        })
                        .map((cat) => {
                            return {
                                value: cat.name,
                                label: cat.name,
                            };
                        })}
                />
                {template && templatesWithoutDesign.includes(template) && (
                    <div className='container-message'>
                        <h2>{t('TEXT_DESIGN_LIBRARY_NOT_COMPATIBLE')}</h2>
                    </div>
                )}

                <div className='container-design-library presets-server'>
                    {templates
                        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                        .filter((template) => {
                            if (filter.length === 0) return true;
                            return filter.find((f) => f.value === template.category.name) ? true : false;
                        })
                        .map((template) => {
                            return props.templatesLoaded.find(
                                (temp) => temp.id.toString() === template.id.toString()
                            ) ? (
                                <div
                                    onClick={() => props.removeTemplate(template)}
                                    className='template'
                                    key={template.id}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/remove_circle.svg?version=${process.env.REACT_APP_VERSION}`}
                                        alt='Remove'
                                        className='action remove'
                                    />
                                    <LazyLoadImage src={`${URL_ASSET}${template.preview.filename_disk}`} />
                                </div>
                            ) : (
                                <div
                                    onClick={() => props.selectTemplate(template)}
                                    className='template'
                                    key={template.id}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/add_circle.svg?version=${process.env.REACT_APP_VERSION}`}
                                        alt='Add'
                                        className='action add'
                                    />
                                    <LazyLoadImage src={`${URL_ASSET}${template.preview.filename_disk}`} />
                                </div>
                            );
                        })}
                </div>
            </>
        );
    }
};

export default TemplatesTP;
