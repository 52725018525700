import axios from 'axios';

export const postOverlays = async (files: any[]) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/v1/overlays/`;

    const params = new FormData();
    files.map((file) => {
        params.append('overlays[]', file.file, file.name);
    });

    return new Promise<any>((resolve) => {
        axios
            .post(url, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};
