import { CanvasAction, Configuration } from 'photoeditorsdk';
import { license } from '../license';
import { TPFonts } from './TPfonts';
import { englishLanguageObject } from '../languages/englishLanguageObject';
import { spanishLanguageObject } from '../languages/spanishLanguageObject';
import { portugueseLanguageObject } from '../languages/portugueseLanguageObject';

export const configurationMobile: Configuration = {
    container: '#editor',
    assetBaseUrl: 'assets/photodesigner',
    license: license,
    displayCloseWarning: false,
    layout: 'basic',
    theme: 'light',
    image: `library/images/mockup/Video Boomerang Gif Slomo 2.png`,
    mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO],
    custom: {
        languages: {
            en: englishLanguageObject,
            es: spanishLanguageObject,
            pt: portugueseLanguageObject,
        },
        themes: {
            light: {
                primary: '#591fe2',
                canvas: {
                    background: '#d4d4d4',
                    controlsOutline: 'red',
                    controlsColor: 'blue',
                    cropBackdrop: 'orange',
                },
            },
        },
    },
    export: {
        image: {
            enableDownload: false,
        },
    },
    text: {
        fonts: TPFonts,
        defaultColor: [0.91372, 0.69019, 0.08235, 1],
    },
    engine: {
        backgroundColor: [0, 0, 0, 0],
    },
    library: {
        advancedUIToolControlBarOrder: [],
    },
    enableZoom: false,
    displaySerializationModal: false,
    displayCanvasRendererWarning: false,
};
