import React, { FC, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    filesSelected: File[] | undefined;
    fileTypes: { name: string; format: string }[];
    setFiles: (files: File[]) => void;
    multiple: boolean;
    isOverlay: boolean;
}

const FilesUpload: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const fileInputRef = useRef(null);
    const [errorFileSize, setErrorFileSize] = useState(false);
    const [errorFileType, setErrorFileType] = useState(false);
    const [errorFileDimensions, setErrorFileDimensions] = useState(false);
    const [errorFileTransparency, setErrorFileTransparency] = useState(false);

    const onFileInputChange = (e: any) => {
        const files = e.target.files;
        manageUploadFiles(files);
    };

    const manageUploadFiles = async (files: File[]) => {
        setErrorFileSize(false);
        setErrorFileType(false);
        setErrorFileDimensions(false);
        setErrorFileTransparency(false);

        const filesBySize = filterBySize(Array.from(files));
        if (filesBySize.length < files.length) {
            setErrorFileSize(true);
        }

        const alloweds = props.fileTypes.map((type) => type.format);
        const allowedFilesByType = filesBySize.filter((file) => {
            if (alloweds.find((allow) => allow.toLowerCase().includes(file.type.toLowerCase()))) {
                return file;
            }
        });

        if (allowedFilesByType.length < filesBySize.length) {
            setErrorFileType(true);
        }

        if (
            props.fileTypes.some((f) => f.name.includes('.png') || f.name.includes('.jpg') || f.name.includes('.jpeg'))
        ) {
            const promisesFiles = allowedFilesByType.map((file) => {
                return getHeightAndWidthFromFile(file);
            });

            const resolvesFiles = (await Promise.all(promisesFiles)).flat();
            if (resolvesFiles.length < allowedFilesByType.length) {
                setErrorFileDimensions(true);
            }

            let filesWithTransparency = resolvesFiles;
            if (props.isOverlay) {
                const promisesFilesTransparent = allowedFilesByType.map((file) => {
                    return hasTransparentPixel(file);
                });

                filesWithTransparency = (await Promise.all(promisesFilesTransparent)).flat();

                if (filesWithTransparency.length < allowedFilesByType.length) {
                    setErrorFileTransparency(true);
                }
            }

            props.setFiles(filesWithTransparency.filter((n) => n) as File[]);
        } else {
            props.setFiles(allowedFilesByType.filter((n) => n) as File[]);
        }
    };

    const getHeightAndWidthFromFile = async (file: File) => {
        const fileAsDataURL = window.URL.createObjectURL(file);
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                if (img.width <= 8000 && img.height <= 8000) {
                    resolve(file);
                } else {
                    resolve([]);
                }
            };
            img.src = fileAsDataURL;
        });
    };

    const hasTransparentPixel = (file: File) => {
        const fileAsDataURL = window.URL.createObjectURL(file);

        return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');

                if (!image.complete || image.naturalWidth === 0 || !ctx) {
                    resolve([]);
                    return;
                }

                ctx.drawImage(image, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

                for (let i = 0; i < imageData.length; i += 4) {
                    if (imageData[i + 3] === 0) {
                        resolve(file);
                        return;
                    }
                }

                resolve([]);
            };
            image.src = fileAsDataURL;
        });
    };

    const filterBySize = (files: File[]) => {
        return files.filter((file) => {
            return file.size / 1024 / 1024 <= 8; // 8MB
        });
    };

    const onTargetClick = () => {
        // @ts-ignore
        fileInputRef.current.click();
    };

    return (
        <div className='file-upload'>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type='file'
                className='hidden'
                accept={props.fileTypes.map((type) => type.format).toString()}
                multiple={props.multiple}
            />
            <FileDrop onDrop={(files: any, event: any) => manageUploadFiles(files)} onTargetClick={onTargetClick}>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/img/file-select.svg`}
                    alt='Upload'
                    className='icon-upload'
                />
                {props.multiple ? <p>{t('UPLOAD_FILES_MULTIPLE')}</p> : <p>{t('UPLOAD_FILES_SINGLE')}</p>}
                {props.filesSelected === undefined || props.filesSelected.length === 0 ? (
                    <p className='files-selected'>{t('UPLOAD_FILES_NO_FILE')}</p>
                ) : (
                    <>
                        <p className='files-selected'>
                            {props.filesSelected.length === 1
                                ? `${t('UPLOAD_FILES_SELECTED_SINGLE')}: `
                                : `${t('UPLOAD_FILES_SELECTED_MULTIPLE')}: `}
                            {props.filesSelected.map((file) => (
                                <span key={file.name}>{file.name}</span>
                            ))}
                        </p>
                    </>
                )}
                {props.fileTypes.map((f) => f.name).includes('.png') && (
                    <p className='files-allowed'>{t('UPLOAD_FILES_MAX_SIZE')}</p>
                )}

                <p className='files-allowed'>
                    {t('UPLOAD_FILES_ALLOWED')}:{' '}
                    {props.fileTypes.map((type, index) => {
                        return index === 0 ? (
                            <span key={type.name}>{type.name}</span>
                        ) : (
                            <span key={type.name}>, {type.name}</span>
                        );
                    })}
                </p>
                {errorFileSize && <p className='error-text-upload'>{t('UPLOAD_FILES_ERROR_SIZE')}</p>}
                {errorFileDimensions && <p className='error-text-upload'>{t('UPLOAD_FILES_ERROR_DIMENSIONS')}</p>}
                {errorFileType && <p className='error-text-upload'>{t('UPLOAD_FILES_ERROR_TYPE')}</p>}
                {errorFileTransparency && <p className='error-text-upload'>{t('UPLOAD_FILES_ERROR_TRANSPARENCY')}</p>}
            </FileDrop>
        </div>
    );
};
export default FilesUpload;
