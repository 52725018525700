import { FC, useState } from 'react';
import './style.scss';

interface Props {
    onClick: () => void;
    title: string;
    style?: 'grey' | 'secondary' | 'red';
    disabled?: boolean;
    loading?: boolean;
}

const Button: FC<Props> = (props) => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        if (!clicked) {
            setClicked(true);
            props.onClick();

            setTimeout(() => {
                setClicked(false);
            }, 1000);
        }
    };

    return (
        <button
            className={`custom-button ${props.style && props.style} ${props.disabled && 'disabled'}`}
            onClick={handleClick}
            disabled={props.disabled}
        >
            {props.loading && <div className='spinner'></div>}
            {props.title}
        </button>
    );
};

export default Button;
