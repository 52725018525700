import LoaderEditor from 'components/loaderEditor/loaderEditor';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEvents } from 'services/events/getEvents';
import { getToken } from 'services/auth/getToken';
import Cookie from 'utils/cookie';
import { ACCESS_TOKEN, LANGUAGE_SELECTED, REFRESH_TOKEN, TPX_CODE_VERIFIER } from 'constants/constants';
import { authInterface } from 'router/router';
import { getUser } from 'services/auth/getUser';
import { useTranslation } from 'react-i18next';

const cookie = new Cookie();

interface Props {
    auth: authInterface;
    setAuth: (state: authInterface) => void;
}

const Auth: FC<Props> = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        props.auth.auth && navigate(`/?event_id=${props.auth.eventId}`);
    }, [props.auth]);

    useEffect(() => {
        const validationToken = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const eventId = urlParams.get('event_id');
            const verifier = cookie.getCookie(TPX_CODE_VERIFIER);
            const token = cookie.getCookie(ACCESS_TOKEN);

            if (code !== null && verifier !== null && eventId !== null) {
                const response = await getToken(code, verifier, eventId);
                const user = await getUser().catch(() => undefined);
                cookie.deleteCookie(TPX_CODE_VERIFIER);
                if (response === 200 && user) {
                    const language = cookie.getCookie(LANGUAGE_SELECTED) ?? user.data.language;
                    i18n.changeLanguage(language);
                    props.setAuth({ auth: true, eventId: props.auth.eventId });
                    return;
                } else {
                    props.setAuth({ auth: false, eventId: props.auth.eventId });
                    navigate(`/login?event_id=${props.auth.eventId}`);
                }
            }

            if (token !== null) {
                const user = await getUser().catch(() => undefined);
                // const response = await getEvents().catch((err) => console.log('error', err));

                if (user && user.status === 200) {
                    const language = cookie.getCookie(LANGUAGE_SELECTED) ?? user.data.language;
                    i18n.changeLanguage(language);
                    props.setAuth({ auth: true, eventId: props.auth.eventId });
                } else {
                    cookie.deleteCookie(ACCESS_TOKEN);
                    cookie.deleteCookie(REFRESH_TOKEN);
                    cookie.deleteAllCookies();
                    props.setAuth({ auth: false, eventId: props.auth.eventId });
                    setTimeout(() => {
                        navigate(`/login?event_id=${props.auth.eventId}`);
                    }, 1000);
                }
            } else {
                props.setAuth({ auth: false, eventId: props.auth.eventId });
                navigate(`/login?event_id=${props.auth.eventId}`);
            }
        };

        validationToken();
    }, []);

    return (
        <div className='auth'>
            <LoaderEditor />
        </div>
    );
};

export default Auth;
