import axios from 'axios';

export const getUser = async () => {
    const url = `${process.env.REACT_APP_TP_HOST}/oauth/me/?timestamp=${Date.now()}`;

    return new Promise<any>((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
