import Button from 'components/button/button';
import Loader from 'components/loader/loader';
import Modal from 'components/modal/modal';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteTemplatePreset, getAllUserPresets, getTemplatePresetById } from 'services/presets/presetsServer';
import ConfirmDelete from './confirmDelete';
import './style.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    uploadPreset: (t: File) => void;
    loadPresetLocally: () => void;
}

const LoadPresetServer: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [eventPresets, setEventPresets] = useState([]);
    const [userPresets, setUserPresets] = useState([]);
    const [idConfirmDelete, setIdConfirmDelete] = useState(0);
    const [nameConfirmDelete, setNameConfirmDelete] = useState('');

    useEffect(() => {
        getAllPresets();
    }, []);

    const confirmDelete = () => {
        deleteTemplatePreset(idConfirmDelete)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => navigate('/'))
            .finally(() => {
                getAllPresets();
                closeModalDelete();
            });
    };

    const loadPresetServer = (id: number) => {
        getTemplatePresetById(id)
            .then((res) => {
                if (res.status === 200) {
                    props.uploadPreset(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllPresets = () => {
        setLoader(true);
        getAllUserPresets()
            .then((res) => {
                setEventPresets([]);
                setUserPresets([]);
                if (res.data.template_presets.event) {
                    setEventPresets(res.data.template_presets.event);
                }

                if (res.data.template_presets.user) {
                    setUserPresets(res.data.template_presets.user);
                }
            })
            .catch((err) => {
                setEventPresets([]);
                setUserPresets([]);
            })
            .finally(() => setLoader(false));
    };

    const closeModalDelete = () => {
        setIdConfirmDelete(0);
        setNameConfirmDelete('');
    };

    return (
        <Modal title={t('TITLE_LOAD_PRESET')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <Button onClick={props.loadPresetLocally} title={t('BUTTON_LOAD_LOCALLY')} />
            {idConfirmDelete !== 0 && (
                <ConfirmDelete
                    isOpen={idConfirmDelete !== 0}
                    closeModal={closeModalDelete}
                    confirmDelete={confirmDelete}
                    name={nameConfirmDelete}
                />
            )}
            {loader ? (
                <div className='container-loader-stickers'>
                    <Loader />
                </div>
            ) : (
                <div className='container-presets'>
                    {eventPresets.length > 0 && (
                        <>
                            <h2>{t('SUBTITLE_EVENTS')}</h2>
                            <div className='container-templates presets-server'>
                                {eventPresets.map((pr: any) => {
                                    return (
                                        <div key={pr.id}>
                                            <span className='label-template'>{pr.name}</span>
                                            <div onClick={() => loadPresetServer(pr.id)} className='template library'>
                                                <LazyLoadImage
                                                    src={pr.preview_url !== '' ? pr.preview_url : pr.overlay_image_url}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {userPresets.length > 0 && (
                        <>
                            <h2>{t('SUBTITLE_USER')}</h2>
                            <div className='container-templates presets-server'>
                                {userPresets.map((pr: any) => {
                                    return (
                                        <div key={pr.id}>
                                            <span className='label-template'>{pr.name}</span>
                                            <div onClick={() => loadPresetServer(pr.id)} className='template library'>
                                                <LazyLoadImage
                                                    src={pr.preview_url !== '' ? pr.preview_url : pr.overlay_image_url}
                                                />
                                            </div>
                                            <div className='container-button-remove'>
                                                <Button
                                                    onClick={() => {
                                                        setIdConfirmDelete(pr.id);
                                                        setNameConfirmDelete(pr.name);
                                                    }}
                                                    title={t('REMOVE')}
                                                    style='grey'
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default LoadPresetServer;
