import { CanvasAction, Configuration } from 'photoeditorsdk';
import { license } from '../license';
import { TPFonts } from './TPfonts';
import { englishLanguageObject } from '../languages/englishLanguageObject';
import { spanishLanguageObject } from '../languages/spanishLanguageObject';
import { portugueseLanguageObject } from '../languages/portugueseLanguageObject';

export const configurationDesktop: Configuration = {
    container: '#editor',
    assetBaseUrl: 'assets/photodesigner',
    license: license,
    displayCloseWarning: false,
    layout: 'advanced',
    theme: 'light',
    image: `library/images/mockup/Video Boomerang Gif Slomo 2.png`,
    mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO],
    custom: {
        languages: {
            en: englishLanguageObject,
            es: spanishLanguageObject,
            pt: portugueseLanguageObject,
        },
        themes: {
            light: {
                primary: '#591fe2',
                canvas: {
                    background: '#d4d4d4',
                    controlsOutline: 'red',
                    controlsColor: 'blue',
                    cropBackdrop: 'orange',
                },
                toolbar: {
                    foreground: '#303030',
                    background: '#efefef',
                },
                card: {
                    background: '#dddddd',
                    foreground: '#dddddd',
                },
                typography: {
                    fontURI: './fonts/imgly_font_open_sans_bold.woff', // font path relative to font assets
                    fontFamily: 'Open Sans',
                    format: 'woff',
                    provider: 'file',
                    // Most users load font while initializing their app,
                    // In that case, you can choose to skip loading it again
                    skipLoading: false,
                },
            },
        },
    },
    export: {
        image: {
            enableDownload: false,
        },
    },
    text: {
        fonts: TPFonts,
        defaultColor: [0.91372, 0.69019, 0.08235, 1],
    },
    engine: {
        backgroundColor: [0, 0, 0, 0],
    },
    library: {
        advancedUIToolControlBarOrder: [],
    },
    enableZoom: false,
    displaySerializationModal: false,
    displayCanvasRendererWarning: false,
};
