export const editorConstants = {
    SET_EVENT: 'SET_EVENT',
    SET_EDITOR: 'SET_EDITOR',
    SET_EDITOR_LOADING: 'SET_EDITOR_LOADING',
    SET_IS_OPEN_TEMPLATES_SIDEBAR: 'SET_IS_OPEN_TEMPLATES_SIDEBAR',
    SET_TEMPLATE: 'SET_TEMPLATE',
    SET_TEMPLATES_LOADED: 'SET_TEMPLATES_LOADED',
    SET_CONFIGURATION: 'SET_CONFIGURATION',
    SET_STICKER_CATEGORIES: 'SET_STICKER_CATEGORIES',
    SET_TYPE_EVENT: 'SET_TYPE_EVENT',
    SET_SERIALIZATION_TO_LOAD: 'SET_SERIALIZATION_TO_LOAD',
    SET_OVERLAY_TO_LOAD: 'SET_OVERLAY_TO_LOAD',
    SET_BACKGROUND_TO_LOAD: 'SET_BACKGROUND_TO_LOAD',
    SET_FINISHED_LOAD_STICKERS: 'SET_FINISHED_LOAD_STICKERS',
    SET_FINISHED_LOAD_SERIALIZATION: 'SET_FINISHED_LOAD_SERIALIZATION',
    SET_LOADER: 'SET_LOADER',
    SET_IS_OPEN_MODAL_ERROR_UPLOAD: 'SET_IS_OPEN_MODAL_ERROR_UPLOAD',
    SET_IS_OPEN_MODAL_QR: 'SET_IS_OPEN_MODAL_QR',
};
