import {
    setFinishedLoadStickers,
    setFinishedLoadSerialization,
    setStickerCategories,
    setSerializationToLoad,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';

export const useUploadPresetHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const uploadPreset = async (t: any) => {
        dispatch(setFinishedLoadStickers(false));
        dispatch(setFinishedLoadSerialization(false));

        if (editorContext.editor === undefined) return;
        const template = t.template_data
            ? typeof t.template_data === 'object'
                ? t.template_data
                : JSON.parse(t.template_data)
            : t;

        const currentCategories = editorContext.stickerCategories.map((cat) => cat.identifier);
        const categoriesFiltered = template.stickerCategories.filter((cat: any) => {
            return !currentCategories.includes(cat.identifier.toUpperCase());
        });
        const newCategories = [...editorContext.stickerCategories, ...categoriesFiltered];
        dispatch(setStickerCategories(newCategories));
        dispatch(setSerializationToLoad(template));

        dispatch(setFinishedLoadStickers(true));
        setTimeout(() => {
            dispatch(setFinishedLoadSerialization(true));
        }, 500);
    };

    return { uploadPreset };
};
