import { TypeEvent } from 'models/typeEvent';
import { StickerCategory } from 'photoeditorsdk';

export const getTemplateName = (template: string | undefined, typeEvent: TypeEvent | undefined) => {
    if (!template) return '';

    if (typeEvent === TypeEvent.AI) {
        return template.replace(TypeEvent.AI, TypeEvent.Photo);
    } else {
        return template;
    }
};

export const getTemplateNameBackground = (template: string | undefined, typeEvent: string | undefined) => {
    if (!template) return '';

    if (typeEvent === TypeEvent.Photo || typeEvent === TypeEvent.AI) {
        const isPortrait = [
            'Photo 1',
            'Photo 2',
            'Photo 3',
            'Photo 4',
            'Photo 4 AI',
            'Photo 5',
            'Photo 6',
            'Photo 8',
            'Photo 8 AI',
            'Photo 9',
            'Photo 10',
            'Photo 10 AI',
            'Photo 11',
        ].includes(template.replace(TypeEvent.AI, TypeEvent.Photo));
        const isLandscape = [
            'Photo 7',
            'Photo 12',
            'Photo 13',
            'Photo 14',
            'Photo 15',
            'Photo 15 AI',
            'Photo 17',
            'Photo 17 AI',
        ].includes(template.replace(TypeEvent.AI, TypeEvent.Photo));
        const isSquare = ['Photo 16', 'Photo 16 AI'].includes(template.replace(TypeEvent.AI, TypeEvent.Photo));

        if (isPortrait) {
            return 'Photo 1 white';
        } else if (isLandscape) {
            return 'Photo 12 white';
        } else if (isSquare) {
            return 'Photo 16 white';
        } else {
            return template.replace(TypeEvent.AI, TypeEvent.Photo);
        }
    } else {
        return template;
    }
};

export const isStickerInCategory = (categories: StickerCategory[], category: string, identifier: string) => {
    const categoriesFlatted = categories.map((cat) => {
        const stickers = cat.items.map((st) => st.identifier);
        return {
            identifier: cat.identifier,
            items: stickers,
        };
    });

    const targetCat = categoriesFlatted.find((cat) => cat.identifier === category);
    if (!targetCat) {
        return false;
    }

    const foundSticker = targetCat.items.find((st) => st.includes(identifier));
    return foundSticker === undefined ? false : true;
};
