import { Template } from 'models/template';
import { Selector } from 'models/selector';
import { Configuration, EditorApi, StickerCategory } from 'photoeditorsdk';
import { editorConstants } from '../constants/editorConstants';

export const setEvent = (value: Selector | undefined) => {
    return {
        type: editorConstants.SET_EVENT,
        value: value,
    };
};

export const setEditor = (value: EditorApi | undefined) => {
    return {
        type: editorConstants.SET_EDITOR,
        value: value,
    };
};

export const setEditorLoading = (value: boolean) => {
    return {
        type: editorConstants.SET_EDITOR_LOADING,
        value: value,
    };
};

export const setIsOpenTemplatesSidebar = (value: boolean) => {
    return {
        type: editorConstants.SET_IS_OPEN_TEMPLATES_SIDEBAR,
        value: value,
    };
};

export const setTemplate = (value: string | undefined) => {
    return {
        type: editorConstants.SET_TEMPLATE,
        value: value,
    };
};

export const setTemplatesLoaded = (value: Template[]) => {
    return {
        type: editorConstants.SET_TEMPLATES_LOADED,
        value: value,
    };
};

export const setConfiguration = (value: Configuration) => {
    return {
        type: editorConstants.SET_CONFIGURATION,
        value: value,
    };
};

export const setStickerCategories = (value: StickerCategory[]) => {
    return {
        type: editorConstants.SET_STICKER_CATEGORIES,
        value: value,
    };
};

export const setTypeEvent = (value: Selector | undefined) => {
    return {
        type: editorConstants.SET_TYPE_EVENT,
        value: value,
    };
};

export const setSerializationToLoad = (value: any) => {
    return {
        type: editorConstants.SET_SERIALIZATION_TO_LOAD,
        value: value,
    };
};

export const setOverlayToLoad = (value: any) => {
    return {
        type: editorConstants.SET_OVERLAY_TO_LOAD,
        value: value,
    };
};

export const setBackgroundToLoad = (value: any) => {
    return {
        type: editorConstants.SET_BACKGROUND_TO_LOAD,
        value: value,
    };
};

export const setFinishedLoadStickers = (value: boolean) => {
    return {
        type: editorConstants.SET_FINISHED_LOAD_STICKERS,
        value: value,
    };
};

export const setFinishedLoadSerialization = (value: boolean) => {
    return {
        type: editorConstants.SET_FINISHED_LOAD_SERIALIZATION,
        value: value,
    };
};

export const setLoader = (value: boolean) => {
    return {
        type: editorConstants.SET_LOADER,
        value: value,
    };
};

export const setIsOpenModalErrorUpload = (value: boolean) => {
    return {
        type: editorConstants.SET_IS_OPEN_MODAL_ERROR_UPLOAD,
        value: value,
    };
};

export const setIsOpenModalQR = (value: boolean) => {
    return {
        type: editorConstants.SET_IS_OPEN_MODAL_QR,
        value: value,
    };
};
