import './style.scss';

const Loader = () => {
    return (
        <img
            src={`${process.env.PUBLIC_URL}/assets/img/loader.png?version=${process.env.REACT_APP_VERSION}`}
            alt='loader'
            className='spinner'
        />
    );
};

export default Loader;
