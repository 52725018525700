import Login from 'pages/login/login';
import PhotoEditor from 'pages/photoEditor/photoEditor';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { PrivateRoute } from './privateRoute';

import Auth from 'pages/auth/auth';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from 'context/store/store';

export interface authInterface {
    auth: boolean;
    eventId: string;
}

export const Router = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [auth, setAuth] = useState<authInterface>({
        auth: false,
        eventId: searchParams.get('event_id') !== null ? (searchParams.get('event_id') as string) : '0',
    });

    useEffect(() => {
        const newId = searchParams.get('event_id') !== null ? (searchParams.get('event_id') as string) : '0';
        setAuth({ ...auth, eventId: newId });
    }, [searchParams]);

    useEffect(() => {
        if (auth.auth) {
            navigate(`/editor?event_id=${auth.eventId}`);
        }
    }, [auth]);

    return (
        <Routes>
            {['/editor', '/editor:event_id'].map((path) => {
                return (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <PrivateRoute auth={auth}>
                                <Provider store={store}>
                                    <PhotoEditor />
                                </Provider>
                            </PrivateRoute>
                        }
                    />
                );
            })}
            <Route path='/' element={<Auth auth={auth} setAuth={(state: authInterface) => setAuth(state)} />} />
            <Route path='login:event_id' element={<Login auth={auth} />} />
            <Route path='login' element={<Login auth={auth} />} />
        </Routes>
    );
};
