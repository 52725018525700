import { FC, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';

interface Props {
    handleSaveStickers: () => void;
    size: { height: number; width: number };
}

const HeaderSidebar: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    return (
        <div className='header-sidebar'>
            <div className='top-container'>
                <h2>{t('SIDEBAR_STICKERS_LAYERS_DESING')}</h2>
                <Button onClick={props.handleSaveStickers} title={t('SIDEBAR_STICKERS_ALERT_SAVE')} />
            </div>

            <p className='size-title'>{t('SIDEBAR_STICKERS_DESIGN_SIZE')}</p>
            <div className='sizes-container'>
                <p>
                    {t('SIDEBAR_STICKERS_WIDTH')}: <span>{props.size.width}px</span>
                </p>
                <p>
                    {t('SIDEBAR_STICKERS_HEIGHT')}: <span>{props.size.height}px</span>
                </p>
            </div>
        </div>
    );
};

export default HeaderSidebar;
