import { TypeEvent } from 'models/typeEvent';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    type: TypeEvent;
    mockupPath: string;
    template: string | undefined;
    isStrip: boolean;
    cutHorizontal: boolean;
    emptyTemplate: boolean;
    selectTemplate: (template: string) => void;
}

const DisplayPhoto: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <div className='container-wrapper-templates'>
            {props.isStrip ? (
                <>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('PORTRAIT')} 620 x 1844px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 1 strip white`)}
                                className={`template library ${
                                    props.template === `${props.type} 1 strip white` && 'selected'
                                }`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 1 strip white.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 1 strip white'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('LANDSCAPE')} 1844 x 620px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 12 horizontal white`)}
                                className={`template library ${
                                    props.template === `${props.type} 12 horizontal white` && 'selected'
                                }`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 12 horizontal white.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 12 horizontal white'
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : props.emptyTemplate ? (
                <>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('PORTRAIT')} 1240 x 1844px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 1 white`)}
                                className={`template library ${
                                    props.template === `${props.type} 1 white` && 'selected'
                                }`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 1 white.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 1 white'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('LANDSCAPE')} 1844 x 1240px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 12 white`)}
                                className={`template library ${
                                    props.template === `${props.type} 12 white` && 'selected'
                                }`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 12 white.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 12 white'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('SQUARE')} 1844 x 1844px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 16 white`)}
                                className={`template library ${
                                    props.template === `${props.type} 16 white` && 'selected'
                                }`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 16 white.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 16 white'
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('PORTRAIT')} 1240 x 1844px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 1`)}
                                className={`template library ${props.template === `${props.type} 1` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 1.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 1'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 2`)}
                                className={`template library ${props.template === `${props.type} 2` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 2.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 2'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 3`)}
                                className={`template library ${props.template === `${props.type} 3` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 3.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 3'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 4`)}
                                className={`template library ${props.template === `${props.type} 4` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 4.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 4'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 5`)}
                                className={`template library ${props.template === `${props.type} 5` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 5.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 5'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 6`)}
                                className={`template library ${props.template === `${props.type} 6` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 6.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 6'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 8`)}
                                className={`template library ${props.template === `${props.type} 8` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 8.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 8'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 9`)}
                                className={`template library ${props.template === `${props.type} 9` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 9.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 9'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 10`)}
                                className={`template library ${props.template === `${props.type} 10` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 10.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 10'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 11`)}
                                className={`template library ${props.template === `${props.type} 11` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 11.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 11'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('LANDSCAPE')} 1844 x 1240px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 7`)}
                                className={`template library ${props.template === `${props.type} 7` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 7.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 7'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 12`)}
                                className={`template library ${props.template === `${props.type} 12` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 12.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 12'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 13`)}
                                className={`template library ${props.template === `${props.type} 13` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 13.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 13'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 14`)}
                                className={`template library ${props.template === `${props.type} 14` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 14.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 14'
                                />
                            </div>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 15`)}
                                className={`template library ${props.template === `${props.type} 15` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 15.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 15'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container-templates'>
                        <h3 className='title'>{`${t('SQUARE')} 1844 x 1844px`}</h3>
                        <div className='group-templates'>
                            <div
                                onClick={() => props.selectTemplate(`${props.type} 16`)}
                                className={`template library ${props.template === `${props.type} 16` && 'selected'}`}
                            >
                                <img
                                    src={`${props.mockupPath}/Photo 16.png?version=${process.env.REACT_APP_VERSION}`}
                                    alt='Photo 16'
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DisplayPhoto;
