import { setLoader, setStickerCategories } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getStickersUser } from 'services/stickers/getStickersUser';

export const useLoadUserStickersHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);

    const loadUserStickers = async () => {
        dispatch(setLoader(true));

        return new Promise<any>((resolve, reject) => {
            getStickersUser()
                .then((res) => {
                    if (res.status === 200) {
                        const stickersData = res.data.stickers.user.stickers;

                        const stickers = stickersData.map((st: { id: string; URI: string }) => {
                            return {
                                identifier: st.id,
                                name: st.id,
                                thumbnailURI: st.URI,
                                stickerURI: st.URI,
                            };
                        });

                        resolve({
                            result: true,
                            identifier: 'USER STICKERS',
                            items: stickers.reverse(),
                        });
                    } else {
                        resolve({
                            result: false,
                            identifier: 'USER STICKERS',
                            items: [],
                        });
                    }
                })
                .catch((err) => {
                    resolve({
                        result: false,
                        identifier: 'USER STICKERS',
                        items: [],
                    });
                });
        });
    };

    return { loadUserStickers };
};
