import FilesUpload from 'components/filesUpload/filesUpload';
import Modal from 'components/modal/modal';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    uploadPreset: (file: File) => void;
}

const LoadPresetLocally: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    const [file, setFile] = useState<File | undefined>(undefined);

    useEffect(() => {
        if (file === undefined) return;
        const fileReader = new FileReader();
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = (e) => {
            // @ts-ignore
            props.uploadPreset(JSON.parse(e.target.result));
        };
    }, [file]);

    return (
        <Modal title={t('TITLE_UPLOAD_PRESET_LOCAL')} isOpen={props.isOpen} closeModal={props.closeModal}>
            <FilesUpload
                fileTypes={[
                    {
                        name: '.json',
                        format: 'application/JSON',
                    },
                ]}
                setFiles={(files) => setFile(files[0])}
                filesSelected={file === undefined ? undefined : [file]}
                multiple={false}
                isOverlay={false}
            />
        </Modal>
    );
};

export default LoadPresetLocally;
