import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    mockupPath: string;
    template: string | undefined;
    fourK: boolean;
    selectTemplate: (template: string) => void;
}

const DisplayVideo: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    return (
        <div className='container-wrapper-templates'>
            <div className='container-templates'>
                <h3 className='title'>
                    {t('TEMPLATE')} 1 {t('PORTRAIT')} {t('LONG')} <br></br>{' '}
                    {props.fourK ? '2144 x 3840px' : '1072 x 1920px'}
                </h3>
                <div className='group-templates'>
                    {props.fourK ? (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 1 4K')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 1 4K' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 1 4K.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 1 4K'
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 1')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 1' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 1.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 1'
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='container-templates'>
                <h3 className='title'>
                    {t('TEMPLATE')} 2 {t('PORTRAIT')} <br></br> {props.fourK ? '2144 x 2880px' : '1072 x 1440px'}
                </h3>
                <div className='group-templates'>
                    {props.fourK ? (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 2 4K')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 2 4K' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 2 4K.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 2 4K'
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 2')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 2' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 2.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 2'
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='container-templates'>
                <h3 className='title'>
                    {t('TEMPLATE')} 3 {t('SQUARE')} <br></br> {props.fourK ? '2144 x 2144px' : '1072 x 1072px'}
                </h3>
                <div className='group-templates'>
                    {props.fourK ? (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 3 4K')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 3 4K' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 3 4K.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 3 4K'
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 3')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 3' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 3.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 3'
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='container-templates'>
                <h3 className='title'>
                    {t('TEMPLATE')} 4 {t('LANDSCAPE')} <br></br> {props.fourK ? '2880 x 2144px' : '1440 x 1072px'}
                </h3>
                <div className='group-templates'>
                    {props.fourK ? (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 4 4K')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 4 4K' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 4 4K.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 4 4K'
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 4')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 4' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 4.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 4'
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='container-templates'>
                <h3 className='title'>
                    {t('TEMPLATE')} 5 {t('LANDSCAPE')} {t('LONG')} <br></br>{' '}
                    {props.fourK ? '3840 x 2144px' : '1920 x 1072px'}
                </h3>
                <div className='group-templates'>
                    {props.fourK ? (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 5 4K')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 5 4K' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 5 4K.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 5 4K'
                            />
                        </div>
                    ) : (
                        <div
                            onClick={() => props.selectTemplate('Video Boomerang Gif Slomo 5')}
                            className={`template library ${
                                props.template === 'Video Boomerang Gif Slomo 5' && 'selected'
                            }`}
                        >
                            <img
                                src={`${props.mockupPath}/Video Boomerang Gif Slomo 5.png?version=${process.env.REACT_APP_VERSION}`}
                                alt='Video Boomerang Gif Slomo 5'
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DisplayVideo;
