import axios from 'axios';

export const getBackgroundsUser = async () => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/v1/backgrounds/`;

    return new Promise<any>((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
