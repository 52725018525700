import Loader from 'components/loader/loader';
import { FC } from 'react';
import './style.scss';

interface Props {
    text?: string;
}

const LoaderEditor: FC<Props> = (props) => {
    return (
        <div className='loader-editor'>
            <Loader />
            {props.text && <p className='loader-text'>{props.text}</p>}
        </div>
    );
};

export default LoaderEditor;
