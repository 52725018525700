export const replaceDuplicatedFonts = (preset: any) => {
    let parsedPreset = JSON.stringify(preset);
    parsedPreset = parsedPreset.replace(new RegExp('AdoraBoutonRegular', 'g'), 'adorabouton');
    parsedPreset = parsedPreset.replace(new RegExp('Amatic(?!-Bold)', 'g'), 'Amatic-Bold');
    parsedPreset = parsedPreset.replace(new RegExp('AquitaineCapsMedium', 'g'), 'Aquitaine-CapsMedium');
    parsedPreset = parsedPreset.replace(new RegExp('ARLRDBD', 'g'), 'ArialRoundedMTBold');
    parsedPreset = parsedPreset.replace(new RegExp('BeautyDemo', 'g'), 'Beauty');
    parsedPreset = parsedPreset.replace(new RegExp('BestSchoolOutline', 'g'), 'Best School');
    parsedPreset = parsedPreset.replace(new RegExp('BlackBubble', 'g'), 'BLACKBUBBLE');
    parsedPreset = parsedPreset.replace(new RegExp('BLKCHCRY', 'g'), 'BlackChancery');
    parsedPreset = parsedPreset.replace(new RegExp('bodoni-serial-black-regular', 'g'), 'Bodoni-Serial-Black-Regular');
    parsedPreset = parsedPreset.replace(new RegExp('FontsFree-Net-Bodoni-SvtyTwo-ITC-TT-Book(2)', 'g'), 'BodoniSevITC');
    parsedPreset = parsedPreset.replace(new RegExp('Bordellia Demo', 'g'), 'BordelliaDemo');
    parsedPreset = parsedPreset.replace(new RegExp('BudmoJigglish', 'g'), 'jigglish');
    parsedPreset = parsedPreset.replace(new RegExp('BudmoJigglish-Regular', 'g'), 'jigglish');
    parsedPreset = parsedPreset.replace(new RegExp('Butler_Black', 'g'), 'Butler-Black');
    parsedPreset = parsedPreset.replace(new RegExp('CarnivaleeFreakshow', 'g'), 'CarnevaleeFreakshow');
    parsedPreset = parsedPreset.replace(new RegExp('FreakShow', 'g'), 'CarnevaleeFreakshow');
    parsedPreset = parsedPreset.replace(new RegExp('ClaudinaPersonalUse-Regular', 'g'), 'Claudina Personal Use');
    parsedPreset = parsedPreset.replace(new RegExp('Damion-Regular', 'g'), 'Damion');
    parsedPreset = parsedPreset.replace(new RegExp('Duality-Regular', 'g'), 'duality');
    parsedPreset = parsedPreset.replace(new RegExp('GoodFeelingScriptDemo', 'g'), 'GoodFeelingScript');
    parsedPreset = parsedPreset.replace(new RegExp('Kallydreams-PersonalUse', 'g'), 'Kallydreams');
    parsedPreset = parsedPreset.replace(new RegExp('MarketFreshInlineBold', 'g'), 'Market Fresh Inline Bold ALL CAPS');
    // prettier-ignore
    parsedPreset = parsedPreset.replace(new RegExp('MarketFreshInlineBold-AYPm', 'g'), 'Market Fresh Inline Bold ALL CAPS');
    parsedPreset = parsedPreset.replace(new RegExp('MetalurdoRegular', 'g'), 'Metalurdo');
    parsedPreset = parsedPreset.replace(new RegExp('Milkshake 400', 'g'), 'Milkshake');
    parsedPreset = parsedPreset.replace(new RegExp('OrangePersonalUse', 'g'), 'Orange Personal Use');
    parsedPreset = parsedPreset.replace(new RegExp('Pacifico-Regular', 'g'), 'Pacifico');
    parsedPreset = parsedPreset.replace(new RegExp('Snowinter-Free-For-Personal-Use', 'g'), 'Snowinter-Free');
    parsedPreset = parsedPreset.replace(new RegExp('VarinoNormal-Regular', 'g'), 'Varino-Normal');
    parsedPreset = parsedPreset.replace(new RegExp('ViktorScript-Regular', 'g'), 'Viktor Script Regular');

    return JSON.parse(parsedPreset);
};
