import axios from 'axios';

export const postEvent = async (
    id: string,
    templateType: string,
    templateId: string,
    file: File,
    backgroundImage: File | undefined,
    templateData: string,
    positioningFile: File,
    positioningData: string
) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/v1/events/${id}/templates`;
    const parsedTemplateId = getTemplateParsed(templateType, templateId);

    const params = new FormData();
    params.append('template_type', templateType);
    params.append('template_id', parsedTemplateId);
    params.append('file', file, `${id}_${templateType}_${Date.now()}.png`); // Image with stickers, transparent background without images position
    params.append('template_data', templateData); // json photodesigner
    params.append('preview_file', positioningFile, `${id}_${templateType}_${Date.now()}_preview.png`); // Image sitckers, position photos in white and background

    if (JSON.parse(positioningData).numberOfPhotos > 0) {
        params.append('positioning_data', positioningData); // json photo position
    }

    if (backgroundImage) {
        params.append('background_file', backgroundImage, `${id}_${templateType}_${Date.now()}_background.png`);
    }

    return new Promise<any>((resolve, reject) => {
        axios
            .post(url, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// Conversion between templates id app / designer / backend
const getTemplateParsed = (templateType: string, templateId: string) => {
    let parseTemplateId = '';

    if (
        templateType === 'photo_overlay' ||
        templateType === 'gif_still_overlay' ||
        templateType === 'photo_ai_overlay'
    ) {
        parseTemplateId = templateId;
    } else {
        switch (templateId) {
            case '1':
                parseTemplateId = '5';
                break;
            case '2':
                parseTemplateId = '1';
                break;
            case '3':
                parseTemplateId = '2';
                break;
            case '4':
                parseTemplateId = '3';
                break;
            case '5':
                parseTemplateId = '4';
                break;
            default:
                break;
        }
    }

    return parseTemplateId;
};
