import { URL_ASSET } from 'constants/constants';
import {
    setFinishedLoadStickers,
    setFinishedLoadSerialization,
    setStickerCategories,
    setLoader,
    setTemplatesLoaded,
} from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { Template } from 'models/template';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceById, updateUseCategory, updateUseTemplate } from 'services/templates/templatesServer';
import { useSearchDefaultPresetHook } from './useSearchDefaultPresetHook';

export const useSelectTemplateHook = () => {
    const dispatch = useDispatch();
    const editorContext = useSelector((state: RootState) => state.editorReducer);
    const { searchDefaultPreset } = useSearchDefaultPresetHook();

    // Set stickers to be loaded and the templates to be loaded after
    const selectTemplate = async (template: Template) => {
        dispatch(setFinishedLoadStickers(false));
        dispatch(setFinishedLoadSerialization(false));

        if (editorContext.stickerCategories.find((cat) => cat.identifier === template.id.toString())) {
            dispatch(setLoader(false));
            return;
        }

        updateUseTemplate(template.id, +template.uses + 1);
        updateUseCategory(template.category.id, +template.category.uses + 1);

        const promisesPresets = await template.presets.map(async (preset) => {
            if (preset.directus_files_id !== null) {
                const resource = getResourceById(preset.directus_files_id);
                return resource;
            }
        });

        const promisesStickers = await template.stickers.map(async (sticker) => {
            if (sticker.directus_files_id !== null) {
                const resource = getResourceById(sticker.directus_files_id);
                return resource;
            }
        });

        const allStickers = await Promise.all(promisesStickers);
        const stickers = allStickers.map((st) => {
            const sticker = st.data.data;
            return {
                identifier: sticker.filename_disk,
                name: sticker.title,
                thumbnailURI: `${URL_ASSET}${sticker.filename_disk}`,
                stickerURI: `${URL_ASSET}${sticker.filename_disk}`,
            };
        });
        const newCategories = [
            ...editorContext.stickerCategories,
            {
                identifier: template.id.toString(),
                name: template.name.toUpperCase(),
                thumbnailURI: `${URL_ASSET}${template.preview.filename_disk}`,
                items: stickers,
            },
        ];

        if (promisesPresets.length > 0) {
            await searchDefaultPreset(promisesPresets, stickers);
        } else {
            setTimeout(() => {
                dispatch(setFinishedLoadSerialization(true));
            }, 500);
        }

        dispatch(setTemplatesLoaded([...editorContext.templatesLoaded, template]));
        dispatch(setStickerCategories(newCategories));
        dispatch(setFinishedLoadStickers(true));
    };

    return { selectTemplate };
};
