import axios from 'axios';

export const postStickers = async (files: File[]) => {
    const url = `${process.env.REACT_APP_TP_API_HOST}/wp-json/tpxac/v1/stickers/`;

    const params = new FormData();
    files.map((file) => {
        params.append('stickers[]', file, `${Date.now()}_${file.name}`);
    });

    return new Promise<any>((resolve) => {
        axios
            .post(url, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
    });
};
