import { setLoader } from 'context/actions/editorActions';
import { RootState } from 'context/store/store';
import { ImageFormat, ExportFormat } from 'photoeditorsdk';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateName, getTemplateNameBackground, isStickerInCategory } from 'utils/utils';

export const useSavePresetPreviewHook = () => {
    const dispatch = useDispatch();
    const { editor, template, typeEvent, stickerCategories } = useSelector((state: RootState) => state.editorReducer);

    const savePresetPreview = async (includeJSON: boolean) => {
        if (editor === undefined) return;
        dispatch(setLoader(true));

        if (!template?.includes('white')) {
            const whiteImg = `library/images/white/${getTemplateNameBackground(template, typeEvent?.value)} white.png`;
            await editor.setImage(whiteImg.replace('white white', 'white'));
        }

        const categories = stickerCategories;
        const serialization = await editor.serialize({ image: false }).then((data) => {
            // @ts-ignore
            data.stickerCategories = stickerCategories;
            // @ts-ignore
            data.template = template;
            // @ts-ignore
            data.typeEvent = typeEvent;
            return data;
        });
        const sprites = serialization.operations.find((sp: any) => sp.type === 'sprite');
        // @ts-ignore
        const allSprites = sprites ? sprites.options.sprites : [];

        if (includeJSON) {
            const dataStrSerialization = `data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(serialization)
            )}`;
            const linkSerialization = document.createElement('a');
            linkSerialization.setAttribute('href', dataStrSerialization);
            linkSerialization.setAttribute('download', `template.json`);
            linkSerialization.click();
        }

        let templateFormatted = template;
        if (template?.includes('white')) {
            templateFormatted = template?.replace(' white', '');
        }

        await editor.setImage(
            `library/images/transparent/${getTemplateName(templateFormatted, typeEvent?.value)} trans.png`
        );

        const backgroundStickers = allSprites.filter((sp: any) => {
            return sp.type === 'sticker' && isStickerInCategory(categories, 'BACKGROUNDS', sp.options.identifier);
        });
        const existsBackground = backgroundStickers.length > 0;
        let backgroundImage: File | undefined = undefined;
        if (existsBackground) {
            const serializationFormattedBackground: any = {
                ...serialization,
                operations: [
                    {
                        type: 'sprite',
                        options: { sprites: backgroundStickers },
                    },
                ],
            };

            await editor.deserialize(serializationFormattedBackground);
            // This is the image to upload as a background
            backgroundImage = await editor
                .export({
                    format: ImageFormat.PNG,
                    exportType: ExportFormat.BLOB,
                    quality: 1.0,
                    enableDownload: false,
                    preventExportEvent: true,
                    transparent: true,
                })
                .then(async (image) => {
                    return new File([image as Blob], 'background.png', { type: 'image/png' });
                });

            const linkImage = document.createElement('a');
            linkImage.setAttribute('href', window.URL.createObjectURL(backgroundImage as Blob));
            linkImage.setAttribute('download', `background.png`);
            linkImage.click();
        }

        const overlayStickers = allSprites.filter((sp: any) => {
            return (
                sp.type === 'text' ||
                (sp.type !== 'text' &&
                    !isStickerInCategory(categories, 'CUSTOM TEMPLATES', sp.options.identifier) &&
                    !isStickerInCategory(categories, 'BACKGROUNDS', sp.options.identifier))
            );
        });
        const serializationFormattedOverlayStickers: any = {
            ...serialization,
            operations: [
                {
                    type: 'sprite',
                    options: { sprites: overlayStickers },
                },
            ],
        };

        await editor.deserialize(serializationFormattedOverlayStickers);

        const overlayImage = await editor
            .export({
                format: ImageFormat.PNG,
                exportType: ExportFormat.BLOB,
                quality: 1.0,
                enableDownload: false,
                preventExportEvent: true,
                transparent: true,
            })
            .then(async (image) => {
                return new File([image as Blob], 'template.png', { type: 'image/png' });
            });

        const linkImage = document.createElement('a');
        linkImage.setAttribute('href', window.URL.createObjectURL(overlayImage as Blob));
        linkImage.setAttribute('download', `template.png`);
        linkImage.click();

        await editor.deserialize(serialization);
        await editor.setImage(`library/images/mockup/${getTemplateNameBackground(template, typeEvent?.value)}.png`);
        dispatch(setLoader(false));
    };

    return { savePresetPreview };
};
