import Modal from 'components/modal/modal';
import { Template } from 'models/template';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import TemplatesTP from './templatesTP';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    currentTemplate: string | undefined;
    selectTemplate: (template: Template) => void;
    removeTemplate: (template: Template) => void;
    templatesLoaded: Template[];
}

const LoadTemplateTP: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal
            title={t('TITLE_STICKERS_ADD_DELETE_TEMPLATE')}
            isOpen={props.isOpen}
            closeModal={props.closeModal}
            fixedSize
        >
            <TemplatesTP
                currentTemplate={props.currentTemplate}
                selectTemplate={props.selectTemplate}
                removeTemplate={props.removeTemplate}
                templatesLoaded={props.templatesLoaded}
            />
        </Modal>
    );
};

export default LoadTemplateTP;
