import { FC, useState } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/modal';
import Button from 'components/button/button';

interface Props {
    showModal: boolean;
    closeModal: () => void;
    saveChanges: () => void;
    notSaveChanges: () => void;
}

const ModalAlert: FC<Props> = (props) => {
    const { i18n, t } = useTranslation();
    return (
        <Modal title={t('SIDEBAR_STICKERS_ALERT_TITLE')} isOpen={props.showModal} closeModal={props.closeModal}>
            <div>
                <p>{t('SIDEBAR_STICKERS_ALERT_TEXT')}</p>
                <div className='container-buttons'>
                    <Button onClick={props.notSaveChanges} title={t('SIDEBAR_STICKERS_ALERT_NOT_SAVE')} style='red' />
                    <Button onClick={props.saveChanges} title={t('SIDEBAR_STICKERS_ALERT_SAVE')} />
                </div>
            </div>
        </Modal>
    );
};

export default ModalAlert;
