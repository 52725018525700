import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../languages/en.json';
import es from '../languages/es.json';
import pt from '../languages/pt.json';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        es: { translation: es },
        pt: { translation: pt },
    },
    fallbackLng: 'en',
    debug: false,
});

export default i18n;
